<div id="modal" class="modal-container" (click)="dismiss($event)">
  <div class="modal-content">
    <form [formGroup]="filters">
      <div>
        <div style="display: flex;flex-direction:column;gap:15px">
          <div formGroupName="roles">
            <p class="category">Disponibilità</p>
            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="walking" formControlName="walking" name="walking">
                <label for="walking">Accompagnare la persona alle visite  a Piedi</label>
              </div>
              <div class="d-flex">
                <input type="checkbox" id="taxiNoCar" formControlName="taxiNoCar" name="taxiNoCar">
                <label for="taxiNoCar">Accompagnare la persona alle visite  con Auto Fornita dalla Famiglia</label>
              </div>
              <div>
                <input type="checkbox" id="taxi" formControlName="taxi" name="taxi">
                <label for="taxi">Accompagnare la persona alle visite  con Auto Propria</label>
              </div>
              <div>
                <input type="checkbox" id="cleaning" formControlName="cleaning" name="cleaning">
                <label for="cleaning">Provvedere all'igiene completo della persona</label>
              </div>
              <div>
                <input type="checkbox" id="dayShift" formControlName="dayShift" name="dayShift">
                <label for="dayShift">Badante diurna</label>
              </div>
              <div>
                <input type="checkbox" id="nightShift" formControlName="nightShift" name="nightShift">
                <label for="nightShift">Badante notturna</label>
              </div>
              <div>
                <input type="checkbox" id="coliving" formControlName="coliving" name="coliving">
                <label for="coliving">Badante convivente</label>
              </div>
              <div>
                <input type="checkbox" id="changing" formControlName="changing" name="changing">
                <label for="changing">Cambio pannolone</label>
              </div>
              <div>
                <input type="checkbox" id="hospital" formControlName="hospital" name="hospital">
                <label for="hospital">Assistenza in ospedale</label>
              </div>
            </div>
          </div>
          <div formGroupName="specialities">
            <p class="category">Competenze</p>
            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="glycemia" formControlName="glycemia">
                <label for="glycemia">Controllo glicemia con apposito apparecchio</label>
              </div>
              <div>
                <input type="checkbox" id="injections" formControlName="injections">
                <label for="injections">Iniezioni sicure</label>
              </div>
              <div>
                <input type="checkbox" id="dressings" formControlName="dressings">
                <label for="dressings">Medicazioni</label>
              </div>
              <div>
                <input type="checkbox" id="technology" formControlName="technology">
                <label for="technology">Utilizzo di apparecchi tecnologici es. ricette elettroniche</label>
              </div>
            </div>
          </div>
          <div>
            <p class="category">Generici</p>
            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="rating" formControlName="rating">
                <label for="rating">Voto generale maggiore o uguale a 6</label>
              </div>
              <div>
                <input type="checkbox" id="license" formControlName="license">
                <label for="license">Patente</label>
              </div>
              <div>
                <input type="checkbox" id="car" formControlName="car">
                <label for="car">Automunita</label>
              </div>
              <div>
                <input type="checkbox" id="fhsub" formControlName="fhsub">
                <label for="fhsub">Iscrizione a Family+Happy</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="days">
          <p class="category">Giorni disponibili</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="sun" formControlName="sun">
              <label for="sun">Domenica</label>
            </div>
            <div>
              <input type="checkbox" id="mon" formControlName="mon">
              <label for="mon">Lunedì</label>
            </div>
            <div>
              <input type="checkbox" id="tue" formControlName="tue">
              <label for="tue">Martedì</label>
            </div>
            <div>
              <input type="checkbox" id="wed" formControlName="wed">
              <label for="wed">Mercoledì</label>
            </div>
            <div>
              <input type="checkbox" id="thu" formControlName="thu">
              <label for="thu">Giovedì</label>
            </div>
            <div>
              <input type="checkbox" id="fri" formControlName="fri">
              <label for="fri">Venerdì</label>
            </div>
            <div>
              <input type="checkbox" id="sat" formControlName="sat">
              <label for="sat">Sabato</label>
            </div>
          </div>
        </div>
        <div formGroupName="hours">
          <p class="category">Orari di disponibilità</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="mor" formControlName="mor">
              <label for="mor">Mattino</label>
            </div>
            <div>
              <input type="checkbox" id="aft" formControlName="aft">
              <label for="aft">Pomeriggio</label>
            </div>
            <div>
              <input type="checkbox" id="eve" formControlName="eve">
              <label for="eve">Sera</label>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="lang">
          <p class="category">Lingue</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="english" formControlName="english">
              <label for="english">Inglese</label>
            </div>
            <div>
              <input type="checkbox" id="italian" formControlName="italian">
              <label for="italian">Italiano</label>
            </div>
            <div>
              <input type="checkbox" id="spanish" formControlName="spanish">
              <label for="spanish">Spagnolo</label>
            </div>
          </div>
        </div>
        <div formGroupName="exp">
          <p class="category">Esperienza</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="cluster1" formControlName="cluster1">
              <label for="cluster1">Con anziani non autosufficienti</label>
            </div>
            <div>
              <input type="checkbox" id="cluster2" formControlName="cluster2">
              <label for="cluster2">Con anziani autosufficienti</label>
            </div>
          </div>
        </div>
        <div formGroupName="contract">
          <p class="category">Interessi per incarichi</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="occ" formControlName="occ">
              <label for="occ">Occasionali</label>
            </div>
            <div>
              <input type="checkbox" id="part" formControlName="part">
              <label for="part">Part-time</label>
            </div>
            <div>
              <input type="checkbox" id="full" formControlName="full">
              <label for="full">Full-time</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showAgeFilter">
        <p class="category">Età</p>
        <div style="display: flex; gap: 15px;">
          <input type="number" min="1" max="99" formControlName="minAge" placeholder="Da...">
          <input type="number" min="1" max="99" formControlName="maxAge" placeholder="A...">
        </div>
      </div>
      <div>
        <p class="category">Distanza massima in KM</p>
        <input type="number" id="distance" formControlName="distance" placeholder="fino a...">
      </div>
    </form>
    <div style="display: grid;grid-template-columns: repeat(3, 1fr);">
      <p class="my-button secondary-button" (click)="resetFilters()">Reset</p>
      <p class="my-button" (click)="emitFilter()">Filtra</p>
    </div>
  </div>
</div>
