<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Servizi</h2>
    <h4 class="text-center pointer" (click)="collapse.toggle()">
      Filtri<i
        class="bi ms-2"
        style="font-size: 16px"
        [ngClass]="isCollapsed ? 'bi-caret-down-fill' : 'bi-caret-up-fill'"
      ></i>
    </h4>
    <form
      class="row m-3 p-3 bg-info rounded"
      [formGroup]="filters"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="isCollapsed"
    >
      <div class="col-6 mb-3">
        <label for="businessLine" class="form-label">Business Line</label>
        <select
          class="form-select"
          aria-label="Default select example"
          id="businessLine"
          formControlName="businessLine"
        >
          <option value="">TUTTI</option>
          <option value="NURSE">NURSE</option>
          <option value="BABYSITTER">BABYSITTER</option>
          <option value="PETSITTER">PETSITTER</option>
          <option value="COLF">COLF</option>
        </select>
      </div>
      <div class="col-6 mb-3">
        <label for="customerType" class="form-label">Tipo di Cliente</label>
        <select
          class="form-select"
          id="customerType"
          aria-label="Default select example"
          formControlName="customerType"
        >
          <option value="">TUTTI</option>
          <option value="B2C">B2C</option>
          <option value="B2B">B2B</option>
          <option value="CAREGIVER">CAREGIVER</option>
          <option value="UNICREDIT">UNICREDIT</option>
        </select>
      </div>
      <button
        class="col-3 btn btn-primary m-auto text-light"
        (click)="collapse.toggle(); applyFilters()"
      >
        Applica
      </button>
      <button class="col-3 btn btn-secondary m-auto text-light" (click)="collapse.toggle(); resetFilters()">reset</button>
    </form>

    <div class="d-flex flex-row-reverse gap-2">
      <button class="btn btn-info" (click)="createService()">
        <i class="bi bi-plus-square"></i>
      </button>
    </div>
    <table class="table table-hover table-striped">
      <thead>
        <tr>
          <!-- <th></th> -->
          <th scope="col">ID</th>
          <th scope="col">Nome</th>
          <th scope="col">Business Line</th>
          <th scope="col">Tipo di Cliente</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let service of services">
          <th scope="row">{{ service.id }}</th>
          <td>{{ service.name }}</td>
          <td>{{ service.businessLine }}</td>
          <td>{{ service.customerType }}</td>
          <td>
            <a [routerLink]="['/services', service.id]">
            <button
              class="btn btn-primary px-2 py-1"
            >
              <i class="bi bi-search text-light pointer"></i>
            </button>
          </a>
          </td>
          <td>
            <button
              class="btn btn-danger px-2 py-1"
              (click)="deleteService(service.id)"
            >
              <i class="bi bi-x-lg text-light pointer"></i>
            </button>
          </td>
        </tr>
        <tr *ngIf="services.length === 0">
          <td colspan="10" class="text-center">
            Non sono stati trovati servizi.
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <p class="col">
        Sono presenti <b>{{ collectionSize }}</b> servizi in totale.
      </p>
      <ngb-pagination
        class="col d-flex justify-content-end"
        *ngIf="services.length > 0"
        [maxSize]="5"
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="collectionSize"
        (pageChange)="onPageChange()"
      ></ngb-pagination>
    </div>

    <app-alert
      *ngIf="showDeleteConfirm"
      (save)="confirmDelete($event)"
      [data]="{ type: undefined }"
      [title]="'Conferma eliminazione'"
      [message]="
        'Sei sicuro di voler eliminare il servizio ' + selectedServiceID + '?'
      "
    >
    </app-alert>
  </div>
</div>
