<div class="toastContainer">
  <ngbd-toast-global></ngbd-toast-global>
</div>
<div class="bigger-container">
  <div class="left-section">
    <p class="filter-title">Filtra</p>
    <form class="filters" [formGroup]="form">
      <div>
        <label for="status">Cerca per ID</label>
        <input id="filters-docref" type="text" formControlName="docRef" placeholder="Cerca per ID">
      </div>
      <div>
        <label for="status">Cerca per nome e cognome</label>
        <input id="filters-name" type="text" formControlName="name" placeholder="Cerca per nome/cognome">
      </div>
      <div>
        <label for="status">Cerca per email</label>
        <input id="filters-email" type="text" formControlName="email" placeholder="Cerca per email">
      </div>
      <div>
        <label for="status">Cerca per telefono</label>
        <input id="filters-phone" type="text" formControlName="phone" placeholder="Cerca per telefono">
      </div>
      <div>
        <label for="status">Cerca per età</label>
        <div class="age-filter" style="display: grid; grid-template-columns: 1fr 1fr;">
          <input id="filters-fromAge" type="number" formControlName="fromAge" placeholder="Da..." min="0" max="99">
          <input id="filters-toAge" type="number" formControlName="toAge" placeholder="A..." min="0" max="99">
        </div>
      </div>
      <div>
        <label for="address">Cerca per indirizzo:</label>
        <!-- <input type="text" [formControl]="addressInput" placeholder="Inserisci indirizzo" id="address"> -->
        <app-input-map [reset]="resetChildren" [value]="addressInput"
          (itemSelected)="sendAddress($event)"></app-input-map>
      </div>
      <div>
        <label for="status">Cerca per distanza in KM:</label>
        <input type="number" formControlName="range" placeholder="distanza in KM">
      </div>
      <button id="filter-button" (click)="showFiltersModal()">Altri Filtri</button>
      <button id="reset-button" data-cy="reset-button" (click)="resetFilters()">RESET</button>
    </form>
  </div>
  <div class="right-section">
    <button (click)="open(map)" class="iconMaps btn btn-secondary text-light">
      <i class="bi bi-pin-map test"></i>
    </button>
    <div class="smaller-container">
      <p class="smaller-text">Colf Family Happy</p>
      <div class="smaller-bar"></div>
    </div>

    <button class="my-button" id="new-nurse-button" (click)="rowClick(undefined)">NUOVA</button>

    <div style="max-height: 70vh; overflow-y: auto;">
      <table>
        <th>Nome</th>
        <th>Cognome</th>
        <th>Email</th>
        <th>Cellulare</th>
        <th></th>
        <th></th>
        <th></th>
        <th>Elimina</th>
        <a *ngFor="let b of colfs" (click)="$event.stopPropagation(); rowClick(b)"
          [routerLink]="['/colf-detail', b.docRef]" [href]="'/colf-detail'+b.docRef" class="link-no-style"
          style="display: table-row;">
          <td>{{ b.name }}</td>
          <td>{{ b.surname }}</td>
          <td>{{ b.email}}<i class="ms-2 bi bi-clipboard"
              (click)="$event.stopImmediatePropagation(); $event.preventDefault();copyToClipboard(b.email)"></i></td>
          <td>{{ b.phone }}<i class="ms-2 bi bi-clipboard"
              (click)="$event.stopImmediatePropagation(); $event.preventDefault();copyToClipboard(b.phone)"></i></td>
          <td class="align-items-center">
            <i style="font-size: 1.5rem;" [ngClass]="{
                'color-success-euro': (b.subscription === 'ABBONATO' || b.manualSubscription === 'ABBONATO'),
                'color-danger-euro': (b.subscription !== 'ABBONATO') && (b.manualSubscription !== 'ABBONATO')}"
              class="bi bi-currency-euro" [ngbTooltip]="b.subscription === 'ABBONATO' ? 'Abbonato' : 'Non abbonato'"
              placement="top"></i>
          </td>
          <td>
            <i style="font-size: 1.5rem;"
              [ngClass]="{'color-success-euro': b.certificate !== '', 'color-danger-euro': b.certificate === '' }"
              class="bi bi-file-earmark-arrow-up-fill" placement="top"
              [ngbTooltip]="b.certificate === '' ? 'Non certificato' : 'Certificato'"></i>
          </td>
          <td>
            <i style="font-size: 1.5rem;"
              [ngClass]="{'color-success-euro': b.rating !== 0, 'color-danger-euro': b.rating === 0}"
              class="bi bi-clipboard2-pulse-fill"
              [ngbTooltip]="b.rating !== 0 ? 'Valutazione: ' + b.rating : 'Non ancora valutato'"></i>
          </td>
          <td class="delete-button"
            (click)="$event.stopImmediatePropagation(); $event.preventDefault();deleteColf(b.docRef)"
            style="text-align: center;">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="red">
              <path
                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
            </svg>
          </td>
        </a>
        <tr *ngIf="colfs.length === 0">
          <td colspan="7" style="text-align: center">Non sono state trovate colf che soddisfano i criteri di
            ricerca.</td>
        </tr>
      </table>
    </div>

    <div class="d-flex justify-content-between mt-4">
      <div class="d-flex gap-2">
        <button class="m-auto list-action-button" id="load-more-button" *ngIf="showPageButton"
          (click)="loadMore()">Carica altri risultati</button>
        <button class="m-auto list-action-button" id="export-data-button" *ngIf="isAdmin"
          (click)="exportColfs()">Esporta dati</button>
      </div>
      <div class="elements-limit-container">
        <label for="elements-number">N. elementi per pagina</label>
        <select id="elements-number" [formControl]="elementsPerPage">
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>

  </div>
</div>

<!--Alert section-->
<app-alert *ngIf="showAlert" (save)="onAlertConfimation($event)" [data]="{ type: undefined }" [title]="'Attenzione!'"
  [message]="'Vuoi davvero eliminare questo profilo colf?'">
</app-alert>
<!-- <app-nurse-filter-modal *ngIf="showFilters" [filtersValue]="filtersObserver" (onClose)="showFilters = false"
  (onFilter)="filters=$event; this.colfs = []; processFilters()"></app-nurse-filter-modal> -->

<app-colf-filter-modal *ngIf="showFilters" [filtersValue]="filtersObserver" (onClose)="  filterModalClose()"
  (onFilter)="filters=$event; this.colfs = []; askMoreColfs()" [resetAllFilters]="resetChildren"></app-colf-filter-modal>

<ng-template #map let-modal>
  <app-map [modal]="modal"></app-map>
</ng-template>
