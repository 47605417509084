import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Ad, Request, RequestCategory } from 'npx-family-happy-common';

@Injectable({
  providedIn: 'root'
})
export class BulletinBoardService {
  private baseURL = environment.bulletinBoardServiceURL;

  constructor(private http: HttpClient) { }

  createAd(newMessage: string, subject: string, req: Request, update = false) {
    const regex1 = /ricerca di un\*(?<description>[^<]+)<\/p>/gm;
    const regex2 = /(?<description><p>La famiglia ha bisogno fino al[^<]+<\/p>)/gm;
    const regex3 = /(?<description><p>Remunerazione netta[^<]+<\/p>)/gm;
    let descrizioneImpegno = regex1.exec(newMessage)?.groups?.description ?? '';
    descrizioneImpegno += regex2.exec(newMessage)?.groups?.description ?? '';
    descrizioneImpegno += regex3.exec(newMessage)?.groups?.description ?? '';
    const reqBody: Ad = {
      richiestaId: req.id,
      title: subject,
      provincia: req.requestPersonalInfo.provincia,
      citta: req.requestPersonalInfo.parentCity,
      zona: req.requestPersonalInfo.parentZone,
      startDate: req.requestDetail.starting,
      endDate: req.requestDetail.estimatedLasting,
      descrizioneImpegno,
      createdAt: new Date(),
      disabled: false,
      requestCategory: req.requestPersonalInfo.requestCategory as RequestCategory
    };
    console.log('send request:', req, reqBody);

    if (reqBody.descrizioneImpegno === '') {
      throw new Error('Attenzione! Il campo descrizione annuncio è vuoto.');
    }

    if (update) {
      return this.http.put(this.baseURL + '/update', reqBody, { params: { idRichiesta: req.id } });
    } else {
      return this.http.post(this.baseURL + '/create', reqBody);
    }
  }

  createAdFromWhatsapp(newMessage: string, subject: string, req: Request, update = false) {
    const reqBody: Ad = {
      richiestaId: req.id,
      title: subject,
      provincia: req.requestPersonalInfo.provincia,
      citta: req.requestPersonalInfo.parentCity,
      zona: req.requestPersonalInfo.parentZone,
      startDate: req.requestDetail.starting,
      endDate: req.requestDetail.estimatedLasting,
      descrizioneImpegno: newMessage,
      createdAt: new Date(),
      disabled: false,
      requestCategory: req.requestPersonalInfo.requestCategory as RequestCategory
    };
    console.log('send request:', req, reqBody);

    if (reqBody.descrizioneImpegno === '') {
      throw new Error('Attenzione! Il campo descrizione annuncio è vuoto.');
    }

    if (update) {
      return this.http.put(this.baseURL + '/update', reqBody, { params: { idRichiesta: req.id } });
    } else {
      return this.http.post(this.baseURL + '/create', reqBody);
    }
  }

  getAdStatus(requestID: number) {
    return this.http.get(this.baseURL + '/retrieve-by-id-visibility', { params: { idRichiesta: requestID } });
  }

  getAdById(id: number) {
    return this.http.get(this.baseURL + '/retrieve-by-id', { params: { idRichiesta: id } });
  }
}
