import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page } from 'npx-family-happy-common';
import { BehaviorSubject } from 'rxjs';
import { LoadBalanceRecordInterface } from 'src/app/shared/models/request-loadBalance-record.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoadBalanceService {

  private baseUrl = environment.loadBalanceAPI;
  cooldown$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null); // Stato del BOOST timer in secondi

  constructor(private http:HttpClient) { }

  getLoadBalance(){
    return this.http.get<Page<LoadBalanceRecordInterface>>(this.baseUrl)
  }

  updateVacation(record:LoadBalanceRecordInterface){
    let body = {
      id: record.id,
      vacation: record.onVacation
    }
    return this.http.patch<LoadBalanceRecordInterface>(`${this.baseUrl}${body.id}/vacation?vacation=${body.vacation}`, body)
  }

  updateWeight(record:LoadBalanceRecordInterface, businessLine:string){
    let weight = 0
    if(businessLine === 'BABYSITTER'){
      weight = record.businessLineWeights.BABYSITTER
    } else if (businessLine === 'PETSITTER'){
      weight = record.businessLineWeights.PETSITTER
    } else if (businessLine === 'NURSE'){
      weight = record.businessLineWeights.NURSE
    } else if (businessLine === 'COLF'){
      weight = record.businessLineWeights.COLF
    }
    let body = {
      businessLine:businessLine,
      id: record.id,
      weight: weight
    }
    return this.http.patch<LoadBalanceRecordInterface>(`${this.baseUrl}${body.id}/weight?businessLine=${body.businessLine}&weight=${body.weight}`, body)
  }

  isTutorCheck(uid:string){
    return this.http.get<{check:string}>(`${this.baseUrl}isTutor?tutorUid=${uid}`)
  }

  activeBoost(uid:string, factor:number){
    let body = {
      factor:factor,
      tutorUid:uid
    }
    return this.http.post<any>(`${this.baseUrl}activateFactor?factor=${factor}&tutorUid=${uid}`, body)
  }

  deactivateBoost(uid:string){
    let body = {
      tutorUid:uid
    }
    return this.http.post<any>(`${this.baseUrl}deactivateFactor?tutorUid=${uid}`, body)
  }

  getReaminingFactorTime(uid:string){
    return this.http.get<any>(`${this.baseUrl}remainingFactorTime?tutorUid=${uid}`)
  }

  getTimer(){
    return this.cooldown$.asObservable()
  }

  setTimer(seconds:number | null){
    this.cooldown$.next(seconds)
  }
}

// "XHJPMuubZ8eERkOgFJFGkW9T9tu1" UID TEST
