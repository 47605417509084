<br>

<div class="w-100 px-2">
  <div class="input-field my-4" *ngIf="!paymentURL">
    <label class="label me-3" for="paymentLink">Clicca qui per generare il link di pagamento:</label>
    <button id="paymentLink" (click)="createPaymentLink()" class="btn btn-primary">Genera</button>
  </div>
  <div class="d-flex input-field my-4" *ngIf="paymentURL">
    <label class="label me-3 align-self-center" for="payment-link-input">Link di pagamento aggiornato:</label>
    <input style="width: 50%;" name="payment-link-input" type="text" class="form-control" [value]="paymentURL" readonly>
    <button class="btn btn-info input-end-button"><i class="bi bi-clipboard" (click)="copyToClipboard()"></i></button>
  </div>
  <div class="input-field my-4">
    <label class="label me-3" for="adTitle">Titolo Annuncio</label>
    <input type="text" id="adTitle" style="width: 50%" [(ngModel)]="request.operationalInfo.adTitle">
    <!-- <button class="btn btn-primary ms-3" (click)="updateAdTitle()">Aggiorna</button> -->
    <button *ngIf="oldTitle !== request.operationalInfo.adTitle" class="title-button ms-3" (click)="updateAdTitle()">Aggiorna</button>
    <button *ngIf="oldTitle === request.operationalInfo.adTitle" class="title-button ms-3 disabled">Aggiorna</button>
  </div>
  <mat-accordion class="w-100" [formGroup]="messages">
    <!-- hideToggle -->
    <mat-expansion-panel *ngFor="let section of sections; let i = index;" [expanded]="step === i" (opened)="setStep(i)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{getSectionTitle(i)}}
        </mat-panel-title>
        <div class="loader" style="display: none"></div>
      </mat-expansion-panel-header>
      <div class="table" *ngIf="section.id !== 0">
        <div class="row">
          <div class="cell" style="padding: 0;">
            <input type="text" placeholder="Oggetto" formControlName="{{section.subject}}" [readOnly]="section.id === 1"
              style="width: 100%;">
          </div>
          <div class="cell">
            <button class="btn btn-secondary bg-secondary text-white" style="margin-bottom: 10px"
              (click)="sendMessage(section.id, i)">Invia</button>
          </div>
        </div>
      </div>

      <div class="w-100" *ngIf="section.id === 0">
        <div class="ms-3 mb-3">
          <app-mode-switch  [currentMode]="isAdvancedSearch ? Mode.Advanced : Mode.Classic"
          (modeChanged)="isAdvancedSearch = $event === 'Advanced'"></app-mode-switch>
        </div>
        <!-- <div class="ms-3" *ngIf="isAdvancedSearch === true">
          <p class="text-danger fw-bold" *ngIf="isAdvancedSearch === true && filtersObj === null">Attenzione! Nessun
            filtro selezionato!</p>
        </div> -->
        <div class="mb-3 ms-3 rangeSelect">
          <!-- <button class="btn btn-primary" (click)="activeAdvancedSearch()">Usa ricerca avanzata</button> -->
          <label for="range mb-1" class="form-label">Distanza target</label>
          <select [formControl]="range" class="form-select" name="range" id="range">
            <option value="5000">5 Km</option>
            <option value="10000">10 Km</option>
            <option value="20000">20 Km</option>
            <option value="Province">Tutta la provincia</option>
          </select>
          <p class="mt-3" *ngIf="targets !== null">
            <span style="color: red;">{{ targets === 0 ? '0 contatti trovati' : (targets === 1 ? '1 contatto trovato' :
              targets + ' contatti trovati') }}</span>
          </p>
        </div>
        <app-whatsapp-message-composer [data]="whatsappData" [type]="request.requestPersonalInfo.requestCategory"
          (sendWhatsappMessage)="sendWhatsappMessage($event)"></app-whatsapp-message-composer>
      </div>
      <div class="w-100" *ngIf="section.id !== 0">
         @if(section.id.toString() !== null){
          <app-text-editor-wrapper [controlText]="messages.get(section.id.toString()) | formControl"></app-text-editor-wrapper>
         }
        <div *ngIf="section.id === 3">
          <div class="mt-2 mb-2">
            <i class="bi bi-arrow-clockwise d-inline" (click)="updateAttachment()"></i>
            <p class="d-inline mr-2" style="margin-top: 10px;">Lista degli allegati:</p>
          </div>
          <div *ngFor="let item of attachments">
            <span class="d-inline deleteIcon" (click)="removeAttachment(item)"><i
                class="bi bi-x-square-fill"></i></span>
            <p style="font-weight: bold" class="d-inline">
              {{item.name}} {{item.surname}}: {{item.filename}}</p>
          </div>
        </div>
        <div *ngIf="section.id === 4">
          <div class="mt-2 mb-2">
            <i class="bi bi-arrow-clockwise d-inline" (click)="updatePhoneNumbers()"></i>
            <p style="margin-top: 10px;" class="d-inline mr-2">Aggiorna contatti:</p>
          </div>
          <div *ngFor="let phoneNumber of phoneNumbersArray">
            <span class="d-inline deleteIcon"><i class="bi bi-x-square-fill d-inline"
                (click)="removePhoneNumber(phoneNumber)"></i></span>
            <p style="font-weight: bold" class="d-inline">{{phoneNumber.name}}: {{phoneNumber.phoneNumbers}}</p>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center gap-1 my-3 justify-content-end">
        <button *ngIf="step !== 0" class="btn btn-secondary bg-secondary text-white" (click)="prevStep()">Precedente</button>
        <button *ngIf="step !== sections.length-1" class="btn btn-primary bg-primary text-white" (click)="nextStep()">Successivo</button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="d-flex gap-2 align-items-center my-4">
    <label class="label" for="scheduledAppointment">Colloquio Famiglia-Caregiver:</label>
    <input type="datetime-local" id="scheduledAppointment" [(ngModel)]="appointmentDate">
    <button class="btn btn-secondary text-light" (click)="saveAppointment()" *ngIf="!scheduledAppointment">Imposta</button>
    <button class="btn btn-primary text-light" (click)="deleteAppointment()" *ngIf="scheduledAppointment">Elimina</button>
    <span *ngIf="scheduledAppointment && scheduledAppointment.createdBy">Inserito da: {{scheduledAppointment.createdBy}}</span>
  </div>

  <mat-expansion-panel style="margin-top: 100px" *ngIf="requestMessages">
    <mat-expansion-panel-header>
      <mat-panel-title><b>Storico Messaggi</b>
        <i class="bi bi-arrow-clockwise ms-2" placement="end" ngbTooltip="Aggiorna storico messaggi"
          (click)="$event.stopPropagation();updateMsgHistory()"></i>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div style="max-width: 100%">
      <table style="width: 100%; max-width: fit-content;">
        <!-- <thead>
          <tr>
            <th>Soggetto</th>
            <th>Tipo</th>
            <th>Data Invio</th>
          </tr>
        </thead> -->
        <tbody style="display: flex; flex-direction: column; gap: 10px; max-width: max-content;">
          <!-- <mat-expansion-panel *ngIf="request.messages.emailTateBody">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <tr class="email-panel">
                  <th>{{ request.messages.emailTateSubject }}</th>
                  <th>Email Tate</th>
                  <th>{{ request.messages.emailTateDate! | fhDate}}</th>
                </tr>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="request.messages.emailTateBody"></div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="request.messages.emailPreventivoBody">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <tr class="email-panel">
                  <th>{{ request.messages.emailPreventivoSubject }}</th>
                  <th>Email Preventivo</th>
                  <th>{{ request.messages.emailPreventivoDate! | fhDate}}</th>
                </tr>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="request.messages.emailPreventivoBody"></div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="request.messages.emailPacchettoRichiestoBody">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <tr class="email-panel">
                  <th>{{ request.messages.emailPacchettoRichiestoSubject }}</th>
                  <th>Email Invio Profili</th>
                  <th>{{ request.messages.emailPacchettoRichiestoDate! | fhDate}}</th>
                </tr>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="request.messages.emailPacchettoRichiestoBody"></div>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="request.messages.emailCellulareBody">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <tr class="email-panel">
                  <th>{{ request.messages.emailCellulareSubject }}</th>
                  <th>Email Cellulare</th>
                  <th>{{ request.messages.emailCellulareDate! | fhDate}}</th>
                </tr>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="request.messages.emailCellulareBody"></div>
          </mat-expansion-panel> -->

          <mat-expansion-panel *ngFor="let message of requestMessages">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <tr class="email-panel">
                  <th>{{ message.subject }}</th>
                  <th>{{ message.type }}</th>
                  <th>{{ message.sentTimestamp | fhDate}}</th>
                </tr>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div [innerHTML]="message.body"></div>
            <mat-expansion-panel *ngIf="message.babysitterSelectedName!.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title>Profili inviati</mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <ul>
                  <li *ngFor="let bb of message.babysitterSelectedName">{{ bb }}</li>
                </ul>
              </div>
            </mat-expansion-panel>
          </mat-expansion-panel>
        </tbody>
      </table>
    </div>
  </mat-expansion-panel>
</div>
