import { GoogleMapsService } from './../caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { WhatsappBody } from './../smartphone-container/smartphone-container.component';
import { RouterStateManagerService } from './../../shared/services/router-state-manager/router-state-manager.service';
import { environment } from './../../../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, of, Subject } from 'rxjs';
import { RequestMessagesTemplatingService } from '../request-messages-templating/request-messages-templating.service';
import { HttpClient } from '@angular/common/http';
import { BulletinBoardService } from 'src/app/shared/services/bulletin-board-service/bulletin-board.service';
import { ModalService, RequestUtilitiesService, Mail, MailService, Request, RequestPetsitterDetail, RequestNurseDetail, RequestCategory, Page, CustomerType, ColfRequestDetail } from 'npx-family-happy-common';
import { MessagesHistoryService } from 'src/app/shared/services/messages-history-service/messages-history.service';
import { TitleCasePipe } from '@angular/common';
import { RequestUpdate } from 'src/app/shared/models/request-update.model';
import { UpperCasePipe } from '@angular/common';
import { User } from 'src/app/shared/models/user.model';
import { CaregiverAttachment } from 'src/app/shared/models/caregiver-attachment.model';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { CaregiverChosenAttachment } from 'src/app/shared/models/caregiver-chosen-attachments.model';
import { ScheduledAppointmentService } from 'src/app/shared/services/scheduled-appointment-service/scheduled-appointment.service';
import { ScheduledAppointment, ScheduledAppointmentType } from 'src/app/shared/models/scheduled-appointment.model';

@Injectable({
  providedIn: 'root',
})
export class RequestService implements OnDestroy {
  private baseURL = environment.requestServiceURL;
  private requestSelected!: Request;
  private formBaseURL = environment.formBaseURL;
  private attachments: CaregiverAttachment[] = []
  private phoneAttachments: CaregiverChosenAttachment[] = []
  private unsubscribe = new Subject<void>();
  private currentUserEmail: string = '';
  private caregiversReached = 0

  //variables for convertion script
  currentPage = new BehaviorSubject<Number>(0);
  private counterTotal = 0;

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private userStorage: UserStorageService,
    private mailTemplating: RequestMessagesTemplatingService,
    private utilities: RequestUtilitiesService,
    private mailService: MailService,
    private modalService: ModalService,
    private stateManager: RouterStateManagerService,
    private boardService: BulletinBoardService,
    private messagesHistoryService: MessagesHistoryService,
    private titleCasePipe: TitleCasePipe,
    private uppercasePipe: UpperCasePipe,
    private googleMapsService: GoogleMapsService,
    private scheduledAppointmentService: ScheduledAppointmentService
  ) {
    this.stateManager.getRequestState().pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
      if (state.request) {
        this.requestSelected = state.request;
      }
    });
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      if (user) {
        this.currentUserEmail = user.email;
      }
    })

    this.mailTemplating.getCaregiverAttachments().pipe(takeUntil(this.unsubscribe)).subscribe((attachments) => {
      this.attachments = attachments
    })
    this.mailTemplating.getCaregiverPhoneNumbers().pipe(takeUntil(this.unsubscribe)).subscribe((attachments) => {
      this.phoneAttachments = attachments
    })
  }



  setPetsitterRequestDetail(data: RequestPetsitterDetail) {
    this.mailTemplating.setPetsitterDetail(data);
  }

  setNurseRequestDetail(data: RequestNurseDetail) {
    this.mailTemplating.setNurseDetail(data);
  }
  setColfRequestDetail(data: ColfRequestDetail) {
    this.mailTemplating.setColfDetail(data);
  }

  // Read requests from Firestore
  getRequests(filters: any, page: number, limit: number, sortDirection: string, sortField?: string | null) {

    let request = {
      babysitters: null,
      category: filters.category ?? null,
      city: filters.city ?? null,
      email: filters.email ?? null,
      id: filters.id ?? null,
      limit,
      operator: filters.operator ? filters.operator.replace(`'`, `''`) : null,
      page,
      parent: filters.parent ? filters.parent.replace(`'`, `''`) : null,
      phone: filters.phone ?? null,
      province: filters.province !== '' ? filters.province : null,
      sortDirection,
      sortField,
      status: filters.status ?? null,
      zone: filters.zone ?? null
    };
    return this.http.post<Page<Request>>(this.baseURL + '/find', request)
  }

  // Get single request associated to the provided requestID
  getSingleRequest(requestID: number, fromTrash = false) {
    let request: any = {
      babysitters: null,
      category: null,
      city: null,
      email: null,
      id: requestID,
      limit: 10,
      operator: null,
      parent: null,
      phone: null,
      province: null,
      sortDirection: "DESC",
      sortField: null,
      status: null,
    }
    if (fromTrash) {
      request.status = 'DELETED';
      return this.http.post<Page<Request>>(this.baseURL + '/find', request).pipe(map((data) => {
        const request = data.content[0];
        return this.handleRequest(request);
      }));
    } else {
      return this.http.post<Page<Request>>(this.baseURL + '/find', request).pipe(map((data) => {
        const request = data.content[0];
        return this.handleRequest(request);
      }));
    }
  }

  getRequestByID(id: string) {
    return this.http.get<Request>(this.baseURL + '/findById/' + id).pipe(map((data) => {
      const request = data
      return this.handleRequest(request);
    }));
  }

  private handleRequest(request: Request) {
    if (request.requestPersonalInfo.expDate) {
      request.requestPersonalInfo.expDate = new Date(request.requestPersonalInfo.expDate);
    }
    if (request.requestPersonalInfo.manualExpDate) {
      request.requestPersonalInfo.manualExpDate = new Date(request.requestPersonalInfo.manualExpDate);
    }
    if (request.requestPersonalInfo.contactDate) {
      if (request.requestPersonalInfo.contactDate.match(/\d{4}\-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/)) {
        request.requestPersonalInfo.contactDate = request.requestPersonalInfo.contactDate.substring(0, 16);
      }
    }
    return request;
  }

  // Update request on Backend
  updateRequest(requestData: any) {
    return this.http.post<any>(this.baseURL + '/import', requestData);
  }

  // Create new request on Backend
  createRequest(requestData: any) {
    return this.http.post<any>(this.baseURL + '/import', requestData);
  }

  // Generate a "Profiles email sent" event
  generateProfilesSentEvent(body: ProfileSentEventBody) {
    return this.http.post<Request>(this.baseURL + '/profiles-mail-sent-publisher', body);
  }

  // Generate a "Profiles email sent" event
  generateContactSentEvent(body: ContactSentEventBody) {
    return this.http.post<Request>(this.baseURL + '/contact-mail-sent-publisher', body);
  }

  duplicateRequest(id: number) {
    return this.http.post<Request>(this.baseURL + `/${id}/duplicate`, {});
  }

  // Restore request from trash
  restoreRequest(id: number) {
    return this.changeStatus(id, 'NUOVA');
  }

  changeStatus(id: number, newStatus: string) {
    return this.http.patch<Request>(`${this.baseURL}/request/${id}/change-status?status=${newStatus}`, {});
  }

  // Delete request from Backend
  deleteRequest(id: number, trash = false) {
    if (trash) {
      return this.http.delete<Request>(this.baseURL + `/delete/${id}`);
    } else {
      return this.changeStatus(id, 'DELETED');
    }
  }

  setReminder(date: Date, time: number) {
    const request = {
      id: this.requestSelected.id,
      parentName: this.requestSelected.requestPersonalInfo.parentName,
      parentSurname: this.requestSelected.requestPersonalInfo.parentSurname,
      operator: this.requestSelected.requestPersonalInfo.operator,
      phone: this.requestSelected.requestPersonalInfo.parentPhone,
      email: this.requestSelected.requestPersonalInfo.parentEmail
    }
    const subject = `Remind chiamata richiesta ${request.id}: ${request.parentName} ${request.parentSurname}`;
    const message = `${request.operator} ricordati di richiamare ${request.parentName} ${request.parentSurname} della richiesta *${request.id}* al *${request.phone}* (email: ${request.email}) intorno alle ${date.toLocaleDateString('it-IT', { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}.`;
    date.setTime(date.getTime() - time);
    return this.firestore.collection('tasks', ref => ref
      .where('email.to', '==', request.operator)
      .where('email.subject', '==', subject)
      .where('type', '==', 'EMAIL')
    ).get().subscribe((result) => {
      let promise: Promise<any>;
      if (result.docs.length > 0) {
        promise = this.firestore.collection('tasks').doc(result.docs[0].id).update({
          performAt: date,
          message
        })
      } else {
        promise = this.firestore.collection('tasks').add({
          performAt: date,
          email: {
            to: request.operator,
            subject,
            message
          },
          type: 'EMAIL'
        })
      }
      promise.then((_) => {
        this.updateRequest(this.requestSelected).subscribe(() => {
          this.modalService.showSuccessMessage('Richiesta aggiornata.');
        })
        this.modalService.showSuccessMessage('Promemoria impostato con successo.');
      }).catch((error) => {
        this.modalService.showErrorMessage(`Si è verificato un errore nell'impostazione del promemoria: ${error}`);
      })
    });
  }

  generateParentFormLink() {
    let formLink = environment.formBaseURL + '/matching-su-misura?email=' + this.requestSelected.requestPersonalInfo.parentEmail;
    this.modalService.showSuccessMessage(`Link copiato con successo!<br> ${formLink}`);
    navigator.clipboard.writeText(formLink);
  }

  getWhatsappData() {
    return this.mailTemplating.getWhatsappData(this.requestSelected);
  }

  sendWhatsappMessage(data: WhatsappBody, update = false, targetReached: number) {
    this.modalService.showLoadingWithMessage('Invio in corso...');
    let newMessage = '';
    this.caregiversReached = targetReached
    switch (this.requestSelected.requestPersonalInfo.requestCategory) {
      case 'COLF':
        newMessage = `Siamo alla ricerca di un* Colf nella città di ${data.city} per ${data.description}<br>Tempistiche: ${data.starting}<br>Remunerazione netta: ${data.wage}`;
        break;
      case 'NURSE':
        newMessage = `Siamo alla ricerca di un* Badante nella città di ${data.city} per ${data.description}<br>Tempistiche: ${data.starting}<br>Remunerazione netta: ${data.wage}`;
        break;
      case 'PETSITTER':
        newMessage = `Siamo alla ricerca di un Petsitter nella città di ${data.city} per ${data.description}<br>Tempistiche: ${data.starting}<br>Remunerazione netta: ${data.wage}`;
        break;
      case 'BABYSITTER':
      default:
        newMessage = `Siamo alla ricerca di ${data.role} per ${data.childInfo} nella città di ${data.city} per ${data.description}<br>Tempistiche: ${data.starting}<br>Remunerazione netta: ${data.wage}`;
        break;
    }
    const subject = this.requestSelected.operationalInfo.adTitle ? `Proposta n. ${data.requestID} - ${this.requestSelected.operationalInfo.adTitle}` : `Proposta n. ${data.requestID}`;
    return this.http.post(`${environment.cloudFunctionsBaseURL}/sendWhatsapp`, data).pipe(mergeMap((res) => {
      console.log('Invio messaggio whatsapp:', res);
      return this.boardService.createAdFromWhatsapp(newMessage, subject, this.requestSelected, update).pipe(mergeMap((res) => {
        console.log('creazione annuncio:', res);
        let message = [
          {
            requestId: this.requestSelected.id,
            subject: subject,
            body: newMessage,
            type: 'Whatsapp Broadcast',
            sentTimestamp: new Date()
          }
        ];
        let oldStatus = this.requestSelected.operationalInfo.status;
        this.requestSelected.operationalInfo.status = 'SELEZIONE ATTIVA';
        this.requestSelected.operationalInfo.statusUpdated = new Date();
        this.requestSelected.timestamp = new Date();
        this.stateManager.setRequest(this.requestSelected);
        return this.updateRequest(this.requestSelected).pipe(mergeMap(res => {
          return this.postRequestUpdate(this.requestSelected.id,
            `Lo stato della richiesta è passato da ${this.uppercasePipe.transform(oldStatus)} a SELEZIONE ATTIVA. Caregivers contattate ${this.caregiversReached}`,
            this.requestSelected.timestamp).pipe(mergeMap((res) => {
              return this.sendSelectionActiveWhatsappMessage(this.requestSelected.id).pipe(mergeMap(res => {
                return this.messagesHistoryService.setMessage(message).pipe(tap((res) => {
                  this.modalService.hideLoading();
                  this.modalService.showSuccessMessage('Whatsapp Broadcast inviato correttamente.');
                }));
              }));
            }))
        }));
      }))
    }));
  }

  sendMessage(emailType: number, subject: string, newMessage: string, provincia: string, update = false) {
    this.modalService.showLoadingWithMessage('Invio in corso...');
    // Sanitize Message replacing all the occurrences of HTML special code into the corresponding characters
    newMessage = this.utilities.sanitizeMessage(newMessage);
    let mail: Mail;
    let user = JSON.parse(localStorage.getItem("user") ?? '') as User;
    let userEmail = this.requestSelected.requestPersonalInfo.parentEmail;
    let senderEmail = this.mailTemplating.getMailServiceByOperator(this.requestSelected.requestPersonalInfo.operator, environment.production);
    let selectedBabysitters: string[] = [];

    //  this.requestUtilitiesService.getBabysitterAttachments(this.requestSelected.operationalInfo.babysitterSelected).forEach(item => {
    //   selectedBabysitters.push(item.name + ' ' + item.surname);
    // });
    // this.mailTemplating.getCaregiverAttachments()
    switch (emailType) {
      case 1:
        if (environment.production === false) {
          mail = new Mail(senderEmail, userEmail, subject, newMessage, []);
          this.sendEmail(mail).subscribe((response: string | undefined) => {
            if (response) {
              this.boardService.createAd(newMessage, subject, this.requestSelected, update).subscribe(res => {
                console.log('creazione annuncio:', res);
              });

              let message = [
                {
                  requestId: this.requestSelected.id,
                  subject: subject,
                  body: newMessage,
                  type: 'Email Tate',
                  sentTimestamp: new Date()
                }
              ];
              let oldStatus = '' + this.requestSelected.operationalInfo.status;
              this.requestSelected.operationalInfo.status = 'SELEZIONE ATTIVA';
              this.requestSelected.operationalInfo.statusUpdated = new Date();
              this.requestSelected.timestamp = new Date();
              this.stateManager.setRequest(this.requestSelected);
              this.updateRequest(this.requestSelected).subscribe(res => {
                if (oldStatus !== this.requestSelected.operationalInfo.status) {
                  // Post status update
                  this.postRequestUpdate(this.requestSelected.id,
                    `Lo stato della richiesta è passato da ${this.uppercasePipe.transform(oldStatus)} a SELEZIONE ATTIVA.`,
                    this.requestSelected.timestamp).subscribe();
                  // If the request was not in SELEZIONE ATTIVA, send the message
                  this.sendSelectionActiveWhatsappMessage(this.requestSelected.id).pipe(mergeMap(res => {
                    return this.messagesHistoryService.setMessage(message).pipe(tap((res) => {
                      this.modalService.hideLoading();
                      this.modalService.showSuccessMessage('Email inviata correttamente.');
                    }));
                  })).subscribe();
                } else {
                  this.modalService.hideLoading();
                  this.messagesHistoryService.setMessage(message).subscribe((res) => {
                    this.modalService.hideLoading();
                    this.modalService.showSuccessMessage('Email inviata correttamente.');
                  })
                }
              });
            } else {
              this.modalService.hideLoading();
            }
          });
          break;
        } else {
          this.firestore.collection('activeCampaign').add({
            subject: subject,
            body: newMessage,
            province: this.requestSelected.requestPersonalInfo.provincia,
            category: this.requestSelected.requestPersonalInfo.requestCategory
          }).then((doc) => {
            this.boardService.createAd(newMessage, subject, this.requestSelected, update).subscribe(res => {
              console.log('creazione annuncio:', res);
            });
            let message = [
              {
                requestId: this.requestSelected.id,
                subject: subject,
                body: newMessage,
                type: 'Email Tate',
                sentTimestamp: new Date()
              }
            ];
            let oldStatus = '' + this.requestSelected.operationalInfo.status;
            this.requestSelected.operationalInfo.status = 'SELEZIONE ATTIVA';
            this.requestSelected.operationalInfo.statusUpdated = new Date();
            this.requestSelected.timestamp = new Date();
            this.stateManager.setRequest(this.requestSelected);
            this.updateRequest(this.requestSelected).subscribe(res => {
              if (oldStatus !== this.requestSelected.operationalInfo.status) {
                // Post status update
                this.postRequestUpdate(this.requestSelected.id,
                  `Lo stato della richiesta è passato da ${this.uppercasePipe.transform(oldStatus)} a SELEZIONE ATTIVA.`,
                  this.requestSelected.timestamp).subscribe();
                // If the request was not in SELEZIONE ATTIVA, send the message
                this.sendSelectionActiveWhatsappMessage(this.requestSelected.id).pipe(mergeMap(res => {
                  return this.messagesHistoryService.setMessage(message).pipe(tap((res) => {
                    this.modalService.hideLoading();
                    this.modalService.showSuccessMessage('Email inviata correttamente.');
                  }));
                })).subscribe();
              } else {
                this.modalService.hideLoading();
                this.messagesHistoryService.setMessage(message).subscribe((res) => {
                  this.modalService.hideLoading();
                  this.modalService.showSuccessMessage('Email inviata correttamente.');
                })
              }
            });
          });
          break;
        }
      case 2:
        mail = new Mail(senderEmail, environment.production ? this.requestSelected.requestPersonalInfo.parentEmail : userEmail, subject, newMessage, []);
        this.sendEmail(mail).subscribe((response: string | undefined) => {
          if (response) {
            let message = [
              {
                requestId: this.requestSelected.id,
                subject: subject,
                body: newMessage,
                type: 'Email Preventivo',
                sentTimestamp: new Date()
              }
            ];
            let oldStatus = '' + this.requestSelected.operationalInfo.status;
            this.requestSelected.operationalInfo.status = 'INVIATO PREVENTIVO';
            this.requestSelected.operationalInfo.statusUpdated = new Date();
            this.requestSelected.timestamp = new Date();
            this.stateManager.setRequest(this.requestSelected);
            this.updateRequest(this.requestSelected).subscribe(res => {
              if (oldStatus !== this.requestSelected.operationalInfo.status) {
                this.postRequestUpdate(this.requestSelected.id,
                  `Lo stato della richiesta è passato da ${this.uppercasePipe.transform(oldStatus)} a INVIATO PREVENTIVO.`,
                  this.requestSelected.timestamp).subscribe();
                this.sendQuotationConfirmWhatsappMessage(this.requestSelected.id).pipe(mergeMap(res => {
                  return this.messagesHistoryService.setMessage(message).pipe(tap((res) => {
                    this.modalService.hideLoading();
                    this.modalService.showSuccessMessage('Email inviata correttamente.');
                  }));
                })).subscribe();
              } else {
                this.modalService.hideLoading();
                this.modalService.showSuccessMessage('Email inviata correttamente.');
              }
            });
          } else {
            this.modalService.hideLoading();
          }
        });
        break;
      case 3:
        mail = new Mail(senderEmail, environment.production ? this.requestSelected.requestPersonalInfo.parentEmail : userEmail, subject,
          newMessage, this.attachments);

        //INSERT CAREGIVERS NAME ON babysitterSelectedName (for messages history)
        this.attachments.forEach((att) => {
          if (att.name && att.surname) {
            let caregiverName = att.name + ' ' + att.surname
            selectedBabysitters.push(caregiverName)
          }
        })

        this.sendEmail(mail).subscribe((response: string | undefined) => {
          if (response) {
            const emailSentBody = [
              {
                requestId: this.requestSelected.id,
                subject: subject,
                body: newMessage,
                type: 'Email Invio Profili',
                babysitterSelectedName: selectedBabysitters,
                sentTimestamp: new Date()
              }
            ];
            const emailSentEventBody: ProfileSentEventBody = {
              requestId: this.requestSelected.id,
              caregiverSelected: this.attachments.map((CV) => CV.docRef),
              sentTimestamp: new Date(),
              businessLine: this.requestSelected.requestPersonalInfo.requestCategory as RequestCategory
            }
            // Generate an event so that other operations are performed in the backend
            this.generateProfilesSentEvent(emailSentEventBody).subscribe(request => {
              this.requestSelected = request;
              this.stateManager.setRequest(this.requestSelected, true);
            });

            // Update the messages history with the new email
            this.messagesHistoryService.setMessage(emailSentBody).subscribe((res) => {
              this.modalService.hideLoading();
              this.modalService.showSuccessMessage('Email inviata correttamente.');
            });
          } else {
            this.modalService.hideLoading();
          }
        });
        break;
      case 4:
        mail = new Mail(senderEmail, environment.production ? this.requestSelected.requestPersonalInfo.parentEmail : userEmail, subject, newMessage, []);
        //INSERT CAREGIVERS NAME ON babysitterSelectedName (for messages history)
        this.phoneAttachments.forEach((att) => {
          if (att.phoneText) {
            let caregiverName = att.phoneText.replace(/<\/?p>/g, '')
            selectedBabysitters.push(caregiverName)
          }
        })
        this.sendEmail(mail).subscribe((response: string | undefined) => {
          if (response) {
            const emailSentBody = [
              {
                requestId: this.requestSelected.id,
                subject: subject,
                body: newMessage,
                type: 'Email Cellulare',
                sentTimestamp: new Date(),
                babysitterSelectedName: selectedBabysitters
              }
            ];
            const emailSentEventBody: ContactSentEventBody = {
              requestId: this.requestSelected.id,
              caregiverChosen: this.phoneAttachments.map((bb) => bb.docRef),
              sentTimestamp: new Date(),
              businessLine: this.requestSelected.requestPersonalInfo.requestCategory as RequestCategory
            }
            // Generate an event so that other operations are performed in the backend
            this.generateContactSentEvent(emailSentEventBody).subscribe(request => {
              this.requestSelected = request;
              this.stateManager.setRequest(this.requestSelected, true);
            });

            // Update the messages history with the new email
            this.messagesHistoryService.setMessage(emailSentBody).subscribe((res) => {
              this.modalService.hideLoading();
              this.modalService.showSuccessMessage('Email inviata correttamente.');
            });
          } else {
            this.modalService.hideLoading();
          }
        });
        break;
    }
  }

  tateSubject() {
    return `Proposta n.${this.requestSelected.id} - ${this.requestSelected.operationalInfo.adTitle}`;
  }

  private sendEmail(mail: Mail): Observable<string | undefined> {
    if (mail.to.match(' ')) {
      this.modalService.showErrorMessage(`Si è verificato un errore. L'indirizzo email destinatario della email non è formattato in maniera corretta. Si prega di verificare.`);
      return of(undefined);
    } else {
      return this.mailService.sendCustom(environment.mailServiceURL, mail);
    }
  }

  sendEmailDiscarded(caregiverID: string, caregiverName: string, caregiverEmail: string, caregiverRole: string, requestID: number, provincia: string, discardedType: string) {
    const email = environment.production ? caregiverEmail : this.currentUserEmail; // Send mail to tester instead of the caregiver
    let message = '';
    let senderEmail = this.utilities.getMailService(CustomerType.B2C, true);
    let subject = `Esito della tua candidatura per l'offerta n°${requestID}`;
    let formURL = `${this.formBaseURL}/${caregiverRole}-form?id=${caregiverID}`;

    let processMessage = (requestRef: string) => {
      switch (discardedType) {
        case 'SUITABLE':
          message = `<p>Car* ${this.titleCasePipe.transform(caregiverName)},<br>
        ti scriviamo in merito all'annuncio ${requestRef}.</p>
        Abbiamo sottoposto il tuo profilo alla famiglia, ma per questa selezione non sei stata inserita nella lista candidate che hanno voluto visionare.<p>
        <p>Le motivazioni sono associate agli anni di esperienza o alla vicinanza al luogo di lavoro o all’accuratezza con cui hai compilato il Curriculum rispetto alle altre
        candidate scelte. Per questo ti consigliamo di aggiornare al meglio il tuo CV per avere più opportunità. Per farlo clicca <a href="${formURL}">QUI</a>.<p>
        <p>Mi raccomando non demordere: abbiamo 600 richieste al mese ed è probabile che ci sia quella che fa al caso tuo.<br>
        Ti suggeriamo di candidarti solo a posizioni che sono veramente in linea con la tua esperienza e la tua disponibilità.</p>
        <p>A presto e in bocca al lupo,<br>
        Il team di Family+Happy</p>`;
          /* <p>Per supportarti abbiamo creato un tutorial che aiuta a presentarti al meglio alla famiglia quando dovrai incontrarla. Considera che le cose che mostriamo derivano da
          oltre 10.000 interviste per cui sono assolutamente testate e vere. Clicca <a href="https://drive.google.com/file/d/1CSwn8GNntvdRUwCqf2mgPVVtC_Ot9hPo/view?usp=sharing">QUI</a>
          per vedere il tutorial.</p> */
          break;

        case 'SELECTED':
          message = `<p>Car* ${this.titleCasePipe.transform(caregiverName)},<br>
        grazie per esserti candidata all'annuncio ${requestRef},</p>
        <p>Come sai abbiamo proposto il tuo profilo insieme a quello di alcune altre candidate alla famiglia che sta offrendo questo lavoro. Con questa mail vogliamo avvisarti
        che al momento la famiglia ha optato per un'altra candidata, per motivi di anni di professione e vicinanza alla casa. Ci spiace perché ci eri molto vicin*.</p>
        <p>Mi raccomando non demordere: abbiamo 600 richieste al mese ed è probabile che ci sia quella che fa al caso tuo.</p>
        <p>A presto e in bocca al lupo,<br>
        Il team di Family+Happy</p>`;
          break;

        case 'CHOSEN':
          message = `<p>Car* ${this.titleCasePipe.transform(caregiverName)},<br>
          ti scriviamo in merito all'annuncio ${requestRef}.</p>
          <p>Con questa mail desideriamo aggiornarti sul fatto che la famiglia che sta offrendo questo posto di lavoro <b>ha optato per un'altra soluzione.</b></p>
          <br><p>Mi raccomando non demordere: abbiamo 600 richieste al mese ed è probabile che ci sia quella che fa al caso tuo.</p>
          <p>A presto e in bocca al lupo,<br>
          Il team di Family+Happy</p>`;
          break;

        default:
          break;
      }

      let mail = new Mail(senderEmail, email, subject, message, []);
      this.sendEmail(mail).subscribe();
    }

    this.boardService.getAdById(requestID).subscribe({
      next: (ad: any) => {
        processMessage(ad.title);
      },
      error: () => {
        processMessage(requestID.toString());
      }
    })

  }

  getRequestUpdates(requestID: number, page: number, limit: number) {
    return this.http.get<RequestUpdate[]>(this.baseURL + '/updates', { params: { requestId: requestID, page, limit } });
  }

  postRequestUpdate(requestID: number, description: string, timestamp: Date) {
    return this.http.post<RequestUpdate>(this.baseURL + '/updates', { requestId: requestID, description, timestamp });
  }

  exportRequestUpdates() {
    return this.http.get(`${this.baseURL}/updates/export-request-to-excel`, { responseType: 'blob' });
  }

  exportRequestsFromDB() {
    return this.http.get(this.baseURL + '/export-request-to-excel', { responseType: 'blob' });
  }

  sendSelectionActiveWhatsappMessage(requestID: number) {
    return this.http.post(`${this.baseURL}/automations/request-active-selection?requestID=${requestID}`, {});
  }

  sendQuotationConfirmWhatsappMessage(requestID: number) {
    return this.http.post(`${this.baseURL}/automations/after-quotation?requestID=${requestID}`, {});
  }

  getNewRequestTemplate() {
    return this.http.get<Request>(`${this.baseURL}/empty-request`, {});
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  //script-------------------------------------------------------------------------------
  getPage(filters: any, page: any, limit: number): void {
    const paginatedRequest = { ...filters, page, limit };
    this.http.post<any>(`${this.baseURL}/find`, paginatedRequest).subscribe(data => {
      if (data.length !== 0) {
        this.convertAddress(data.content, page, filters);
      }
    });
  }

  // CONVERT ADDRESSES INTO COORDINATES - SCRIPT
  async convertAddress(array: Request[], page: any, filters: any): Promise<void> {
    let totalConversion = 0
    for (let i = 0; i < array.length; i++) {
      const request = array[i];
      const address = request.requestPersonalInfo.parentAddress;
      if (address !== null && address !== "") {
        if (request.requestPersonalInfo.latitude === null) {
          try {
            const res = await this.googleMapsService.getCoordinates(address);
            const lat = res[0].geometry.location.lat();
            const lng = res[0].geometry.location.lng();

            const updatedRequest = {
              ...request,
              requestPersonalInfo: {
                ...request.requestPersonalInfo,
                latitude: lat,
                longitude: lng
              }
            };
            await this.updateRequest(updatedRequest).pipe(takeUntil(this.unsubscribe)).toPromise();
            await this.delay(1000); // 1000 ms = 1 secondo
          } catch (err) {
            console.log(err);
          }
        } else {
          console.log("COORDINATES ALREADY SAVED")
        }
      } else {
        console.log("UPDATED REQUEST ADDRESS NULL OR EMPTY");
      }
      totalConversion++
    }
    // Incrementa la pagina corrente e verifica se ci sono altre pagine da caricare
    await this.delay(1500);
    const nextPage = page + 1;
    console.log(totalConversion, "CONVERSIONI TOTALI")
    if (array.length !== 0) {
      this.getPage(filters, nextPage, 50); // Chiamata alla pagina successiva
    } else {
      console.log("converione finita")
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

export interface ProfileSentEventBody {
  requestId: number,
  // babysitterSelected: string[],
  caregiverSelected: string[],
  sentTimestamp: Date,
  businessLine: RequestCategory
}

export interface ContactSentEventBody {
  requestId: number,
  caregiverChosen: string[],
  sentTimestamp: Date,
  businessLine: RequestCategory
}
