import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Petsitter } from 'npx-family-happy-common';
import { Injectable } from '@angular/core';
import { map, Observable, BehaviorSubject, forkJoin, catchError } from 'rxjs';


const DEFAULT_FILTERS = {
  catHealing: null,
  catHosting: null,
  dogHealing: null,
  dogHosting: null,
  age_lower: null,
  age_upper: null,
  cluster1: false,
  cluster2: false,
  cluster3: false,
  contract: null,
  days: null,
  disability: false,
  doc_ref: null,
  drugs: false,
  email: null,
  fhsub: false,
  homeService: false,
  hosting: false,
  hours: null,
  illness: false,
  limit: 10,
  name: null,
  nightShift: false,
  page: 0,
  pension: false,
  phone: null,
  province: null,
  walking: false
}

@Injectable({
  providedIn: 'root'
})
export class PetsitterService {
  baseURL = environment.petsitterServiceURL;
  petsitterList = new BehaviorSubject<Petsitter[]>([]);

  constructor(private http: HttpClient, private firestore: AngularFirestore) {
  }

  checkEmptyField(field: string) {
    return field !== '' ? field : 'unknown';
  }

  getFiveSuggestions(search: string, province: string): Observable<Petsitter[]> {
    const filters = {
      ...DEFAULT_FILTERS,
      limit: 5,
      name: search ? search.replace(`'`, `''`) : '',
      province: province !== '' ? province : null
    };

    return this.http.post<Petsitter[]>(this.baseURL + '/find', filters).pipe(map((petsitterList) => {
      return petsitterList.filter(petsitter => petsitter.rating.dangerous === false).slice(0, 5);
    }));
  }

  getPetsitter(filters: any, limit: number): Observable<Petsitter[]> {
    const request = this.getPetsitterFilters(filters, limit);

    this.http.post<Petsitter[]>(this.baseURL + '/find', request).subscribe((data) => {
      this.petsitterList.next(data);
    });

    return this.petsitterList;
  }

  getWhatsappFilteredTargets(filters: any, latitude: number, longitude: number, range: number | null = 5, limit = 20): any {
    if (filters) {

      let request = {
        age_lower: filters.fromAge ?? null,
        age_upper: filters.toAge ?? null,
        catHealing: filters.catHealing === '' ? null : (filters.catHealing ?? null),
        catHosting: filters.catHosting === '' ? null : (filters.catHosting ?? null),
        cluster1: filters.exp.cluster1 ?? false,
        cluster2: filters.exp.cluster2 ?? false,
        cluster3: filters.exp.cluster3 ?? false,
        contract: filters.contract === '' ? null : (filters.contract ?? null),
        days: filters.days === '' ? null : (filters.days ?? null),
        disability: filters.disability ?? false,
        doc_ref: filters.docRef ?? null,
        dogHealing: filters.dogHealing === '' ? null : (filters.dogHealing ?? null),
        dogHosting: filters.dogHosting === '' ? null : (filters.dogHosting ?? null),
        drugs: filters.drugs ?? false,
        email: filters.email ?? null,
        fhsub: filters.fhsub ?? false,
        evaluation: filters.overall ?? false,
        homeService: filters.homeService ?? false,
        hosting: filters.hosting ?? false,
        hours: filters.hours === '' ? null : (filters.hours ?? null),
        illness: filters.illness ?? false,
        limit,
        latitude: filters.latitude ?? null,
        longitude: filters.longitude ?? null,
        range: range ?? null,
        name: null,
        nightShift: filters.nightShift ?? false,
        page: filters.page ?? 1,
        pension: filters.pension ?? false,
        phone: filters.phone ?? null,
        province: filters.province ?? null,
        walking: filters.walking ?? false
      }
      return this.http.post<any>(this.baseURL + '/get-whatsapp-filtered-target', request)
    }
  }

  getListOfPetsitters(ids: string[]) {
    let petsitters: any = {};
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      petsitters[id] = this.getSinglePetsitter(id).pipe(catchError((error) => { return error }));
    }
    return forkJoin(petsitters);
  }

  getSinglePetsitter(id: string) {
    const filters = {
      ...DEFAULT_FILTERS,
      doc_ref: id,
      limit: 1
    };

    return this.http.post<Petsitter[]>(this.baseURL + '/find', filters);
  }

  getOneBabysitter(id: string) {
    const filters = {
      ...DEFAULT_FILTERS,
      doc_ref: id,
      limit: 1
    }

    return this.http.post<Petsitter[]>(this.baseURL + '/find', filters).pipe(map((res: Petsitter[]) => res.length ? res[0] : null));
  }

  updatePetsitter(requestData: Petsitter) {
    return this.http.post(this.baseURL + '/import', requestData)
  }

  async getFirebaseDocRef(email: string) {
    const doc = await this.firestore.collection('babysitter').add({ placeholder: email });
    return doc.id;
  }

  createPetsitter(requestData: Petsitter) {
    return this.http.post(this.baseURL + '/import', requestData);
  }

  deletePetsitter(id: string) {
    // Remember to delete storage data
    return this.http.delete(this.baseURL + '/delete/' + id);
  }

  getWhatsappTargets(province: string, latitude: number | null, longitude: number | null, range: number | null) {
    let rangeParam = (range === null) ? '' : range;
    return this.http.get<string[]>(`${this.baseURL}/get-whatsapp-target?province=${province}&latitude=${latitude}&longitude=${longitude}&range=${rangeParam}`);
  }

  /* Utilities methods */

  // It converts an object to a string. e.g.: {sun: true, mon: false, tue: true} -> 'sun-tue'
  fromAvailObjectToString(object: any, type: string) {
    let result = '';
    if (object) {
      switch (type) {
        case 'hours':
          result += object['mor'] ? 'mor' : '';
          result += object['aft'] ? (result !== '' ? '-aft' : 'aft') : '';
          result += object['eve'] ? (result !== '' ? '-eve' : 'eve') : '';
          return result;
        case 'days':
          result += object['sun'] ? 'sun' : '';
          result += object['mon'] ? (result !== '' ? '-mon' : 'mon') : '';
          result += object['tue'] ? (result !== '' ? '-tue' : 'tue') : '';
          result += object['wed'] ? (result !== '' ? '-wed' : 'wed') : '';
          result += object['thu'] ? (result !== '' ? '-thu' : 'thu') : '';
          result += object['fri'] ? (result !== '' ? '-fri' : 'fri') : '';
          result += object['sat'] ? (result !== '' ? '-sat' : 'sat') : '';
          return result;
        case 'contract':
          result += object['occ'] ? 'occ' : '';
          result += object['part'] ? (result !== '' ? '-part' : 'part') : '';
          result += object['full'] ? (result !== '' ? '-full' : 'full') : '';
          return result;
        case 'catHealing':
          result += object['cat'] ? 'cat' : '';
          return result;
        case 'catHosting':
          result += object['cat'] ? 'cat' : '';
          return result;
        case 'dogHealing':
          result += object['dog'] ? 'dog' : '';
          result += object['dog1'] ? (result !== '' ? '-dog1' : 'dog1') : '';
          result += object['dog2'] ? (result !== '' ? '-dog2' : 'dog2') : '';
          result += object['dog3'] ? (result !== '' ? '-dog3' : 'dog3') : '';
          result += object['dog4'] ? (result !== '' ? '-dog4' : 'dog4') : '';
          return result;
        case 'dogHosting':
          result += object['dog'] ? 'dog' : '';
          result += object['dog1'] ? (result !== '' ? '-dog1' : 'dog1') : '';
          result += object['dog2'] ? (result !== '' ? '-dog2' : 'dog2') : '';
          result += object['dog3'] ? (result !== '' ? '-dog3' : 'dog3') : '';
          result += object['dog4'] ? (result !== '' ? '-dog4' : 'dog4') : '';
          return result;
        case 'tasks1':
          result += object['peeing'] ? 'peeing' : '';
          result += object['games'] ? (result !== '' ? '-games' : 'games') : '';
          result += object['social'] ? (result !== '' ? '-social' : 'social') : '';
          result += object['muzzle'] ? (result !== '' ? '-muzzle' : 'muzzle') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        case 'tasks2':
          result += object['interactions'] ? 'interactions' : '';
          result += object['walking'] ? (result !== '' ? '-walking' : 'walking') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        case 'tasks3':
          result += object['helpers'] ? 'helpers' : '';
          result += object['diseases'] ? (result !== '' ? '-diseases' : 'diseases') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        default:
          return '';
      }
    } else {
      return result;
    }
  }

  // It converts a string to an object (hour, contract or days property of Petsitter). e.g.: 'sun-tue' -> {sun: true, mon: false, tue: true}
  fromStringToAvailObject(origin: string, type: string) {
    if (origin) {
      let keys = origin.split('-') || [];
      switch (type) {
        case 'hours':
          return {
            mor: keys.findIndex(item => item === 'mor') !== -1,
            aft: keys.findIndex(item => item === 'aft') !== -1,
            eve: keys.findIndex(item => item === 'eve') !== -1,
          }
        case 'days':
          return {
            sun: keys.findIndex(item => item === 'sun') !== -1,
            mon: keys.findIndex(item => item === 'mon') !== -1,
            tue: keys.findIndex(item => item === 'tue') !== -1,
            wed: keys.findIndex(item => item === 'wed') !== -1,
            thu: keys.findIndex(item => item === 'thu') !== -1,
            fri: keys.findIndex(item => item === 'fri') !== -1,
            sat: keys.findIndex(item => item === 'sat') !== -1,
          };
        case 'contract':
          return {
            occ: keys.findIndex(item => item === 'occ') !== -1,
            part: keys.findIndex(item => item === 'part') !== -1,
            full: keys.findIndex(item => item === 'full') !== -1,
          };
        case 'catHealing':
          return {
            cat: keys.findIndex(item => item === 'cat') !== -1,
          };
        case 'catHosting':
          return {
            cat: keys.findIndex(item => item === 'cat') !== -1,
          };
        case 'dogHealing':
          return {
            dog: keys.findIndex(item => item === 'dog') !== -1,
            dog1: keys.findIndex(item => item === 'dog1') !== -1,
            dog2: keys.findIndex(item => item === 'dog2') !== -1,
            dog3: keys.findIndex(item => item === 'dog3') !== -1,
            dog4: keys.findIndex(item => item === 'dog4') !== -1,
          };
        case 'dogHosting':
          return {
            dog: keys.findIndex(item => item === 'dog') !== -1,
            dog1: keys.findIndex(item => item === 'dog1') !== -1,
            dog2: keys.findIndex(item => item === 'dog2') !== -1,
            dog3: keys.findIndex(item => item === 'dog3') !== -1,
            dog4: keys.findIndex(item => item === 'dog4') !== -1,
          };
        case 'tasks1':
          return {
            peeing: keys.findIndex(item => item === 'peeing') !== -1,
            games: keys.findIndex(item => item === 'games') !== -1,
            social: keys.findIndex(item => item === 'social') !== -1,
            muzzle: keys.findIndex(item => item === 'muzzle') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        case 'tasks2':
          return {
            interactions: keys.findIndex(item => item === 'interactions') !== -1,
            walking: keys.findIndex(item => item === 'walking') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        case 'tasks3':
          return {
            helpers: keys.findIndex(item => item === 'helpers') !== -1,
            diseases: keys.findIndex(item => item === 'diseases') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        default:
          return {
            occ: false,
            part: false,
            full: false,
          };
      }
    } else {
      switch (type) {
        case 'hours':
          return {
            mor: false,
            aft: false,
            eve: false,
          }
        case 'days':
          return {
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
          };
        case 'contract':
          return {
            occ: false,
            part: false,
            full: false,
          };
        case 'catHealing':
          return {
            cat: false,
          };
        case 'catHosting':
          return {
            cat: false,
          };
        case 'dogHealing':
          return {
            dog: false,
            dog1: false,
            dog2: false,
            dog3: false,
            dog4: false,
          };
        case 'dogHosting':
          return {
            dog: false,
            dog1: false,
            dog2: false,
            dog3: false,
            dog4: false,
          };
        case 'tasks1':
          return {
            peeing: false,
            games: false,
            social: false,
            muzzle: false,
            other: false
          }
        case 'tasks2':
          return {
            interactions: false,
            walking: false,
            other: false
          }
        case 'tasks3':
          return {
            helpers: false,
            diseases: false,
            other: false
          }
        default:
          return undefined;
      }
    }
  }

  exportPetsittersFromDB(filters: any) {
    const request = this.getPetsitterFilters(filters, 50);

    if (request) {
      return this.http.post(this.baseURL + '/export-petsitters-to-excel', request, { responseType: 'blob' });
    }

    throw new Error('No filters provided');
  }

  private getPetsitterFilters(filters: any, limit: number) {
    if (filters) {
      if (!filters.info) {
        filters.info = {};
      }
      if (!filters.avail) {
        filters.avail = {};
      }
      if (!filters.exp) {
        filters.exp = {};
      }
      if (!filters.lang) {
        filters.lang = {};
      }
      if (!filters.rating) {
        filters.rating = {};
      }
      if (!filters.engagement) {
        filters.engagement = {};
      }
      if (!filters.cert) {
        filters.cert = {};
      }
      return {
        age_lower: filters.fromAge ?? null,
        age_upper: filters.toAge ?? null,
        catHealing: filters.avail.catHealing ?? null,
        catHosting: filters.avail.catHosting ?? null,
        cluster1: filters.exp.cluster1 ?? false,
        cluster2: filters.exp.cluster2 ?? false,
        cluster3: filters.exp.cluster3 ?? false,
        contract: filters.avail.contract ?? null,
        days: filters.avail.days ?? null,
        disability: filters.avail.disability ?? false,
        doc_ref: filters.docRef ?? null,
        dogHealing: filters.avail.dogHealing ?? null,
        dogHosting: filters.avail.dogHosting ?? null,
        drugs: filters.avail.drugs ?? false,
        email: filters.info.email ?? null,
        fhsub: filters.info.fhsub ?? false,
        evaluation: filters.rating.overall ?? false,
        homeService: filters.avail.homeService ?? false,
        hosting: filters.avail.hosting ?? false,
        hours: filters.avail.hours ?? null,
        illness: filters.avail.illness ?? false,
        limit,
        latitude: filters.latitude ?? null,
        longitude: filters.longitude ?? null,
        range: filters.range * 1000 ?? null,
        name: filters.info.name ? filters.info.name.replace(`'`, `''`) : null,
        nightShift: filters.avail.nightShift ?? false,
        page: filters.page ?? 1,
        pension: filters.avail.pension ?? false,
        phone: filters.info.phone ?? null,
        province: filters.province ?? null,
        walking: filters.avail.walking ?? false
      }
    }
    return undefined;
  }
}
