import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractTime',
  standalone: true
})
export class ExtractTimePipe implements PipeTransform {

  transform(value: Date): string {
    if (!value) return '';

    try {
      const date = new Date(value);
      let offset = date.getTimezoneOffset() * (-60000);
      const adjustedExpiryDate = new Date(date.getTime() + offset);

      return adjustedExpiryDate.toLocaleTimeString('it-IT', {hour:'2-digit', minute:'2-digit'})
    } catch (error) {
      console.error('Invalid date format:', value);
      return '';
    }
  }

}
