import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';

@Component({
  selector: 'app-colf-filter-modal',
  templateUrl: './colf-filter-modal.component.html',
  styleUrls: ['./colf-filter-modal.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class ColfFilterModalComponent implements OnInit {

  private _resetAllFilters: boolean = false;

  @Input() filtersValue!: Observable<any>;
  @Input() showAgeFilter: boolean = false;
  @Output() onClose = new EventEmitter<void>();
  @Output() onFilter = new EventEmitter<any>();

  @Input()
  get resetAllFilters(): boolean {
    return this._resetAllFilters;
  }
  set resetAllFilters(value: boolean) {
    this._resetAllFilters = value;
    if(this._resetAllFilters === true){
      this.caregiverSelectionService.setFilters(null)
      this.resetFilters();
    }
  }

  filters!: UntypedFormGroup;

  constructor(private caregiverSelectionService: CaregiverSelectionService) { }

  ngOnInit(): void {
    this.filters = new UntypedFormGroup({
      // specialities: new FormGroup({
      //   injections: new FormControl(false),
      //   dressings: new FormControl(false),
      //   glycemia: new FormControl(false),
      //   technology: new FormControl(false),
      // }),
      roles: new UntypedFormGroup({
        cooking: new UntypedFormControl(false),
        gardening: new UntypedFormControl(false),
        groceries: new UntypedFormControl(false),
        helping: new UntypedFormControl(false),
        houseCleaning: new UntypedFormControl(false),
        ironing: new UntypedFormControl(false),
        sewing: new UntypedFormControl(false),
        terraceCleaning: new UntypedFormControl(false),
        washingMachines: new UntypedFormControl(false),
        windowCleaning: new UntypedFormControl(false),
      }),
      rating: new UntypedFormControl(false),
      license: new UntypedFormControl(false),
      car: new UntypedFormControl(false),
      fhsub: new UntypedFormControl(false),
      minAge: new UntypedFormControl(0),
      maxAge: new UntypedFormControl(0),
      page: new UntypedFormControl(1),
      days: new UntypedFormGroup({
        sun: new UntypedFormControl(false),
        mon: new UntypedFormControl(false),
        tue: new UntypedFormControl(false),
        wed: new UntypedFormControl(false),
        thu: new UntypedFormControl(false),
        fri: new UntypedFormControl(false),
        sat: new UntypedFormControl(false),
      }),
      hours: new UntypedFormGroup({
        mor: new UntypedFormControl(false),
        aft: new UntypedFormControl(false),
        eve: new UntypedFormControl(false),
      }),
      contract: new UntypedFormGroup({
        occ: new UntypedFormControl(false),
        part: new UntypedFormControl(false),
        full: new UntypedFormControl(false),
      }),
      lang: new UntypedFormGroup({
        english: new UntypedFormControl(false),
        italian: new UntypedFormControl(false),
        spanish: new UntypedFormControl(false)
      }),
      // exp: new FormGroup({
      //   cluster1: new FormControl(false),
      //   cluster2: new FormControl(false),
      // }),
      distance: new UntypedFormControl(0)
    });

    this.caregiverSelectionService.getFilters().subscribe((filters) => {
      if (filters !== undefined && filters !== null) {
        if (Object.keys(filters).length > 0) {
          this.filters.setValue(filters);
        } else {
          this.filters.reset();
        }
      }
    });
  }

  /* Close the modal */
  dismiss(event: Event) {
    if (event.target === document.getElementById('modal')) {
      this.onClose.emit();
    }
  }

  emitFilter() {
    this.caregiverSelectionService.setFilters(this.filters.value);
    this.onFilter.emit(this.filters.value);
    this.onClose.emit();
  }

  resetFilters() {
    if(this.filters !== undefined){
      this.filters.reset();
    }
  }
}
