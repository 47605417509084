import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { PetsitterState } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { RouterStateManagerService } from '../../../shared/services/router-state-manager/router-state-manager.service';
import { Petsitter, CertificateService, ModalService, ProvinceFilterComponent } from 'npx-family-happy-common';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { PetsitterService } from '../petsitter-service/petsitter-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RequestService } from 'src/app/requests-handling/request-service/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { Loader } from '@googlemaps/js-api-loader';
import html2canvas from 'html2canvas';
import { CaregiverEvaluation, DEFAULT_CAREGIVER_EVALUATION } from 'src/app/shared/models/caregiver-evaluation.model';
import { CaregiverEvaluationRecord } from 'src/app/shared/models/caregiver-evaluation-record.model';
import { EvaluationService } from 'src/app/shared/services/evaluation-service/evaluation.service';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { CaregiverEvaluationComponent } from 'src/app/shared/components/caregiver-evaluation/caregiver-evaluation.component';
import { CaregiverEngagementComponent } from 'src/app/shared/components/caregiver-engagement/caregiver-engagement.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { CvPetsitterTemplateComponent } from '../cv-petsitter-template/cv-petsitter-template.component';
import { DEFAULT_CAREGIVER_ENGAGEMENT } from 'src/app/shared/models/caregiver-engagement.model';

@Component({
  selector: 'app-petsitter-detail',
  templateUrl: './petsitter-detail.component.html',
  styleUrls: ['./petsitter-detail.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, ProvinceFilterComponent, CaregiverEvaluationComponent, CaregiverEngagementComponent,
    AlertComponent, ImageCropperComponent, CvPetsitterTemplateComponent
  ]
})
export class PetsitterDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  isNew = true;
  hasPayed!: boolean;
  addressSelected: boolean = false;
  showAlert = false;
  showModal = false;
  chosenPhoto!: string;
  finalPhoto!: string;
  step = 0;
  form!: UntypedFormGroup;
  petsitter!: Petsitter;
  petsitterID!: string;
  generatePages: boolean = false;
  cvPages: number = 1;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];
  checkNotRated = true;
  private nextTab = -1;
  private goBackRequested = false
  private petsitterState!: PetsitterState;
  private subscriptions: Subscription[] = [];
  private unsubscribe = new Subject<void>();
  private caregiverRating: CaregiverEvaluation | undefined;
  private currentUser: string = '';
  private oldAddress!: string | undefined

    //default option for NEW PET
    defaultRating = DEFAULT_CAREGIVER_EVALUATION
    defaultEngagement = DEFAULT_CAREGIVER_ENGAGEMENT

  constructor(private certificateService: CertificateService, private caregiverEvaluationService: EvaluationService,
    private petsitterService: PetsitterService, private stateManager: RouterStateManagerService,
    private sanitizer: DomSanitizer, private modalService: ModalService, private requestService: RequestService, private router: Router,
    private activeRoute: ActivatedRoute, private datePipe: DatePipe, private userStorage: UserStorageService, private googleMapsService: GoogleMapsService) {
    this.finalPhoto = '';
  }

  ngOnInit(): void {
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.currentUser = user?.name ?? '';
    })
    this.form = new UntypedFormGroup({
      info: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        surname: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        phone: new UntypedFormControl(null, Validators.required),
        address: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        zone: new UntypedFormControl(''),
        cap: new UntypedFormControl('', [Validators.pattern(new RegExp('^\\d{5}$'))]),
        age: new UntypedFormControl(0),
        birthDate: new UntypedFormControl(''),
        provincia: new UntypedFormControl('TO'),
        fiscalcode: new UntypedFormControl('', [Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
        iban: new UntypedFormControl(''),
        resume: new UntypedFormControl(''),
        resumeHidden: new UntypedFormControl(''),
        video: new UntypedFormControl(''),
        notes: new UntypedFormControl(''),
        description: new UntypedFormControl(''),
        photo: new UntypedFormControl(''),
        subscription: new UntypedFormControl(''),
        manualSubscription: new UntypedFormControl(''),
        expDate: new UntypedFormControl(''),
        expDateManual: new UntypedFormControl(''),
        stopWhatsapp: new UntypedFormControl(false),
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null)
      }),
      exp: new UntypedFormGroup({
        total: new UntypedFormControl(0),
        cluster1: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            peeing: new UntypedFormControl(false),
            games: new UntypedFormControl(false),
            social: new UntypedFormControl(false),
            muzzle: new UntypedFormControl(false),
            other: new UntypedFormControl(false),
            game: new UntypedFormControl(''),
            help: new UntypedFormControl('')
          }),

          otherTasks: new UntypedFormArray([])
        }),
        cluster2: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            interactions: new UntypedFormControl(false),
            walking: new UntypedFormControl(false),
            other: new UntypedFormControl(false)
          }),
          otherTasks: new UntypedFormArray([])
        }),
        cluster3: new UntypedFormGroup({
          expYears: new UntypedFormControl(0, [Validators.min(0), Validators.max(50)]),
          employer: new UntypedFormControl(''),
          tasks: new UntypedFormGroup({
            helpers: new UntypedFormControl(false),
            diseases: new UntypedFormControl(false),
            other: new UntypedFormControl(false),
            help: new UntypedFormControl('')
          }),
          otherTasks: new UntypedFormArray([])
        }),
      }),
      lang: new UntypedFormGroup({
        drugs: new UntypedFormControl(false),
        disability: new UntypedFormControl(false),
        mentalIllness: new UntypedFormControl(false),
        title: new UntypedFormControl(''),
        graduationType: new UntypedFormControl(''),
        certificates: new UntypedFormArray([])
      }),
      reference: new UntypedFormGroup({
        f_surname: new UntypedFormControl(''),
        f_second_surname: new UntypedFormControl('')
      }),
      avail: new UntypedFormGroup({
        catHealing: new UntypedFormGroup({
          cat: new UntypedFormControl(false),
        }),
        catHosting: new UntypedFormGroup({
          cat: new UntypedFormControl(false),
        }),
        dogHealing: new UntypedFormGroup({
          dog: new UntypedFormControl(false),
          dog1: new UntypedFormControl(false),
          dog2: new UntypedFormControl(false),
          dog3: new UntypedFormControl(false),
          dog4: new UntypedFormControl(false),
        }),
        dogHosting: new UntypedFormGroup({
          dog: new UntypedFormControl(false),
          dog1: new UntypedFormControl(false),
          dog2: new UntypedFormControl(false),
          dog3: new UntypedFormControl(false),
          dog4: new UntypedFormControl(false),
        }),
        walking: new UntypedFormControl(false),
        pension: new UntypedFormControl(false),
        homeService: new UntypedFormControl(false),
        nightShift: new UntypedFormControl(false),
        hosting: new UntypedFormControl(false),
        grooming: new UntypedFormControl(false),
        veterinarian: new UntypedFormControl(false),
        cleaning: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        license: new UntypedFormControl(false),
        car: new UntypedFormControl(false),
        days: new UntypedFormGroup({
          sun: new UntypedFormControl(false),
          mon: new UntypedFormControl(false),
          tue: new UntypedFormControl(false),
          wed: new UntypedFormControl(false),
          thu: new UntypedFormControl(false),
          fri: new UntypedFormControl(false),
          sat: new UntypedFormControl(false),
        }),
        hours: new UntypedFormGroup({
          mor: new UntypedFormControl(false),
          aft: new UntypedFormControl(false),
          eve: new UntypedFormControl(false),
        }),
        contract: new UntypedFormGroup({
          occ: new UntypedFormControl(false),
          part: new UntypedFormControl(false),
          full: new UntypedFormControl(false),
        })
      })
    });
    this.activeRoute.params.subscribe(param => {
      this.petsitterID = param.id;
    })
    /* this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.setPetsitter(this.processFormData(value));
    }); */
    try {
      this.stateManager.getPetsitterState().pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
        this.petsitterState = state;
        this.petsitterState.id = this.petsitterID;
        this.petsitterState.route = 'petsitter-detail/' + this.petsitterID;
        this.oldAddress = state.petsitter?.info.address //GET OLD ADDRESS FROM BE (TO COMPARE WITH ADDRESS ON FORM)
        if (this.petsitterState.step >= 0) {
          this.step = this.petsitterState.step;
        }
        if (this.petsitterState.petsitter) {
          this.isNew = false;
          this.setPetsitter(this.petsitterState.petsitter);
          this.formInitialize(this.petsitterState.petsitter);
        } else if (!this.petsitterID.match('null')) {
          const id = this.petsitterID;
          if (id && id !== 'new') {
            this.isNew = false;
            this.petsitterService.getSinglePetsitter(id).pipe(takeUntil(this.unsubscribe)).subscribe((petsitters) => {
              let petsitter = petsitters[0];
              if (petsitter) {
                this.setPetsitter(petsitter);
                this.formInitialize(this.petsitter);
              }
            });
          }
        }
      });
    } catch (err) {
      this.modalService.showErrorMessage('Errore nel recupero delle informazioni legate alla petsitter.');
    }

    this.form.get('lang.title')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (value === 'Laurea') {
        this.form.get('lang.graduationType')?.enable({ emitEvent: false });
      } else {
        this.form.get('lang.graduationType')?.disable({ emitEvent: false });
      }
    });

    /* Reset exp clusters when value is 0 */

    this.form.get('exp.cluster1.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster1')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(1, 'otherTasks');
        this.form.get('exp.cluster1.expYears')?.setValue(0, { emitEvent: false });
      }
    });
    this.form.get('exp.cluster2.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster2')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(2, 'otherTasks');
        this.form.get('exp.cluster2.expYears')?.setValue(0, { emitEvent: false });
      }
    });
    this.form.get('exp.cluster3.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.form.get('exp.cluster3')?.reset(undefined, { emitEvent: false });
        this.resetFormArray(3, 'otherTasks');
        this.form.get('exp.cluster3.expYears')?.setValue(0, { emitEvent: false });
      }
    });

    /* FormArrays handling depending on relative checkbox status */

    this.form.get('exp.cluster1.tasks.6')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(1, 'otherTasks');
      }
    });
    this.form.get('exp.cluster2.tasks.7')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(2, 'otherTasks');
      }
    });
    this.form.get('exp.cluster3.tasks.7')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      if (!value) {
        this.resetFormArray(3, 'otherTasks');
      }
    });

    //Listen to every cluster's expYears value changes and then compute the total exp according to the formula

    this.form.get('exp.cluster1.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });

    this.form.get('exp.cluster2.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });

    this.form.get('exp.cluster3.expYears')?.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(value => {
      let exp = this.form.get('exp')?.value;
      let expSum = exp.cluster1.expYears + exp.cluster2.expYears + exp.cluster3.expYears;
      let age = this.form.get('info.age')?.value;
      this.form.get('exp.total')?.setValue(Math.max(exp.cluster1.expYears, exp.cluster2.expYears, exp.cluster3.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    });
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;
    if (inputAddress) {
      inputAddress.addEventListener('change', () => {
        inputAddress.value = '';
        this.form.get('info.address')?.setValue('', { emitEvent: false });
      });
      const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {

        const autocomplete = new google.maps.places.Autocomplete(inputAddress);

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          let formAddress = this.form.get('info.address');

          formAddress?.setValue(place.formatted_address);

          // Compile other address fields according to place object
          place.address_components?.forEach((component: any) => {
            if (component.types.includes('locality')) {
              this.form.get('info.city')?.setValue(component.long_name);
            }
            if (component.types.includes('postal_code')) {
              this.form.get('info.cap')?.setValue(component.long_name);
            }
          });
        })
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stateManager.setPetsitterState(this.petsitterState, false);
    this.processFormData(this.form.value).then((res) => {
      this.setPetsitter(res);
    })
  }

  uploadFile(_event: any, field: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento CV in corso...');
      const file = <File>_event.target.files[0];
      const path = field + '/' + field + this.form.get('info.email')?.value + '.pdf';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (field === 'info.resume') {
            this.petsitter.info.resume = downloadURL;
          } else {
            this.petsitter.info.resumeHidden = downloadURL;
          }
          this.form.get(field)?.setValue(downloadURL);
          if (this.isNew) {
            this.createPetsitter();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updatePetsitter();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel caricamento del CV. Si prega di riprovare');
    }
  }

  uploadImage(image: string, id: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento foto in corso...');
      const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
      const path = 'pictures/' + id + '.png';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          this.form.get('info.photo')?.setValue(downloadURL);
          let body = this.petsitter.info;
          body.photo = downloadURL;
          this.petsitter.info = body;
          if (this.isNew) {
            this.createPetsitter();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updatePetsitter();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel corso del caricamento della foto. Si prega di riprovare.');
    }
    this.showModal = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async generateCV(showAlert: boolean) {
    if (this.form.invalid) {
      return
    }
    if (showAlert) {
      this.modalService.showLoadingWithMessage('Generazione CV Family Happy in corso...');
    }
    try {
      this.generatePages = true;
      setTimeout(() => {
        this.htmlToCanvas(showAlert)
      }, 1000);
    } catch (err: any) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nella generazione del CV. Potrebbe essere legato alla foto caricata. Si prega di scaricarla, caricarla nuovamente e riprovare.');
    }
  }

  goBack() {
    if (this.form.dirty) {
      this.showAlert = true;
      this.goBackRequested = true;
    } else {
      this.petsitterState.route = 'petsitter-list';
      this.petsitterState.petsitter = undefined;
      this.petsitterState.step = 0;
      this.petsitterState.id = 'null';
      this.ngOnDestroy();
      this.stateManager.setPetsitterState(this.petsitterState);
    }
  }

  formInitialize(petsitter: Petsitter) {
    let expSum = petsitter.exp.cluster1.cluster1Age + petsitter.exp.cluster2.cluster2Age + petsitter.exp.cluster3.cluster3Age;
    let age = petsitter.info.age;

    this.resetAllFormArrays();
    if (petsitter) {
      this.form.setValue({
        info: {
          ...petsitter.info,
          expDate: petsitter.info.expDate ? this.datePipe.transform(petsitter.info.expDate, 'yyyy-MM-dd') : '',
          expDateManual: petsitter.info.expDateManual ? this.datePipe.transform(petsitter.info.expDateManual, 'yyyy-MM-dd') : ''
        },
        exp: {
          total: petsitter.exp.total,
          cluster1: {
            expYears: petsitter.exp.cluster1.cluster1Age,
            employer: petsitter.exp.cluster1.employer1,
            tasks:
            {
              ...this.petsitterService.fromStringToAvailObject(petsitter.exp.cluster1.tasks1, 'tasks1'),
              game: petsitter.exp.cluster1.games ?? '',
              help: petsitter.exp.cluster1.help1 ?? '',
            },
            otherTasks: ['']
          },
          cluster2: {
            expYears: petsitter.exp.cluster2.cluster2Age,
            employer: petsitter.exp.cluster2.employer2,
            tasks: this.petsitterService.fromStringToAvailObject(petsitter.exp.cluster2.tasks2, 'tasks2'),
            otherTasks: ['']
          },
          cluster3: {
            expYears: petsitter.exp.cluster3.cluster3Age,
            employer: petsitter.exp.cluster3.employer3,
            tasks: {
              ...this.petsitterService.fromStringToAvailObject(petsitter.exp.cluster3.tasks3, 'tasks3'),
              help: petsitter.exp.cluster3.help2 ?? '',
            },
            otherTasks: ['']
          },
        },
        lang: {
          ...petsitter.lang,
          certificates: ['']
        },
        reference: {
          f_surname: petsitter.reference ? petsitter.reference.f_surname : '',
          f_second_surname: petsitter.reference ? petsitter.reference.f_second_surname : ''
        },
        avail: {
          ...petsitter.avail,
          catHealing: this.petsitterService.fromStringToAvailObject(petsitter.avail.catHealing, 'catHealing'),
          catHosting: this.petsitterService.fromStringToAvailObject(petsitter.avail.catHosting, 'catHosting'),
          dogHealing: this.petsitterService.fromStringToAvailObject(petsitter.avail.dogHealing, 'dogHealing'),
          dogHosting: this.petsitterService.fromStringToAvailObject(petsitter.avail.dogHosting, 'dogHosting'),
          days: this.petsitterService.fromStringToAvailObject(petsitter.avail.days, 'days'),
          hours: this.petsitterService.fromStringToAvailObject(petsitter.avail.hours, 'hours'),
          contract: this.petsitterService.fromStringToAvailObject(petsitter.avail.contract, 'contract')
        },
      }, { emitEvent: false });

      this.setCertificatesLenght(petsitter.certificates.petsitterCertificateList);
    }

    if (this.form.get('rating.notRated')?.value === true && this.checkNotRated) {
      this.form.get('rating.overall')?.valueChanges.subscribe((overall) => {
        if (overall > 0) {
          this.form.get('rating.notRated')?.setValue(false);
        } else {
          this.form.get('rating.notRated')?.setValue(true);
        }
      })
      this.checkNotRated = false;
    } else {
      this.checkNotRated = false;
    }
  }

  save(showMessage?: boolean) {
    if (this.form.valid) {
      if (this.isNew) {
        this.createPetsitter();
      } else {
        this.updatePetsitter(showMessage);
      }
    }
  }

  changeTab(tab: number) {
    if (this.form.dirty) {
      this.showAlert = true;
      this.nextTab = tab;
    } else {
      this.step = tab;
      this.petsitterState.step = this.step;
    }
  }

  onAlertAction(save: boolean) {
    if (save && !this.isNew) {
      this.updatePetsitter();
    } else if (save && this.isNew) {
      this.createPetsitter();
    } else if (!save && this.goBackRequested) {
      if (this.petsitter === undefined) {
        this.form.markAsPristine();
        this.form.reset();
        this.goBack();
      } else {
        this.form.markAsPristine();
        this.form.reset();
        this.formInitialize(this.petsitter);
        this.goBack();
      }
    } else {
      this.form.markAsPristine();
      this.form.reset();
      this.formInitialize(this.petsitter);
      // this.goBack();
    }
    this.showAlert = false;
    if (this.nextTab !== -1) {
      this.step = this.nextTab;
      this.petsitterState.step = this.step;
      this.nextTab = -1;
    }
  }

  private async createPetsitter() {
    this.petsitter = await this.processFormData(this.form.value);
    this.petsitterService.createPetsitter(this.petsitter).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: any) => {
        this.modalService.showSuccessMessage('Nuova petsitter creata.');
        const regex = /#(\w{2}-\d{5})/gm;
        const result = regex.exec(res.message);
        if (result) {
          this.petsitterService.getSinglePetsitter(result[1]).pipe(takeUntil(this.unsubscribe)).subscribe((petsitters) => {
            let petsitter = petsitters[0];
            if (petsitter) {
              this.isNew = !this.isNew;
              this.petsitter = petsitter;
              this.form.markAsPristine();
              this.formInitialize(this.petsitter);
            }
          });
        }
      },
      error: (error) => {
        if ((error.error as string).match('Key .* already exists.')) {
          this.modalService.showErrorMessage('Esiste già un profilo associato all\'indirizzo email fornito.');
        } else {
          this.modalService.showErrorMessage('Si è verificato un errore nella creazione della petsitter, si prega di riprovare.');
        }
      }
    });
  }

  private async updatePetsitter(showMessage?: boolean) {
    this.petsitter = await this.processFormData(this.form.value);
    this.petsitterService.updatePetsitter(this.petsitter).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        if (showMessage) {
          this.modalService.showSuccessMessage('Petsitter modificata.');
        }
        this.publishNewCaregiverScoreUpdate();
        this.form.markAsPristine();
        this.formInitialize(this.petsitter);
        this.generateCV(false);
      },
      error: (error) => {
        console.log(error);
        this.modalService.showErrorMessage(`Si è verificato un errore nell'aggiornamento della petsitter.`);
      }
    });
  }

  private async processFormData(data: any) {
    let petsitter: Petsitter = {
      docRef: this.petsitter?.docRef ?? '',
      certificate: this.petsitter?.certificate ?? '',
      info: {
        ...data.info,
        age: +data.info.age > 0 ? +data.info.age : 1,
        expDate: data.info.expDate !== '' ? new Date(data.info.expDate) : null,
        expDateManual: data.info.expDateManual !== '' ? new Date(data.info.expDateManual) : null,
        stopWhatsapp: data.info.stopWhatsapp ?? false,
        latitude: null,
        longitude: null
      },
      exp: {
        total: data.exp.total,
        cluster1: {
          cluster1Age: data.exp.cluster1.expYears ?? 0,
          employer1: data.exp.cluster1.employer,
          tasks1: this.petsitterService.fromAvailObjectToString(data.exp.cluster1.tasks, 'tasks1'),
          games: data.exp.cluster1.tasks.game,
          help1: data.exp.cluster1.tasks.help
        },
        cluster2: {
          cluster2Age: data.exp.cluster2.expYears ?? 0,
          employer2: data.exp.cluster2.employer,
          tasks2: this.petsitterService.fromAvailObjectToString(data.exp.cluster2.tasks, 'tasks2')
        },
        cluster3: {
          cluster3Age: data.exp.cluster3.expYears ?? 0,
          employer3: data.exp.cluster3.employer,
          tasks3: this.petsitterService.fromAvailObjectToString(data.exp.cluster3.tasks, 'tasks3'),
          help2: data.exp.cluster3.tasks.help
        }
      },
      reference: {
        f_surname: data.reference.f_surname,
        f_second_surname: data.reference.f_second_surname
      },
      lang: {
        drugs: data.lang.drugs,
        disability: data.lang.disability,
        mentalIllness: data.lang.mentalIllness,
        title: data.lang.title,
        graduationType: data.lang.graduationType ?? '',
      },
      avail: {
        ...data.avail,
        catHealing: this.petsitterService.fromAvailObjectToString(data.avail.catHealing, 'catHealing'),
        catHosting: this.petsitterService.fromAvailObjectToString(data.avail.catHosting, 'catHosting'),
        dogHealing: this.petsitterService.fromAvailObjectToString(data.avail.dogHealing, 'dogHealing'),
        dogHosting: this.petsitterService.fromAvailObjectToString(data.avail.dogHosting, 'dogHosting'),
        days: this.petsitterService.fromAvailObjectToString(data.avail.days, 'days'),
        hours: this.petsitterService.fromAvailObjectToString(data.avail.hours, 'hours'),
        contract: this.petsitterService.fromAvailObjectToString(data.avail.contract, 'contract')
      },
      rating: {
        ...this.caregiverRating ?? this.petsitter?.rating ??
        {
          overall: 0,
          punctuality: 0,
          empathy: 0,
          behave: 0,
          communication: 0,
          attitude: '',
          dangerous: false,
          notRated: false
        }
      },
      engagement: {
        ...this.petsitter?.engagement ?? {
          selected: 0,
          chosen: 0,
          discarded: 0,
          ignored: 0,
          substituted: 0,
          applications: 0,
          chosenDate: undefined,
          latestApplication: undefined
        }
      },
      certificates: {
        petsitterCertificateList: data.lang.certificates
      },
      updateTimestamp: this.petsitter ? this.petsitter.updateTimestamp : new Date()
    };

    //calculate coordinates from address
    if (data.info.address === '') {
      let coordinates = {
        latitude: data.info.latitude,
        longitude: data.info.longitude,
      }
      petsitter = {
        ...petsitter,
        info: {
          ...petsitter.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }
    } else {
      if (this.oldAddress !== data.info.address) {
        let coordinates = await this.getCoordiantes(petsitter)
        petsitter = {
          ...petsitter,
          info: {
            ...petsitter.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }

      } else {
        let coordinates = {
          latitude: data.info.latitude,
          longitude: data.info.longitude,
        }
        petsitter = {
          ...petsitter,
          info: {
            ...petsitter.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }
      }
    }
    // if (this.oldAddress !== data.info.address) {
    //   let coordinates = await this.getCoordiantes(petsitter)
    //   petsitter = {
    //     ...petsitter,
    //     info: {
    //       ...petsitter.info,
    //       latitude: coordinates.latitude,
    //       longitude: coordinates.longitude
    //     }
    //   }

    // } else {
    //   let coordinates = {
    //     latitude: data.info.latitude,
    //     longitude: data.info.longitude,
    //   }
    //   petsitter = {
    //     ...petsitter,
    //     info: {
    //       ...petsitter.info,
    //       latitude: coordinates.latitude,
    //       longitude: coordinates.longitude
    //     }
    //   }
    // }

    return petsitter;
  }

  private setPetsitter(bb: Petsitter) {
    this.petsitter = bb;
    this.petsitterState.petsitter = this.petsitter;
    this.petsitterState.step = this.step;
  }

  openModal(event: any) {
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  dismiss(event: Event) {
    const elem = event.target as HTMLElement;
    if (elem === document.getElementById('modal-container')) {
      this.showModal = false;
    }
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
  }

  /* Experience form arrays methods */

  changeFormArrayLength(more: boolean, cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  resetFormArray(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    array.clear();
  }

  resetAllFormArrays() {
    this.resetFormArray(1, 'otherTasks');
    this.resetFormArray(2, 'otherTasks');
    this.resetFormArray(3, 'otherTasks');
    this.resetCertificates();
    this.changeFormArrayLength(true, 1, 'otherTasks');
    this.changeFormArrayLength(true, 2, 'otherTasks');
    this.changeFormArrayLength(true, 3, 'otherTasks');
    this.changeCertificatesLength(true);
  }

  getFormArrayControls(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    return array.controls;
  }


  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetCertificates() {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    return array.controls;
  }

  htmlToCanvas(showAlert: boolean) {
    console.log('Check pages:', this.currentPage, this.cvPages)
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;
      return;
    }
    const element = document.getElementById('cv-page-container') as HTMLElement;

    html2canvas(element, { allowTaint: true, useCORS: true, scale: 2, }).then(canvas => {
      this.canvases.push(canvas);
      if (this.currentPage === this.cvPages - 1) {
        this.certificateService.createPetsittePDFCVFromHTML(this.canvases, this.petsitter, this.isNew, showAlert,
          this.stateManager, environment.petsitterServiceURL, environment.requestServiceURL);
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas(showAlert) }, 1000);
    })
  }

  cvPreview() {
    if (!this.generatePages) {
      this.router.navigate(['petsitter-detail', this.petsitter.docRef, 'cv'], { state: { data: this.petsitter } });
    }
  }

  openCertificatePage() {
    /* const config: FillCertificateConfig = {
      petsitter: this.babySitter,
      stateManager: this.stateManager,
      isProduction: environment.production,
      mailServiceURL: environment.mailServiceURL,
      babysitterApiURL: environment.babysitterServiceURL,
      showModal: true
    }
    this.certificateService.setConfig(config);
    this.router.navigate(['petsitter-detail', this.babySitter.docRef, 'fill-certificate']); */
  }

  computeTotalExp() {
    let value = this.form.get('exp')?.value;
    let expSum = value.cluster1.expYears + value.cluster2.expYears + value.cluster3.expYears;
    let age = this.form.get('info.age')?.value;
    this.form.get('exp.total')?.setValue(Math.max(value.cluster1.expYears, value.cluster2.expYears, value.cluster3.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
  }

  onCaregiverEvaluationChanged(newCaregiverEvaluation: CaregiverEvaluation) {
    this.caregiverRating = newCaregiverEvaluation;
    this.form.markAsTouched();
    this.form.markAsDirty();
  }

  updateProvincia(newValue: string) {
    this.form.get('info.provincia')?.setValue(newValue);
    this.form.markAllAsTouched();
    this.form.get('info.provincia')?.markAsDirty();
  }

  private publishNewCaregiverScoreUpdate() {
    if (this.caregiverRating) {
      // Insert code to save new caregiver evaluation in the database
      const record: CaregiverEvaluationRecord = {
        ...this.caregiverRating,
        id: null,
        docRef: this.petsitter.docRef,
        operator: this.currentUser,
        timestamp: new Date()
      }
      this.caregiverEvaluationService.addRecord(record).subscribe((response: CaregiverEvaluationRecord) => console.log(record))
      this.caregiverRating = undefined;
    }
  }

  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Petsitter) {

    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
