<div class="fh-form-section" [formGroup]="form">
  <fieldset>
    <legend>Caratteristiche bambini</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="numerobambini">Numero bambini</label>
        <div style="display: grid; grid-template-columns: 50px 1fr; gap: 5px">
          <select style="min-width: unset;" id="numerobambini" formControlName="numberOfChildren">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <select style="min-width: 100%;" formControlName="childrenGender">
            <option>bambino</option>
            <option>bambina</option>
            <option>bambini</option>
            <option>bambine</option>
          </select>
        </div>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="etabambini">Età bambini</label>
        <div style="display: grid; grid-template-columns: 1fr 90px; gap: 5px">
          <input style="min-width: 100%;" type="text" id="etabambini" formControlName="ageOfChildren">
          <select style="min-width: 100%" formControlName="ageOfChildrenText">
            <option></option>
            <option>mesi</option>
            <option>anno</option>
            <option>anni</option>
          </select>
        </div>
      </div>
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requestedExp">
        <label class="fw-semibold">Esperienza babysitter richiesta</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp1">0-6 mesi</label>
          <input type="checkbox" id="reqexp1" formControlName="requestedExpCluster1">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp2">7-24 mesi</label>
          <input type="checkbox" id="reqexp2" formControlName="requestedExpCluster2">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp3">2-5 anni</label>
          <input type="checkbox" id="reqexp3" formControlName="requestedExpCluster3">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp4">&gt;5 anni</label>
          <input type="checkbox" id="reqexp4" formControlName="requestedExpCluster4">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="reqexp5">Aiuto compiti</label>
          <input type="checkbox" id="reqexp5" formControlName="requestedExpHomework">
        </div>
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>Caratteristiche babysitter richiesta</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="category">
        <label class="fw-semibold" for="category">Categoria</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="babysitter">Babysitter</label>
          <input type="checkbox" id="babysitter" formControlName="babysitter">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="cleaning">Aiuto pulizie</label>
          <input type="checkbox" id="cleaning" formControlName="cleaning">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="cooking">Preparazione pasti</label>
          <input type="checkbox" id="cooking" formControlName="cooking">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="petsitter">Pet Sitter</label>
          <input type="checkbox" id="petsitter" formControlName="petsitter">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="taxi">Taxisitter Automunita</label>
          <input type="checkbox" id="taxi" formControlName="taxi">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="taxiNoCar">Taxisitter con auto Fornita dai Genitori</label>
          <input type="checkbox" id="taxiNoCar" formControlName="taxiNoCar">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="walking">Taxisitter (a piedi)</label>
          <input type="checkbox" id="walking" formControlName="walking">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="car">Automunita</label>
          <input type="checkbox" id="car" formControlName="car">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="greenpass">Green pass</label>
          <input type="checkbox" id="greenpass" formControlName="greenpass">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="smoker">Non fumatrice</label>
          <input type="checkbox" id="smoker" formControlName="smoker">
        </div>
      </div>
      <div>
        <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requestedAge">
          <label class="fw-semibold">Età babysitter</label>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="reqage1">18-35 anni</label>
            <input type="checkbox" id="reqage1" formControlName="requestedAgeCluster1">
          </div>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="reqage2">36-50 anni</label>
            <input type="checkbox" id="reqage2" formControlName="requestedAgeCluster2">
          </div>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="reqage3">51+ anni</label>
            <input type="checkbox" id="reqage3" formControlName="requestedAgeCluster3">
          </div>
        </div>
        <div style="height: 42px"></div>
        <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="requiredLanguage">
          <label class="fw-semibold">Lingue richieste</label>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="italian">Italiano</label>
            <input type="checkbox" id="italian" formControlName="italian">
          </div>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="english">Inglese</label>
            <input type="checkbox" id="english" formControlName="english">
          </div>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="french">Francese</label>
            <input type="checkbox" id="french" formControlName="french">
          </div>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="german">Tedesco</label>
            <input type="checkbox" id="german" formControlName="german">
          </div>
          <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
            <label for="spanish">Spagnolo</label>
            <input type="checkbox" id="spanish" formControlName="spanish">
          </div>
        </div>
      </div>
      <label class="fw-semibold" style="grid-column: 1/3;">Altre</label>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="label" for="coliving">Disp. Convivenza</label>
        <input type="checkbox" id="coliving" formControlName="coliving">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="label" for="relocation">Disp. Trasferte</label>
        <input type="checkbox" id="relocation" formControlName="relocation">
      </div>
    </div>
  </fieldset>
  <fieldset>
    <legend>Caratteristiche incarico</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="iniziostimato">Inizio stimato</label>
        <input type="date" id="iniziostimato" formControlName="starting">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="durataimpegno">I genitori avrebbero bisogno fino a</label>
        <input type="date" id="durataimpegno" formControlName="estimatedLasting">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="serviceStart">Da quando</label>
        <input type="text" id="serviceStart" formControlName="serviceStart" readonly>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="serviceDuration">Per quanto tempo</label>
        <input type="text" id="serviceDuration" formControlName="serviceDuration" readonly>
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="continuousJob">Lavoro continuativo</label>
        <input type="checkbox" id="continuousJob" formControlName="continuousJob">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="moreThanOne">Numero di bambini maggiore di 1</label>
        <input type="checkbox" id="moreThanOne" formControlName="moreThanOne">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="handicap">Bambini portatori di handicap/autismo o con esigenze speciali</label>
        <input type="checkbox" id="handicap" formControlName="handicap">
      </div>
      <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
        <label class="fw-semibold" for="badWorkHours">Orari scomodi - <span style="font-size: 13px; color: grey;">A
            chiamata,
            Periodo breve inferiore al mese, tra le 20 e le 7.30; solo sabato o Domenica; meno o uguale 3 ore/ al
            giorno</span></label>
        <input type="checkbox" id="badWorkHours" formControlName="badWorkHours">
      </div>
      <div class="fh-form-input-field" style="grid-column: span 2;">
        <div style="grid-column: span 2;">
          <label class="fw-semibold" for="descrizioneimpegno" style="display: block;">
            Descrizione impegno -
            <span style="font-size: 13px; color: grey;">"Serve una babysitter per..."</span>
          </label>
          <textarea rows="6" type="text" id="descrizioneimpegno" maxlength="2048"
            formControlName="requestDescription" style="width: 100%;"></textarea>
        </div>
      </div>
    </div>
  </fieldset>
  @if (inputData !== undefined) {
    <app-request-pricing [province]="provincia" [badWorkHoursInput]="form.get('badWorkHours')!.value" [handicapInput]="form.get('handicap')!.value" [moreThanOneInput]="form.get('moreThanOne')!.value"
    [request]="inputData" [reqService]="reqService" (pricing)="updatePricing($event)"></app-request-pricing>
  }
  <fieldset>
    <legend>Orari di lavoro</legend>
    <div class="fh-form-grid" style="justify-items: normal">
      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="days">
        <label class="fw-semibold">Giorni lavorativi</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="mon">Lunedì</label>
          <input type="checkbox" id="mon" formControlName="mon">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="tue">Martedì</label>
          <input type="checkbox" id="tue" formControlName="tue">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="wed">Mercoledì</label>
          <input type="checkbox" id="wed" formControlName="wed">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="thu">Giovedì</label>
          <input type="checkbox" id="thu" formControlName="thu">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="fri">Venerdì</label>
          <input type="checkbox" id="fri" formControlName="fri">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="sat">Sabato</label>
          <input type="checkbox" id="sat" formControlName="sat">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="sun">Domenica</label>
          <input type="checkbox" id="sun" formControlName="sun">
        </div>
      </div>

      <div style="display: flex; flex-direction: column; gap: 10px;" formGroupName="hours">
        <label class="fw-semibold">Fascia oraria</label>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="mor">Mattino</label>
          <input type="checkbox" id="mor" formControlName="mor">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="aft">Pomeriggio</label>
          <input type="checkbox" id="aft" formControlName="aft">
        </div>
        <div class="fh-form-input-field" style="grid-template-columns: 1fr 20px;">
          <label for="eve">Sera</label>
          <input type="checkbox" id="eve" formControlName="eve">
        </div>
      </div>
    </div>
  </fieldset>

  <fieldset>
    <legend>Animali domestici</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="animals">Animali in casa</label>
        <input type="checkbox" id="animals" formControlName="animals">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="animalsType">Tipo di animale</label>
        <input type="text" id="animalsType" formControlName="animalsType">
      </div>
    </div>
  </fieldset>
</div>
