import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCollapseModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FHService, ModalService, NpxFamilyHappyCommonModule, PaymentRow, ProvinceFilterComponent } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { DEFAULT_PAYMENT_FILTERS, PaymentService } from 'src/app/backoffice/payments/payment-service/payment.service';
import { FHServiceSelectComponent } from 'src/app/shared/components/custom-select/fh-service-select/fh-service-select.component';
import { OperatorFilterComponent } from 'src/app/shared/components/custom-select/operator-filter/operator-filter.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { PaymentStatusBadgeComponent } from 'src/app/shared/components/payment-status-badge/payment-status-badge.component';
import { ProvinceSelectComponent } from 'src/app/shared/components/province-select/province-select.component';
import { Sell } from 'src/app/shared/models/sell.model';

@Component({
  selector: 'app-seller-dashboard',
  templateUrl: './seller-dashboard.component.html',
  styleUrls: ['./seller-dashboard.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, NgbCollapseModule, FHServiceSelectComponent, ProvinceSelectComponent, ProvinceFilterComponent,
    OperatorFilterComponent, PaymentStatusBadgeComponent, NgbPaginationModule, AlertComponent
  ]
})
export class SellerDashboardComponent implements OnInit, OnDestroy {
  filters = new UntypedFormGroup({
    dateFrom: new UntypedFormControl(null),
    dateTo: new UntypedFormControl(null),
    product: new UntypedFormControl(null),
    email: new UntypedFormControl(null),
    province: new UntypedFormControl(null),
    invoiced: new UntypedFormControl(null),
    amount: new UntypedFormControl(null),
    seller: new UntypedFormControl(null),
    status: new UntypedFormControl(null),
    serviceID: new UntypedFormControl(null)
  })
  sells: Sell[] = [];
  page = 1;
  pageSize = 10;
  collectionSize = 0;

  showDeleteConfirm = false;
  isCollapsed = true;
  selectedPaymentID = -1;
  resetServiceValue = false
  serviceID!: number | null

  operator: string = '';
  unsubscribe = new Subject<void>();
  origin = `${location.protocol}//${location.host}`;

  constructor(
    private paymentService: PaymentService,
    private router: Router,
    private modalService: ModalService,
    private userStorage: UserStorageService
  ) {
    this.updateFilters()
   }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.operator = user?.name ?? '';
    });
    this.loadPayments();
  }

  onPageChange() {
    if (this.page > 0) {
      this.loadPayments();
    }
  }

  openPaymentDetail(id: number) {
    this.router.navigate(['/payments/' + id]);
  }

  claimPayment(id: number) {
    this.selectedPaymentID = id;
    this.showDeleteConfirm = true;
  }

  confirmClaim(answer: boolean) {
    if (answer && this.selectedPaymentID > -1) {
      this.paymentService.claimPaymentById(this.selectedPaymentID, this.operator).subscribe(() => {
        this.modalService.showSuccessMessage('Venditore assegnato!');
        this.loadPayments();
      });
    }
    this.showDeleteConfirm = false;
  }

  applyFilters() {
    const filtersValue = this.filters.value;
    this.page = 1; // Reset page on filters change
    const formattedFilters = {
      ...filtersValue,
      dateFrom: filtersValue.dateFrom === null ? null : new Date(filtersValue.dateFrom).toISOString(),
      dateTo: filtersValue.dateTo === null ? null : new Date(filtersValue.dateTo).toISOString(),
    };
    this.paymentService.setFilters(formattedFilters)
    this.loadPayments();
  }

  resetFilters(){
    this.paymentService.setFilters(DEFAULT_PAYMENT_FILTERS)
    this.updateFHService(null)
    this.loadPayments();
  }

  updateFilters(){
    this.paymentService.getFilters().subscribe((filters) => {
      this.serviceID = filters.serviceID;
      this.filters.patchValue({
        ...filters,
        dateFrom: filters.dateFrom ? filters.dateFrom.split('.')[0] : null,
        dateTo: filters.dateTo ? filters.dateTo.split('.')[0] : null,
      })
    })
  }

  updateProvincia(newValue: string) {
    this.filters.get('province')?.setValue(newValue);
    this.filters.markAllAsTouched();
    this.filters.get('province')?.markAsDirty();
  }

  updateSeller(newValue: string) {
    this.filters.get('seller')?.setValue(newValue);
    this.filters.markAllAsTouched();
    this.filters.get('seller')?.markAsDirty();
  }

  updateFHService(service: FHService | null) {
    if(service === null){
      this.resetServiceValue = true
    }
    this.filters.get('serviceID')?.setValue(service?.id);
    this.filters.markAllAsTouched();
    this.filters.get('serviceID')?.markAsDirty();
  }

  private loadPayments() {
    this.paymentService.getAllSells(this.page - 1, this.pageSize, this.filters.value).subscribe((sellPage) => {
      this.sells = sellPage.content;
      this.page = sellPage.number + 1;
      this.pageSize = sellPage.size;
      this.collectionSize = sellPage.totalElements;
      this.resetServiceValue = false
    })
  }

  copyToClipboard(event:any) {
    navigator.clipboard.writeText(event.target.innerHTML);
    this.modalService.showSuccessMessage('Numero di telefono copiato con successo! Ora puoi incollarlo dove vuoi.');
  }
}
