import { ExtractTimePipe } from './../../../shared/pipes/extract-time.pipe';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadBalanceService } from '../load-balance-service/load-balance.service';
import { LoadBalanceRecordRow } from 'src/app/shared/models/request-loadBalance-record.model';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-load-balance-dashboard',
  templateUrl: './load-balance-dashboard.component.html',
  styleUrls: ['./load-balance-dashboard.component.scss'],
  standalone:true,
  imports: [CommonModule, ReactiveFormsModule, NgbTooltipModule, ExtractTimePipe]
})
export class LoadBalanceDashboardComponent implements OnInit, OnDestroy {

  loadBalanceRecords:LoadBalanceRecordRow[]= []
  operatorsForm: FormArray;
  private unsubscribe = new Subject<void>()

  constructor(private loadBalanceService:LoadBalanceService, private fb: FormBuilder) {

    this.operatorsForm = this.fb.array([])

  }

  ngOnInit(): void {
    this.getUsers()
  }

  getUsers(){
    this.loadBalanceService.getLoadBalance().pipe(takeUntil(this.unsubscribe)).subscribe((loadbalance) => {
      this.loadBalanceRecords = loadbalance.content.map((item) => ({...item, selected:false}))
      this.initForm()
    })
  }

  initForm(){
    this.loadBalanceRecords.forEach((record) => {

      record.isExpired = record.factorExpiry ? this.expiringDateCHeck(record.factorExpiry) : false;

      const group = this.fb.group({
        onVacation: new FormControl(record.onVacation),
        weightBB: new FormControl(record.businessLineWeights.BABYSITTER),
        weightPET: new FormControl(record.businessLineWeights.PETSITTER),
        weightNURSE: new FormControl(record.businessLineWeights.NURSE),
        weightCOLF: new FormControl(record.businessLineWeights.COLF),
        boost: new FormControl(record.factorExpiry)
      })
      this.operatorsForm.push(group)
    })
  }

  getOperatorFormGroup(index: number): FormGroup {
    return this.operatorsForm.at(index) as FormGroup;
  }

  updateOperatorVacation(index: number): void {
    const record = this.loadBalanceRecords[index];
    const formGroup = this.operatorsForm.at(index) as FormGroup;

    const updatedData = {
      ...record,
      onVacation: formGroup.get('onVacation')?.value,
    };
    this.loadBalanceService.updateVacation(updatedData).pipe(takeUntil(this.unsubscribe)).subscribe()
  }

  //UPDATE BUSINESSLINE WEIGHT
  updateOperatorWeight(index: number, businessLine:string): void {
    const record = this.loadBalanceRecords[index];
    const formGroup = this.operatorsForm.at(index) as FormGroup;

    const updatedData = {
      ...record,
      businessLineWeights:{
        BABYSITTER:formGroup.get('weightBB')?.value,
        PETSITTER:formGroup.get('weightPET')?.value,
        NURSE:formGroup.get('weightNURSE')?.value,
        COLF:formGroup.get('weightCOLF')?.value,
      }
    };
    this.loadBalanceService.updateWeight(updatedData, businessLine).pipe(takeUntil(this.unsubscribe)).subscribe((res) => console.log(res, "response"))
  }

  expiringDateCHeck(factorExpiry: Date): boolean{

    const expiryDate = new Date(factorExpiry);
    const now = new Date();
    const offset = expiryDate.getTimezoneOffset() * (-60000); // Offset in millisecondi
    const adjustedExpiryDate = new Date(expiryDate.getTime() + offset);
    return adjustedExpiryDate.getTime() > now.getTime();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
