export interface CaregiverEngagement {
  selected: number;
  chosen: number;
  discarded: number;
  ignored: number;
  substituted: number;
  applications: number;
  chosenDate?: Date | null;
  latestApplication?: Date | null;
}

export const DEFAULT_CAREGIVER_ENGAGEMENT:CaregiverEngagement = {
  selected: 0,
  chosen: 0,
  discarded: 0,
  ignored: 0,
  substituted: 0,
  applications: 0,
  chosenDate: null,
  latestApplication: null
}
