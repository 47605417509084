<div class="container p-3">
  <div>
    <h2 class="text-center text-primary">Bilanciamento Carico FamilyTutor</h2>
  </div>
  <table class="table table-hover table-striped mt-5">
    <thead>
      <tr>
        <th scope="col">Nome Operatore</th>
        <th scope="col">In Vacanza</th>
        <th scope="col">Peso Babysitter</th>
        <th scope="col">Peso Petsitter</th>
        <th scope="col">Peso Badanti</th>
        <th scope="col">Peso Colf</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let record of loadBalanceRecords; let i = index" [formGroup]="getOperatorFormGroup(i)">
        <td>{{record.operator}} <span *ngIf="record.isExpired && record.factorExpiry"><i [ngbTooltip]="'Boost attivo fino alle: ' + (record.factorExpiry | extractTime)" class="ms-2 bi bi-rocket-takeoff"></i></span></td>
        <td><select class="form-control" formControlName="onVacation" (change)="updateOperatorVacation(i)">
          <option value="true">Si</option>
          <option value="false">No</option>
        </select>
      </td>
        <td><input class="form-control" type="number" min="0" max="1" step="0.1" formControlName="weightBB" (change)="updateOperatorWeight(i, 'BABYSITTER')"></td>
        <td><input class="form-control" type="number" min="0" max="1" step="0.1" formControlName="weightPET" (change)="updateOperatorWeight(i, 'PETSITTER')"></td>
        <td><input class="form-control" type="number" min="0" max="1" step="0.1" formControlName="weightNURSE" (change)="updateOperatorWeight(i, 'NURSE')"></td>
        <td><input class="form-control" type="number" min="0" max="1" step="0.1" formControlName="weightCOLF" (change)="updateOperatorWeight(i, 'COLF')"></td>
      </tr>
      <tr *ngIf="loadBalanceRecords.length === 0">
        <td colspan="10" class="text-center">Non sono stati trovati operatori</td>
      </tr>
    </tbody>
  </table>
</div>

