import { CommonModule } from '@angular/common';
import { VariableSharingService } from './text-editor-service/text-editor-service';
import {
  Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AngularEditorModule, AngularEditorService,
} from '@kolkov/angular-editor';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription, debounceTime, takeUntil,
} from 'rxjs';
import { VariableMailTemplateComponent } from '../modals/variable-mail-template/variable-mail-template.component';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import HorizontalRuleBlot from './custom/horizontal-separator.blots'

Quill.register({
  'formats/hr': HorizontalRuleBlot
});
// All'inizio del tuo componente, dopo le importazioni
const icons:any = Quill.import('ui/icons');
icons['hr'] = '<svg viewBox="0 0 18 18"><line class="ql-stroke" x1="3" x2="15" y1="9" y2="9"></line></svg>';


@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AngularEditorModule, VariableMailTemplateComponent, QuillEditorComponent]
})
export class TextEditorComponent implements OnInit, OnDestroy, OnChanges {

  @Output() save = new EventEmitter<string>();
  @Input() parent!: string
  @Input() text!: string;
  @Input() variable!: string;

  @ViewChild('editor', {
    static: true
  }) editor: QuillEditorComponent | undefined
  editorConfig: any;  // Configurazione dell'editor


  form = new UntypedFormGroup({
    editor: new UntypedFormControl(null),
  });

  localVariable = '';
  closeResult = '';
  VariableSubscription!: Subscription;
  variableLocal$!: Observable<string>;
  private unsubscribe = new Subject<void>();
  cursorPositionIndex!: any



  constructor(
    private modalServiceNGB: NgbModal,private editorConfigService: VariableSharingService) {
    const Font: any = Quill.import('formats/font');
    Font.whitelist = ['josefin-sans', 'sans-serif', 'serif', 'monospace', 'Verdana'];
    Quill.register(Font, true);
  }


  ngOnInit(): void {
    // //init editor config
    this.editorConfig = this.editorConfigService.getEditorConfig(() => this.insertHorizontalRule());
    this.variableLocal$ =
      this.editorConfigService.variableLocalSource.asObservable();
    this.VariableSubscription = this.variableLocal$.subscribe((variable) => {
      this.localVariable = variable;
    });
    if (this.editor !== undefined) {
      this.pushValue(this.localVariable);
      this.editor?.onContentChanged.asObservable().subscribe((text) => {
        if(text.html !== null){
          this.save.emit(text.html);
        }
      })
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.editor !== undefined) {
      this.form.get('editor')?.patchValue(this.text)
    }
  }

  pushValue(variable: string) {
    if (this.cursorPositionIndex !== undefined) {
      this.editor!.quillEditor?.insertText(this.cursorPositionIndex, variable); // insert text after the index
    }
  }

  confirmVariables(variable: string) {
    this.localVariable = variable
    this.editorConfigService.changeVariable(variable)
    this.pushValue(this.localVariable)
    this.modalServiceNGB.dismissAll()
  }

  confirmVariablesPlural(variable: string) {
    this.localVariable = variable
    this.editorConfigService.changeVariable(variable)

    this.pushValue(this.localVariable)
    this.modalServiceNGB.dismissAll()
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  open(content: any) {
    this.cursorPositionIndex = this.editor!.quillEditor?.getSelection()?.index; // get cursor position
    this.modalServiceNGB.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
  //method to insert hr on text template
  insertHorizontalRule() {
    const quill = this.editor!.quillEditor;
    const range = quill.getSelection(true);
    quill.insertEmbed(range.index, 'hr', 'null', Quill.sources.USER);
    quill.setSelection(range.index + 1, Quill.sources.SILENT);
  }

}
