import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum caregiverMode {
  NETTA = 'netta',
  FORFETTARIA = 'forfettaria'
}

@Component({
  selector: 'app-pricing-caregiver-switch',
  standalone: true,
  imports: [CommonModule],
  template: `
<div class="mode-switch d-flex align-items-center">
  <button
    class="btn me-2"
    [ngClass]="{
      'bg-primary text-white': currentMode === Mode.NETTA,
      'bg-disabled': currentMode !== Mode.NETTA
    }"
    (click)="switchMode(Mode.NETTA)">
    Tariffa netta
  </button>
  <button
    class="btn"
    [ngClass]="{
      'bg-primary text-white': currentMode === Mode.FORFETTARIA,
      'bg-disabled': currentMode !== Mode.FORFETTARIA
    }"
    (click)="switchMode(Mode.FORFETTARIA)">
    Tariffa Forfettaria
  </button>
</div>
  `,
  styles: [`
    .mode-switch .btn {
      min-width: 120px;
    }
    .bg-disabled {
      background-color:none;
      color:#3886c3;
      border-color: #3886c3
    }
  `]
})
export class PricingCaregiverSwitchComponent {
  @Input() currentMode!: caregiverMode
  Mode = caregiverMode; // expose enum to the template
  @Output() modeChanged = new EventEmitter<caregiverMode>();

  switchMode(mode: caregiverMode) {
      this.modeChanged.emit(mode);
  }
}
