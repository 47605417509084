<div class="container p-3">
  <div class="d-flex justify-content-between">
    <div class="d-flex gap-2">
      <h2 class="bi bi-arrow-left pointer" (click)="goBackToService()"></h2>
      <h2 class="mb-3" *ngIf="!isNew">Servizio: {{selectedService?.id}}</h2>
      <h2 class="mb-3" *ngIf="isNew">Registra Servizio</h2>
    </div>

  </div>
    <form [formGroup]="form" class="p-3 mb-3 rounded">
      <div class="row">
        <div class="col-6 mb-3">
          <label for="businessLine" class="form-label">Business Line</label>
          <select
            class="form-select"
            aria-label="Default select example"
            id="businessLine"
            formControlName="businessLine"
          >
            <option value="NURSE">NURSE</option>
            <option value="BABYSITTER">BABYSITTER</option>
            <option value="PETSITTER">PETSITTER</option>
            <option value="COLF">COLF</option>
          </select>
          <div *ngIf="form.get('businessLine')?.touched && form.get('businessLine')?.errors || formInvalid">
            <span class="form-error">{{validatorErrorMessage('businessLine')}}</span>
          </div>
        </div>

        <div class="col-6 mb-3">
          <label for="customerType" class="form-label">Tipo di Cliente</label>
          <select class="form-select" id="customerType" aria-label="Default select example" formControlName="customerType">
            <option value="B2C">B2C</option>
            <option value="B2B">B2B</option>
            <option value="CAREGIVER">CAREGIVER</option>
            <option value="UNICREDIT">UNICREDIT</option>
          </select>
          <div *ngIf="form.get('customerType')?.touched && form.get('customerType')?.errors || formInvalid">
            <span class="form-error">{{validatorErrorMessage('customerType')}}</span>
          </div>
        </div>
        <div class="col-6 mb-3">
          <label for="nome" class="form-label">Nome servizio</label>
          <input type="text" id="nome" class="form-control" formControlName="name">
          <div *ngIf="form.get('name')?.touched && form.get('name')?.errors || formInvalid">
            <span class="form-error">{{validatorErrorMessage('name')}}</span>
          </div>
        </div>
        <div class="col-6 mb-3">
          <label for="disabled" class="form-label">Abilita servizio</label>
          <select class="form-select" id="disabled" aria-label="Default select example" formControlName="disabled">
            <option value="true">DISABILITATO</option>
            <option value="false">ABILITATO</option>
          </select>
        </div>
        <app-text-editor [variable]="localVariable" [parent]="editorTitle" [text]="scriptText" (save)="saveScript($event)"></app-text-editor>
        <div *ngIf="isDirty && !haveText ">
          <span class="form-error">{{validatorErrorMessage('script')}}</span>
        </div>
      </div>
    </form>

    <div class="d-flex flex-row-reverse gap-2">
      <button class="btn btn-primary text-light" (click)="saveService()"><i class="bi bi-floppy-fill text-light"></i>
        Salva</button>
      <button *ngIf="!isNew" class="btn btn-danger text-light" (click)="deleteService()"><i class="bi bi-x-lg text-light pointer"></i>
        Elimina</button>
    </div>
</div>

<app-alert *ngIf="showDeleteConfirm" (save)="confirmDelete($event)" [data]="{ type: undefined }"
  [title]="'Conferma eliminazione'"
  [message]="'Sei sicuro di voler eliminare il servizio ' + selectedService?.id + '?'">
</app-alert>
