import {
  Babysitter,
  Colf,
  ModalService,
  Nurse,
  Petsitter,
  Request,
  RequestNurseDetail,
  RequestPetsitterDetail,
} from 'npx-family-happy-common';
import { CaregiverSelectionService } from './../caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
  Caregiver,
} from 'src/app/shared/models/caregiver.model';
import {
  applyColfFilter,
  applyFilter,
  applyNurseFilter,
  applyPetsitterFilter,
  getURLRelated,
} from 'src/app/shared/utils.functions';
import { RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { CaregiverSelectionRecordSortable } from 'src/app/shared/models/caregiverSelectionRecordSortable.models';
import { computeFittingIndex } from '../fitting-index/fitting-index.utils';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CaregiverCardDistancePipe } from './caregiver-card-pipes/caregiver.card-distance.pipe';
import { CaregiverCardDatePipe } from './caregiver-card-pipes/caregiver-card-date.pipe';
import { CompatibilityIndexComponent } from '../compatibility-index/compatibility-index.component';

@Component({
  selector: 'app-caregiver-card',
  templateUrl: './caregiver-card.component.html',
  styleUrls: ['./caregiver-card.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbTooltipModule, CaregiverCardDistancePipe, CaregiverCardDatePipe, CompatibilityIndexComponent]
})
export class CaregiverCardComponent implements OnInit, OnDestroy {
  private subscriptionFilters = new Subscription();
  private unsubscribe = new Subject<void>();
  filters: any;
  requestObj!: Request;
  requestDetail: undefined | RequestPetsitterDetail | RequestNurseDetail;
  isMatch: boolean | undefined = undefined;
  caregiver!: Caregiver;
  selected = false;
  haveCar: boolean = false;
  caregiverAge: number = 1;
  isPreferred!: boolean;
  cvSent!: boolean;
  cvSentDate!: Date | null;
  cvSentDateTxt!: string;
  contactSentDateTxt!: string;
  contactSent!: boolean;
  contactSentDate!: Date | null;

  private _caregiverSelectionRecord!: CaregiverSelectionRecordSortable;

  @Input()
  set caregiverSelectionRecord(value: CaregiverSelectionRecordSortable) {
    this._caregiverSelectionRecord = value;
    this.cvSent = !!value.record.cvSent;
    this.contactSent = !!value.record.contactSent
    this.cd.detectChanges();
  }
  //CHECK ENGAGEMENT - CAREGIVER
  private _isEngaged!:boolean
  @Input()
  set isEngaged(value:boolean){
    this._isEngaged = value
  }


  get caregiverSelectionRecord(): CaregiverSelectionRecordSortable {
    return this._caregiverSelectionRecord;
  }

    get isEngaged(): boolean {
    return this._isEngaged;
  }

  @Input() acceptButtonEnabled = false;
  @Input() discardButtonEnabled = true;
  @Input() isAddingCaregiver = false;
  @Output() acceptClicked = new EventEmitter<void>();
  @Output() discardClicked = new EventEmitter<void>();
  @Output() selectedCard = new EventEmitter<void>();
  @Output() showDetailClicked = new EventEmitter<void>();
  @Output() fittingIndex = new EventEmitter<void>();

  constructor(
    private caregiverSelectionService: CaregiverSelectionService,
    private stateManager: RouterStateManagerService,
    private modalService: ModalService,
    private cd: ChangeDetectorRef
  ) {
    this.stateManager
      .getRequestState()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((state) => {
        if (state.request) {
          this.requestObj = state.request;
          this.requestDetail = state.requestDetail;
        }
      });
  }

  ngOnInit(): void {
    this.sortCaregiver();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.subscriptionFilters.unsubscribe();
  }

  async sortCaregiver() {
    let data = this.caregiverSelectionRecord.record.suitableDate;
    if (this.caregiverSelectionRecord.record.babysitter) {
      let profileUrl = getURLRelated(
        'BABYSITTER',
        this.caregiverSelectionRecord.record.babysitter.docRef
      );
      this.caregiver = await this.initCaregiver(
        this.caregiverSelectionRecord.record.babysitter,
        data,
        profileUrl,
        this.caregiverSelectionRecord.record.cvSent,
        this.caregiverSelectionRecord.record.cvSentDate,
        this.caregiverSelectionRecord.record.contactSent,
        this.caregiverSelectionRecord.record.contactSentDate,
        this.caregiverSelectionRecord.record.distance,
        this.caregiverSelectionRecord.record.discardedReason,
      );
      if (this.caregiverSelectionRecord.record.babysitter.avail.car === true) {
        this.haveCar = true;
      }
      this.caregiverAge = this.caregiverSelectionRecord.record.babysitter.info.age;
    } else if (this.caregiverSelectionRecord.record.petsitter) {
      let profileUrl = getURLRelated(
        'PETSITTER',
        this.caregiverSelectionRecord.record.petsitter.docRef
      );
      this.caregiver = await this.initCaregiver(
        this.caregiverSelectionRecord.record.petsitter,
        data,
        profileUrl,
        this.caregiverSelectionRecord.record.cvSent,
        this.caregiverSelectionRecord.record.cvSentDate,
        this.caregiverSelectionRecord.record.contactSent,
        this.caregiverSelectionRecord.record.contactSentDate,
        this.caregiverSelectionRecord.record.distance,
        this.caregiverSelectionRecord.record.discardedReason,
      );
      if (this.caregiverSelectionRecord.record.petsitter.avail.car === true) {
        this.haveCar = true;
      }
      this.caregiverAge = this.caregiverSelectionRecord.record.petsitter.info.age;
    } else if (this.caregiverSelectionRecord.record.nurse) {
      let profileUrl = getURLRelated(
        'NURSE',
        this.caregiverSelectionRecord.record.nurse.docRef
      );
      this.caregiver = await this.initCaregiver(
        this.caregiverSelectionRecord.record.nurse,
        data,
        profileUrl,
        this.caregiverSelectionRecord.record.cvSent,
        this.caregiverSelectionRecord.record.cvSentDate,
        this.caregiverSelectionRecord.record.contactSent,
        this.caregiverSelectionRecord.record.contactSentDate,
        this.caregiverSelectionRecord.record.distance,
        this.caregiverSelectionRecord.record.discardedReason
          );
      if (this.caregiverSelectionRecord.record.nurse.avail.car === true) {
        this.haveCar = true;
      }
      this.caregiverAge = this.caregiverSelectionRecord.record.nurse.info.age;
    }
    else if (this.caregiverSelectionRecord.record.colf) {
      let profileUrl = getURLRelated(
        'COLF',
        this.caregiverSelectionRecord.record.colf.docRef
      );
      this.caregiver = await this.initCaregiver(
        this.caregiverSelectionRecord.record.colf,
        data,
        profileUrl,
        this.caregiverSelectionRecord.record.cvSent,
        this.caregiverSelectionRecord.record.cvSentDate,
        this.caregiverSelectionRecord.record.contactSent,
        this.caregiverSelectionRecord.record.contactSentDate,
        this.caregiverSelectionRecord.record.distance,
        this.caregiverSelectionRecord.record.discardedReason
      );
      if (this.caregiverSelectionRecord.record.colf.avail.car === true) {
        this.haveCar = true;
      }
      this.caregiverAge = this.caregiverSelectionRecord.record.colf.info.age;
    }
    this.applyFilters()
  }

  async initCaregiver(
    entity: Babysitter | Petsitter | Nurse | Colf,
    data: Date | null,
    profileUrl: string,
    cvSent: boolean,
    cvSentDate: Date | null,
    contactSent: boolean,
    contactSentDate: Date | null,
    distance: number,
    discardedReason:string
  ) {
    this.isPreferred = this.caregiverSelectionRecord.record.favorite;
    this.caregiver = {
      name: entity.info.name,
      surname: entity.info.surname,
      distance: distance,
      cvURL: entity.info.resumeHidden,
      profileURL: profileUrl,
      photoURL: entity.info.photo,
      discarded: this.caregiverSelectionRecord.record.discarded,
      subscriptionActive:
        (entity.info.subscription === 'ABBONATO' || entity.info.manualSubscription === 'ABBONATO') ? true : false,
      applicationDate: data,
      phone: entity.info.phone,
      fittingIndex: this.requestObj ? computeFittingIndex(distance ? distance * 1000 : 100000, this.requestObj, this.requestDetail, entity) : 0
    };
    this.caregiverSelectionRecord.fittingIndex = this.caregiver.fittingIndex;
    this.cvSentDate = cvSentDate;
    this.initDateCV(cvSentDate);
    this.contactSentDate = contactSentDate
    this.initDateContact(contactSentDate);
    this.fittingIndex.emit();
    return this.caregiver;
  }
  initDateCV(date: Date | null) {
    if (this.cvSent === true) {
      this.cvSentDateTxt = new Date(date!).toLocaleDateString();
    }
  }

  initDateContact(date: Date | null) {
    if (this.contactSent === true) {
      this.contactSentDateTxt = new Date(date!).toLocaleDateString();
    }
  }

  setPreferred() {
    this.isPreferred = !this.isPreferred;
    this.caregiverSelectionService
      .addCaregiverToFavorite(
        this.caregiverSelectionRecord.record.id,
        this.isPreferred
      )
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        return response;
      });
  }

  select() {
    this.selected = !this.selected;
    if (this.selected === true) {
      this.selectedCard.emit();
    }
  }
  copyToClipboard() {
    navigator.clipboard.writeText(this.caregiver.phone);
    this.modalService.showSuccessMessage('Numero di telefono copiato con successo! Ora puoi incollarlo dove vuoi.');
  }

  applyFilters() {
    this.subscriptionFilters = this.caregiverSelectionService
      .getFilters()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((filters) => {
        if (filters !== null) {
          this.filters = filters;
          if (this.requestObj !== undefined) {
            if (
              this.requestObj.requestPersonalInfo.requestCategory ===
              'BABYSITTER'
            ) {
              if (this.caregiverSelectionRecord.record.babysitter !== null) {
                this.isMatch = applyFilter(
                  this.caregiverSelectionRecord.record.babysitter,
                  filters,
                  this.caregiver.distance
                );
              }
            } else if (
              this.requestObj.requestPersonalInfo.requestCategory === 'PETSITTER'
            ) {
              this.isMatch = applyPetsitterFilter(
                this.caregiverSelectionRecord.record.petsitter!,
                filters,
                this.caregiver.distance
              );
            } else if (
              this.requestObj.requestPersonalInfo.requestCategory === 'NURSE'
            ) {
              this.isMatch = applyNurseFilter(
                this.caregiverSelectionRecord.record.nurse!,
                filters,
                this.caregiver.distance
              );
            } else if (
              this.requestObj.requestPersonalInfo.requestCategory === 'COLF'
            ) {
              this.isMatch = applyColfFilter(
                this.caregiverSelectionRecord.record.colf!,
                filters,
                this.caregiver.distance
              );
            }
          }
        }
      });
  }
}
