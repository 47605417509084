export interface ScheduledAppointment {
  id?: number;
  entityLinked: string;
  timestamp: string;
  type: ScheduledAppointmentType;
  createdBy: string;
}

export enum ScheduledAppointmentType {
  CAREGIVER_INTERVIEW = 'CAREGIVER_INTERVIEW',
  CAREGIVER_PRESENTATION = 'CAREGIVER_PRESENTATION'
}
