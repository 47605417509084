import { CommonModule } from '@angular/common';
import { Component, EventEmitter, input, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FHService, RequestDetail, RequestUtilitiesService } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { BabySitterService } from 'src/app/caregiver/babysitter/baby-sitter-service/baby-sitter-service.service';
import { RequestPricingComponent } from "../request-pricing/request-pricing.component";

@Component({
  selector: 'app-request-babysitter-detail-form',
  templateUrl: './request-babysitter-detail-form.component.html',
  styleUrls: ['./request-babysitter-detail-form.component.scss'],
  standalone:true,
  imports: [CommonModule, ReactiveFormsModule, RequestPricingComponent]
})
export class RequestBabysitterDetailFormComponent implements OnInit, OnDestroy {

  @Input() inputData!: RequestDetail;
  @Input() requestID!: number;
  @Input() provincia!: string;
  @Input() reqService!: FHService;
  @Output() dataUpdated = new EventEmitter<RequestDetail>();


  form = new UntypedFormGroup({
    numberOfChildren: new UntypedFormControl('', Validators.required),
    childrenGender: new UntypedFormControl('', Validators.required),
    ageOfChildren: new UntypedFormControl('', Validators.required),
    ageOfChildrenText: new UntypedFormControl(''),
    requestedExp: new UntypedFormGroup({
      requestedExpCluster1: new UntypedFormControl(false),
      requestedExpCluster2: new UntypedFormControl(false),
      requestedExpCluster3: new UntypedFormControl(false),
      requestedExpCluster4: new UntypedFormControl(false),
      requestedExpHomework: new UntypedFormControl(false)
    }),
    category: new UntypedFormGroup({
      babysitter: new UntypedFormControl(false),
      homework: new UntypedFormControl(false),
      cleaning: new UntypedFormControl(false),
      cooking: new UntypedFormControl(false),
      taxi: new UntypedFormControl(false),
      taxiNoCar: new UntypedFormControl(false),
      walking: new UntypedFormControl(false),
      petsitter: new UntypedFormControl(false),
      licence: new UntypedFormControl(false),
      car: new UntypedFormControl(false),
      smoker: new UntypedFormControl(false),
      greenpass: new UntypedFormControl(false)
    }),
    requestedAge: new UntypedFormGroup({
      requestedAgeCluster1: new UntypedFormControl(false),
      requestedAgeCluster2: new UntypedFormControl(false),
      requestedAgeCluster3: new UntypedFormControl(false)
    }),
    requiredLanguage: new UntypedFormGroup({
      italian: new UntypedFormControl(false),
      english: new UntypedFormControl(false),
      french: new UntypedFormControl(false),
      german: new UntypedFormControl(false),
      spanish: new UntypedFormControl(false)
    }),
    starting: new UntypedFormControl('', Validators.required),
    estimatedLasting: new UntypedFormControl('', Validators.required),
    serviceStart: new UntypedFormControl('', Validators.required),
    serviceDuration: new UntypedFormControl('', Validators.required),
    continuousJob: new UntypedFormControl(false, Validators.required),
    moreThanOne: new UntypedFormControl(false, Validators.required),
    handicap: new UntypedFormControl(false, Validators.required),
    badWorkHours: new UntypedFormControl(false, Validators.required),
    discount: new UntypedFormControl(false),
    overPrice: new UntypedFormControl(false),
    taxisitter: new UntypedFormControl(false),
    hoursAmount: new UntypedFormControl(0),
    animals: new UntypedFormControl(false),
    animalsType: new UntypedFormControl(''),
    netHourCost: new UntypedFormControl(0),
    flatCost: new UntypedFormControl(0),
    flatCostTiming: new UntypedFormControl(0),
    packageFlatCost: new UntypedFormControl(0),
    coliving: new UntypedFormControl(false),
    relocation: new UntypedFormControl(false),
    withholdingTax: new UntypedFormControl(false),
    days: new UntypedFormGroup({
      sun: new UntypedFormControl(false),
      mon: new UntypedFormControl(false),
      tue: new UntypedFormControl(false),
      wed: new UntypedFormControl(false),
      thu: new UntypedFormControl(false),
      fri: new UntypedFormControl(false),
      sat: new UntypedFormControl(false),
    }),
    hours: new UntypedFormGroup({
      mor: new UntypedFormControl(false),
      aft: new UntypedFormControl(false),
      eve: new UntypedFormControl(false),
    }),
    requestDescription: new UntypedFormControl('', Validators.required),
  })

  private unsubscribe = new Subject<void>();


  constructor(private utilities: RequestUtilitiesService, private babysitterService: BabySitterService) { }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((_) => {
        this.pushDataChanges();
      })
  }

  updateNetHourPrice() {
    const formValue = this.form.value;
    this.form.get('netHourCost')?.setValue(
      this.utilities.computeNetPrice(this.provincia, formValue.moreThanOne, formValue.handicap, formValue.badWorkHours));
    this.pushDataChanges();
  }

  decreaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  decreaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  private initForm() {
    this.form.setValue({
      numberOfChildren: this.inputData.numberOfChildren,
      childrenGender: this.inputData.childrenGender,
      ageOfChildren: this.inputData.ageOfChildren,
      ageOfChildrenText: this.inputData.ageOfChildrenText,
      starting: this.inputData.starting,
      estimatedLasting: this.inputData.estimatedLasting,
      serviceStart: this.inputData.serviceStart,
      serviceDuration: this.inputData.serviceDuration,
      continuousJob: this.inputData.continuousJob,
      moreThanOne: this.inputData.moreThanOne,
      handicap: this.inputData.handicap,
      badWorkHours: this.inputData.badWorkHours,
      discount: this.inputData.discount,
      overPrice: this.inputData.overPrice,
      taxisitter: this.inputData.taxisitter,
      hoursAmount: this.inputData.hoursAmount,
      animals: this.inputData.animals,
      animalsType: this.inputData.animalsType,
      netHourCost: this.inputData.netHourCost,
      flatCost: this.inputData.flatCost,
      flatCostTiming: this.inputData.flatCostTiming,
      packageFlatCost: this.inputData.packageFlatCost,
      coliving: this.inputData.coliving,
      relocation: this.inputData.relocation,
      withholdingTax: this.inputData.withholdingTax,
      requestDescription: this.inputData.requestDescription,
      requestedExp: {
        requestedExpCluster1: this.inputData.requestedExp.includes('0-6 mesi'),
        requestedExpCluster2: this.inputData.requestedExp.includes('7-24 mesi'),
        requestedExpCluster3: this.inputData.requestedExp.includes('2-5 anni'),
        requestedExpCluster4: this.inputData.requestedExp.includes('5+ anni'),
        requestedExpHomework: this.inputData.requestedExp.includes('Aiutocompiti')
      },
      category: {
        babysitter: this.inputData.category.includes('babysitter'),
        homework: this.inputData.category.includes('homework'),
        cleaning: this.inputData.category.includes('cleaning'),
        cooking: this.inputData.category.includes('cooking'),
        taxi: this.inputData.category.includes('taxi'),
        taxiNoCar: this.inputData.category.includes('taxiNoCar'),
        walking: this.inputData.category.includes('walking'),
        petsitter: this.inputData.category.includes('petsitter'),
        licence: this.inputData.category.includes('licence'),
        car: this.inputData.category.includes('car'),
        smoker: this.inputData.category.includes('smoker'),
        greenpass: this.inputData.category.includes('greenpass')
      },
      requestedAge: {
        requestedAgeCluster1: this.inputData.requestedAge.includes('18-35'),
        requestedAgeCluster2: this.inputData.requestedAge.includes('36-50'),
        requestedAgeCluster3: this.inputData.requestedAge.includes('51+')
      },
      requiredLanguage: {
        italian: this.inputData.requiredLanguage.includes('italian'),
        english: this.inputData.requiredLanguage.includes('english'),
        french: this.inputData.requiredLanguage.includes('french'),
        german: this.inputData.requiredLanguage.includes('german'),
        spanish: this.inputData.requiredLanguage.includes('spanish')
      },
      days: this.babysitterService.fromStringToAvailObject(this.inputData.workDays, 'days'),
      hours: this.babysitterService.fromStringToAvailObject(this.inputData.workHours, 'hours')
    });
  }

  private pushDataChanges() {
    const data = this.form.value;
    let category: string[] = [];
    Object.entries<boolean>(data.category).forEach((value) => {
      if (value[1]) {
        category.push(value[0]);
      }
    });

    let requestedAge: string[] = [];
    Object.entries<boolean>(data.requestedAge).forEach((value) => {
      if (value[1]) {
        switch (value[0]) {
          case 'requestedAgeCluster1':
            requestedAge.push('18-35');
            break;

          case 'requestedAgeCluster2':
            requestedAge.push('36-50');
            break;

          case 'requestedAgeCluster3':
            requestedAge.push('51+');
            break;

          default:
            break;
        }
      }
    })

    let requestedExp: string[] = [];
    Object.entries<boolean>(data.requestedExp).forEach((value) => {
      if (value[1]) {
        switch (value[0]) {
          case 'requestedExpCluster1':
            requestedExp.push('0-6 mesi');
            break;

          case 'requestedExpCluster2':
            requestedExp.push('7-24 mesi');
            break;

          case 'requestedExpCluster3':
            requestedExp.push('2-5 anni');
            break;

          case 'requestedExpCluster4':
            requestedExp.push('5+ anni');
            break;

          case 'requestedExpHomework':
            requestedExp.push('Aiutocompiti');
            break;

          default:
            break;
        }
      }
    })

    let requiredLanguage: string[] = [];
    Object.entries<boolean>(data.requiredLanguage).forEach((value) => {
      if (value[1]) {
        requiredLanguage.push(value[0]);
      }
    })

    const newOpInfo: RequestDetail = {
      numberOfChildren: data.numberOfChildren,
      childrenGender: data.childrenGender,
      ageOfChildren: data.ageOfChildren,
      ageOfChildrenText: data.ageOfChildrenText,
      starting: data.starting,
      estimatedLasting: data.estimatedLasting,
      serviceStart: data.serviceStart,
      serviceDuration: data.serviceDuration,
      continuousJob: data.continuousJob,
      moreThanOne: data.moreThanOne,
      handicap: data.handicap,
      badWorkHours: data.badWorkHours,
      discount: data.discount,
      overPrice: data.overPrice,
      taxisitter: data.taxisitter,
      hoursAmount: data.hoursAmount,
      animals: data.animals,
      animalsType: data.animalsType,
      netHourCost: data.netHourCost,
      flatCost: data.flatCost,
      flatCostTiming: +data.flatCostTiming,
      packageFlatCost: data.packageFlatCost,
      withholdingTax: data.withholdingTax,
      requestDescription: data.requestDescription,
      category,
      requestedAge,
      requestedExp,
      requiredLanguage,
      coliving: data.coliving,
      relocation: data.relocation,
      workHours: this.babysitterService.fromAvailObjectToString(data.hours, 'hours'),
      workDays: this.babysitterService.fromAvailObjectToString(data.days, 'days'),
    }
    this.dataUpdated.emit(newOpInfo);
  }

  updatePricing(pricing:any){
    this.form.setValue({
      ...this.form.value,
      taxisitter: pricing.taxisitter,
      hoursAmount: pricing.hoursAmount,
      netHourCost: pricing.netHourCost,
      flatCost: pricing.flatCost,
      flatCostTiming: pricing.flatCostTiming,
      packageFlatCost: pricing.packageFlatCost,
      withholdingTax: pricing.withholdingTax,
    })
  }

}
