import { Babysitter, Colf, Nurse, Petsitter, Request, RequestCategory, RequestDetail, RequestNurseDetail, RequestPetsitterDetail } from 'npx-family-happy-common';

export const computeFittingIndex = (distance: number, request: Request, requestDetail: undefined | RequestPetsitterDetail | RequestNurseDetail, babysitter: Babysitter | Petsitter | Nurse | Colf) => {
  // Init Weights
  const K_DIST = 2;
  const K_E = 1.5;
  const K_CA = 2;
  const K_CB = 1;
  const K_ETA = 1;
  const K_L = 1;
  const K_V = 1;
  const K_PERIC = 1;

  let FS_1 = 0;
  let FS_2 = 0;
  let FS_3 = 0;
  let FS_4 = 0;
  let FS_5 = 0;
  let FS_6 = 0;

  // Compute babysitter variables
  const businessLine = request.requestPersonalInfo.requestCategory;
  const service = request.requestPersonalInfo.serviceLinked.name;
  if (businessLine === RequestCategory.BABYSITTER) {
    FS_1 = service === 'Babysitter Fissa' ? 1 : 0;
    FS_2 = service === 'Babysitter Fissa + Assistenza Fiscale' ? 1 : 0;
    FS_3 = service === 'Babysitter Occasionale' ? 1 : 0;
    FS_4 = service === 'Babysitter Occasionale - Luglio' ? 1 : 0;
    FS_5 = service === 'Pacchetto Ore' ? 1 : 0;
    FS_6 = service === 'Babysitter Fissa 2024' ? 1 : 0;
  }

  if (businessLine === RequestCategory.PETSITTER) {
    FS_1 = service === 'Petsitter Fissa' ? 1 : 0;
    FS_3 = service === 'Petsitter Occasionale' ? 1 : 0;
    FS_5 = service === 'Pacchetto Ore' ? 1 : 0;
  }

  if (businessLine === RequestCategory.NURSE) {
    FS_1 = service === 'Badante Fissa' ? 1 : 0;
    FS_2 = service === 'Badante Fissa + Assistenza Fiscale' ? 1 : 0;
    FS_3 = service === 'Badante Occasionale' ? 1 : 0;
    FS_4 = service === 'Badante Convivente' ? 1 : 0;
    FS_5 = service === 'Pacchetto Ore' ? 1 : 0;
  }

  // Compute partial indexes
  const DIST = computeDistance(distance, FS_3, FS_4);
  const E = computeExp(request, requestDetail, babysitter);
  const C: { CA: number, CB: number } = computeCategory(request, requestDetail, babysitter);
  const ETA = computeAge(request, requestDetail, babysitter);
  const L = computeLanguage(request, babysitter);
  const V = computeEvaluation(babysitter);
  const PERIC = computeDanger(babysitter);
  /* console.log('COMPUTE INDEX:', {
    DIST,
    E,
    C,
    ETA,
    L,
    V,
    PERIC,
    total: DIST * E * C.CA * C.CB * ETA * L * PERIC * V
  }); */

  return DIST * E * C.CA * C.CB * ETA * L * PERIC * V;
}

export const computeDistance = (distance: number, FS_3: number, FS_4: number) => {
  // If distance is undefined, set it to the average of the other distances, otherwise, set it to a high value
  let total = 0.2;
  if (distance > -1) {
    total = (3000 - 1000 * (FS_3 + FS_4)) / distance;
  }
  return Math.min(total, 1);
}

export const computeExp = (request: Request, requestDetail: undefined | RequestPetsitterDetail | RequestNurseDetail, caregiver: Babysitter | Petsitter | Nurse | Colf) => {
  let N_CLUSTERS = 5;

  // Init weights
  let N_Flag = [6, 7, 7, 4, 6]; // The maximum number of tasks a caregiver could have done for each cluster

  // Compute the required experience
  const F_E = [0, 0, 0, 0, 0];
  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
    N_CLUSTERS = 5; // The number of clusters for the specified requestCategory
    N_Flag = [6, 7, 7, 4, 6];
    F_E[0] = request.requestDetail.requestedExp.includes('0-6 mesi') ? 1 : 0;
    F_E[1] = request.requestDetail.requestedExp.includes('7-24 mesi') ? 1 : 0;
    F_E[2] = request.requestDetail.requestedExp.includes('2-5 anni') ? 1 : 0;
    F_E[3] = request.requestDetail.requestedExp.includes('5+ anni') ? 1 : 0;
    F_E[4] = request.requestDetail.requestedExp.includes('Aiutocompiti') ? 1 : 0;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
    requestDetail = requestDetail as RequestPetsitterDetail;
    N_CLUSTERS = 3; // The number of clusters for the specified requestCategory
    N_Flag = [5, 2, 2, 0, 0];
    F_E[0] = requestDetail.requestedExp.includes('Cuccioli') ? 1 : 0;
    F_E[1] = requestDetail.requestedExp.includes('Adulti') ? 1 : 0;
    F_E[2] = requestDetail.requestedExp.includes('Anziani') ? 1 : 0;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
    requestDetail = requestDetail as RequestNurseDetail;
    N_CLUSTERS = 2; // The number of clusters for the specified requestCategory
    N_Flag = [6, 6, 0, 0, 0];
    F_E[0] = requestDetail.requestedExp.includes('Cuccioli') ? 1 : 0;
    F_E[1] = requestDetail.requestedExp.includes('Adulti') ? 1 : 0;
  }

  if (F_E.every(item => item === 0)) { // If the request, has no required experience, return 1
    return 1;
  }

  // Compute variables for babysitters
  let C_E = [0, 0, 0, 0, 0]; // The years of experience of the caregiver in relation to the different clusters
  let C_ET = 0; // The total of the years of experience the caregiver has
  let C_EE = [0, 0, 0, 0, 0]; // The number of tasks a caregiver has done for each cluster
  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
    caregiver = caregiver as Babysitter;
    C_E[0] = caregiver.exp.cluster1.cluster1Age ?? 0;
    C_E[1] = caregiver.exp.cluster2.cluster2Age ?? 0;
    C_E[2] = caregiver.exp.cluster3.cluster3Age ?? 0;
    C_E[3] = caregiver.exp.cluster4.cluster4Age ?? 0;
    C_E[4] = caregiver.exp.experienceHomework.expYears ?? 0;
    C_ET = caregiver.exp.total ?? 0;
    C_EE[0] = caregiver.exp.cluster1.tasks1.length ?? 0; // The tasks field stores only the values of the tasks done
    C_EE[1] = caregiver.exp.cluster2.tasks2.length ?? 0;
    C_EE[2] = caregiver.exp.cluster3.tasks3.length ?? 0;
    C_EE[3] = caregiver.exp.cluster4.tasks4.length ?? 0;
    if (caregiver.exp.experienceHomework && caregiver.exp.experienceHomework.subjects) {
      C_EE[4] = Object.values(caregiver.exp.experienceHomework.subjects).filter(item => item === true).length;
    }
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
    caregiver = caregiver as Petsitter;
    C_E[0] = caregiver.exp.cluster1.cluster1Age;
    C_E[1] = caregiver.exp.cluster2.cluster2Age;
    C_E[2] = caregiver.exp.cluster3.cluster3Age;
    C_ET = caregiver.exp.total;
    if (caregiver.exp) {
      if (caregiver.exp.cluster1 && caregiver.exp.cluster1.tasks1) {
        C_EE[0] = caregiver.exp.cluster1.tasks1.split('-').length; // The tasks field stores only the values of the tasks done
      }
      if (caregiver.exp.cluster2 && caregiver.exp.cluster2.tasks2) {
        C_EE[1] = caregiver.exp.cluster2.tasks2.split('-').length; // The tasks field stores only the values of the tasks done
      }
      if (caregiver.exp.cluster3 && caregiver.exp.cluster3.tasks3) {
        C_EE[2] = caregiver.exp.cluster3.tasks3.split('-').length; // The tasks field stores only the values of the tasks done
      }
    }
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
    caregiver = caregiver as Nurse;
    C_E[0] = caregiver.exp.cluster1.cluster1Age;
    C_E[1] = caregiver.exp.cluster2.cluster2Age;
    C_ET = caregiver.exp.total;
    C_EE[0] = caregiver.exp.cluster1.tasks1.split('-').length; // The tasks field stores only the values of the tasks done
    C_EE[1] = caregiver.exp.cluster2.tasks2.split('-').length;
  }

  let F_ET = 0;
  let partial = 0;
  for (let i = 0; i < N_CLUSTERS; i++) {
    partial = partial + F_E[i] * (C_EE[i] / N_Flag[i]) * (C_E[i] ^ 0.5);
    F_ET = F_ET + F_E[i];
  }

  let E_PARZ = partial / F_ET;

  let total = E_PARZ + (C_ET / 3) ^ 0.5;
  return Math.min(total, 5)/4;
}

export const computeCategory = (request: Request, requestDetail: undefined | RequestPetsitterDetail | RequestNurseDetail, caregiver: Babysitter | Petsitter | Nurse | Colf) => {
  let N_CATEGORIES = 13;

  // Compute the required category
  let F_C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
    N_CATEGORIES = 12; // The number of categories for the specified requestCategory
    F_C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    F_C[0] = request.requestDetail.category.includes('babysitter') ? 1 : 0;
    F_C[1] = request.requestDetail.category.includes('cleaning') ? 1 : 0;
    F_C[2] = request.requestDetail.category.includes('cooking') ? 1 : 0;
    F_C[3] = request.requestDetail.category.includes('petsitter') ? 1 : 0;
    F_C[4] = request.requestDetail.category.includes('taxi') ? 1 : 0;
    F_C[5] = request.requestDetail.category.includes('taxiNoCar') ? 1 : 0;
    F_C[6] = request.requestDetail.category.includes('walking') ? 1 : 0;
    F_C[7] = request.requestDetail.category.includes('licence') ? 1 : 0;
    F_C[8] = request.requestDetail.category.includes('car') ? 1 : 0;
    F_C[9] = request.requestDetail.category.includes('coliving') ? 1 : 0;
    F_C[10] = request.requestDetail.category.includes('smoker') ? 1 : 0;
    F_C[11] = request.requestDetail.category.includes('homework') ? 1 : 0;
    F_C[12] = request.requestDetail.category.includes('relocation') ? 1 : 0;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
    requestDetail = requestDetail as RequestPetsitterDetail;
    N_CATEGORIES = 12; // The number of categories for the specified requestCategory
    F_C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    F_C[0] = requestDetail.hosting ? 1 : 0;
    F_C[1] = requestDetail.homeService ? 1 : 0;
    F_C[2] = requestDetail.grooming ? 1 : 0;
    F_C[3] = requestDetail.walking ? 1 : 0;
    F_C[4] = requestDetail.pension ? 1 : 0;
    F_C[5] = requestDetail.nightShift ? 1 : 0;
    F_C[6] = requestDetail.veterinarian ? 1 : 0;
    F_C[7] = requestDetail.license ? 1 : 0;
    F_C[8] = requestDetail.car ? 1 : 0;
    F_C[9] = requestDetail.cleaning ? 1 : 0;
    F_C[10] = requestDetail.coliving ? 1 : 0;
    F_C[11] = requestDetail.relocation ? 1 : 0;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
    requestDetail = requestDetail as RequestNurseDetail;
    N_CATEGORIES = 12; // The number of categories for the specified requestCategory
    F_C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    F_C[0] = requestDetail.hospital ? 1 : 0;
    F_C[1] = requestDetail.changing ? 1 : 0;
    F_C[2] = requestDetail.dayShift ? 1 : 0;
    F_C[3] = requestDetail.nightShift ? 1 : 0;
    F_C[4] = requestDetail.walking ? 1 : 0;
    F_C[5] = requestDetail.taxiNoCar ? 1 : 0;
    F_C[6] = requestDetail.taxi ? 1 : 0;
    F_C[7] = requestDetail.license ? 1 : 0;
    F_C[8] = requestDetail.car ? 1 : 0;
    F_C[9] = requestDetail.cleaning ? 1 : 0;
    F_C[10] = requestDetail.coliving ? 1 : 0;
    F_C[11] = requestDetail.relocation ? 1 : 0;
  }

  // Compute variables for babysitters
  let C_C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // The roles the caregiver is willing to accept in relation to the different categories
  let C = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // The partials computed for each category
  //let K_C = [1, 3, 3, 0, 1, 1, 1, 0, 1, 0, 0, 0]; // The weights to be used
  let K_C = [1, 1, 1, 0, 1, 1, 1, 0, 1, 0, 0, 0]; // The weights to be used
  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
    caregiver = caregiver as Babysitter;
    C_C[0] = caregiver.avail.babysitter ? 1 : 0;
    C_C[1] = caregiver.avail.cleaning ? 1 : 0;
    C_C[2] = caregiver.avail.cooking ? 1 : 0;
    C_C[3] = caregiver.avail.petSitter ? 1 : 0;
    C_C[4] = caregiver.avail.taxi ? 1 : 0;
    C_C[5] = caregiver.avail.taxiNoCar ? 1 : 0;
    C_C[6] = caregiver.avail.walking ? 1 : 0;
    C_C[7] = caregiver.avail.licence ? 1 : 0;
    C_C[8] = caregiver.avail.car ? 1 : 0;
    C_C[9] = caregiver.avail.coliving ? 1 : 0;
    C_C[10] = caregiver.avail.smoker ? 1 : 0;
    C_C[11] = caregiver.avail.homework ? 1 : 0;
    C_C[12] = caregiver.avail.relocation ? 1 : 0;
    // Compute the partials
    C[0] = F_C[0] * C_C[0];
    C[1] = F_C[1] * C_C[1];
    C[2] = F_C[2] * C_C[2];
    C[4] = F_C[4] === 0 ? 1 : F_C[4] * (C_C[4] + C_C[8] * C_C[7] / 2)
    C[5] = F_C[5] === 0 ? 1 : F_C[5] * (C_C[5] + C_C[7] / 2);
    C[6] = F_C[6] === 0 ? 1 : F_C[6] * C_C[6] + 0.5;
    C[8] = F_C[8] === 0 ? 1 : F_C[8] * C_C[8];
    C[9] = F_C[9] * C_C[9];
    C[10] = F_C[10] * C_C[10];
    C[11] = F_C[11] * C_C[11];
    C[12] = F_C[12] * C_C[12];
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
    caregiver = caregiver as Petsitter;
    C_C[0] = caregiver.avail.hosting ? 1 : 0;
    C_C[1] = caregiver.avail.homeService ? 1 : 0;
    C_C[2] = caregiver.avail.grooming ? 1 : 0;
    C_C[3] = caregiver.avail.walking ? 1 : 0;
    C_C[4] = caregiver.avail.pension ? 1 : 0;
    C_C[5] = caregiver.avail.nightShift ? 1 : 0;
    C_C[6] = caregiver.avail.veterinarian ? 1 : 0;
    C_C[7] = caregiver.avail.license ? 1 : 0;
    C_C[8] = caregiver.avail.car ? 1 : 0;
    C_C[9] = caregiver.avail.cleaning ? 1 : 0;
    C_C[10] = caregiver.avail.coliving ? 1 : 0;
    C_C[11] = caregiver.avail.relocation ? 1 : 0;
    // Compute the partials
    for (let i = 0; i < C.length; i++) {
      C[i] = F_C[i] * C_C[i];
    }
    K_C[3] = 1;
    K_C[9] = 1;
    //console.log('Petsitter partials:', C, F_C, C_C, K_C);
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
    caregiver = caregiver as Nurse;
    C_C[0] = caregiver.avail.hospital ? 1 : 0;
    C_C[1] = caregiver.avail.changing ? 1 : 0;
    C_C[2] = caregiver.avail.dayShift ? 1 : 0;
    C_C[3] = caregiver.avail.nightShift ? 1 : 0;
    C_C[4] = caregiver.avail.walking ? 1 : 0;
    C_C[5] = caregiver.avail.taxiNoCar ? 1 : 0;
    C_C[6] = caregiver.avail.taxi ? 1 : 0;
    C_C[7] = caregiver.avail.license ? 1 : 0;
    C_C[8] = caregiver.avail.car ? 1 : 0;
    C_C[9] = caregiver.avail.cleaning ? 1 : 0;
    C_C[10] = caregiver.avail.coliving ? 1 : 0;
    C_C[11] = caregiver.avail.relocation ? 1 : 0;

    // Compute the partials
    C[0] = F_C[0] * C_C[0];
    C[1] = F_C[1] * C_C[1];
    C[2] = F_C[2] * C_C[2];
    C[3] = F_C[3] * C_C[3];
    C[4] = F_C[4] * C_C[4];
    C[5] = F_C[5] === 0 ? 1 : F_C[5] * (C_C[5] + C_C[7] / 2)
    C[6] = F_C[6] === 0 ? 1 : F_C[5] * (C_C[5] + C_C[8] * C_C[7] / 2);
    C[7] = F_C[7] === 0 ? 1 : F_C[7] * C_C[7];
    C[8] = F_C[8] === 0 ? 1 : F_C[8] * C_C[8];
    C[9] = F_C[9] * C_C[9];
    C[10] = F_C[10] * C_C[10];
    C[11] = F_C[11] * C_C[11];
  }

  let F_CT = 0;
  let partial = 0;
  for (let i = 0; i < N_CATEGORIES; i++) {
    partial = partial + (C[i] * K_C[i] * F_C[i]);
    F_CT = F_CT + F_C[i];
  }

  let CA = F_CT === 0 ? 1 : partial / F_CT;
  let CB = C[4] * C[5] * C[6] * C[8];

  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER && F_C[0] === 0 && F_C[1] === 0 && F_C[2] === 0) {
    CA = 1;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER || F_C[4] === 0 && F_C[5] === 0 && F_C[6] === 0 && F_C[8] === 0) {
    CB = 1;
  }

  return { CA, CB };
}

export const computeAge = (request: Request, requestDetail: undefined | RequestPetsitterDetail | RequestNurseDetail, babysitter: Babysitter | Petsitter | Nurse | Colf) => {
  const C_ETA = babysitter.info.age;

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
    request.requestDetail.requestedAge = (requestDetail as RequestPetsitterDetail).requestedAge;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
    request.requestDetail.requestedAge = (requestDetail as RequestNurseDetail).requestedAge;
  }

  const F_ETA1 = request.requestDetail.requestedAge.includes('18-35') ? 26.5 : 0;
  const F_ETA2 = request.requestDetail.requestedAge.includes('36-50') ? 43 : 0;
  const F_ETA3 = request.requestDetail.requestedAge.includes('51+') ? 55 : 0;
  const F_ETA = F_ETA1 + F_ETA2 + F_ETA3; // there should be only one value that is different than zero

  if (F_ETA === 0) {
    return 1;
  }

  const partial = (7 / Math.abs(F_ETA - C_ETA)) ^ 2;
  return Math.min(partial, 1);
}

export const computeLanguage = (request: Request, babysitter: Babysitter | Petsitter | Nurse | Colf) => {
  let N_LANGUAGES = 5;

  // Compute the required languages
  const F_L = [0, 0, 0, 0, 0];
  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
    N_LANGUAGES = 5; // The number of languages for the specified requestCategory
    F_L[0] = request.requestDetail.requiredLanguage.includes('italian') ? 1 : 0;
    F_L[1] = request.requestDetail.requiredLanguage.includes('english') ? 1 : 0;
    F_L[2] = request.requestDetail.requiredLanguage.includes('french') ? 1 : 0;
    F_L[3] = request.requestDetail.requiredLanguage.includes('german') ? 1 : 0;
    F_L[4] = request.requestDetail.requiredLanguage.includes('spanish') ? 1 : 0;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.PETSITTER) {
    return 1;
  }

  if (request.requestPersonalInfo.requestCategory === RequestCategory.NURSE) {
    return 1;
  }

  if (F_L.every(item => item === 0)) { // If the request, has no required language, return 1
    return 1;
  }

  // Compute variables for babysitters
  let C_L = [0, 0, 0, 0, 0]; // The years of experience of the caregiver in relation to the different clusters
  if (request.requestPersonalInfo.requestCategory === RequestCategory.BABYSITTER) {
    babysitter = babysitter as Babysitter;
    C_L[0] = computeBabysitterLanguageScore(babysitter.lang.italian);
    C_L[1] = computeBabysitterLanguageScore(babysitter.lang.english);
    C_L[2] = computeBabysitterLanguageScore(babysitter.lang.french);
    C_L[3] = computeBabysitterLanguageScore(babysitter.lang.german);
    C_L[4] = computeBabysitterLanguageScore(babysitter.lang.spanish);
  }

  let total = 0;
  let F_LT = 0;
  for (let i = 0; i < N_LANGUAGES; i++) {
    total = total + F_L[i] * C_L[i];
    F_LT = F_LT + F_L[i];
  }

  return total/F_LT;
}

export const computeBabysitterLanguageScore = (level: string) => {
  if (level === 'Madrelingua') {
    return 1;
  } else if (level === 'Ottimo') {
    return 0.7;
  } else if (level === 'Sufficiente') {
    return 0.1;
  } else {
    return 0;
  }
}

export const computeEvaluation = (babysitter: Babysitter | Petsitter | Nurse | Colf) => {
  const C_V1 = babysitter.rating.overall;
  const total = C_V1 === 0 || C_V1 === 1 ? 1 : ((C_V1 - 5) > 0 ? 1 : 0);
  return (total ^ 0.5)/2;
}

export const computeDanger = (babysitter: Babysitter | Petsitter | Nurse | Colf) => {
  return babysitter.rating.dangerous ? 0 : 1;
}
