import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VariableSharingService {
  variableLocalSource = new BehaviorSubject<string>('');

  constructor() { }

  changeVariable(variable: string) {
    this.variableLocalSource.next(variable);
  }

  //method to share configuration inside different components
  getEditorConfig(insertHorizontalRuleFn: () => void) {
    return {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 }, { 'header': 4 }], // custom header buttons
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'script': 'sub' }, { 'script': 'super' }],    // superscript/subscript
          [{ 'indent': '-1' }, { 'indent': '+1' }],        // outdent/indent
          [{ 'size': ['small', false, 'large', 'huge', 'default'] }],  // size dropdown
          [{ 'header': [1, 2, 3, 4, false] }],
          [{ 'font': ['josefin-sans', 'sans-serif', 'serif', 'monospace', 'verdana'] }],
          [{ 'align': [] }],
          ['link'],
          ['hr']
        ],
        handlers: {
          'hr': insertHorizontalRuleFn // Funzione handler per 'hr'
        }
      }
    };
  }
}
