export interface CaregiverEvaluation {
  overall: number,
  punctuality: number,
  empathy: number,
  behave: number,
  communication: number,
  attitude: string,
  dangerous: boolean,
  notRated: boolean
}

export const DEFAULT_CAREGIVER_EVALUATION:CaregiverEvaluation = {
  overall: 0,
  punctuality: 0,
  empathy: 0,
  behave: 0,
  communication: 0,
  attitude: '',
  dangerous: false,
  notRated: false,
}
