import { TimerPipe } from './../../pipes/timer.pipe';
import { ProvinceService } from './../../services/province-service/province.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/auth/auth.service';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { User } from '../../models/user.model';
import { UserManagementService } from 'src/app/backoffice/user-management/user-management-service/user-management.service';
import { CommonModule } from '@angular/common';
import { ModalService, ProvinceFilterComponent } from 'npx-family-happy-common';
import { AlertComponent } from '../modals/alert/alert.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadBalanceService } from 'src/app/backoffice/load-balance/load-balance-service/load-balance.service';
import { finalize, map, of, Subject, Subscription, switchMap, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { ToastService } from '../../services/toast-service/toast.service';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, ProvinceFilterComponent, AlertComponent, NgbDropdownModule, TimerPipe]
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  @Output() showMenu = new EventEmitter();
  user!: User;
  showAlert: boolean = false;
  editForm: UntypedFormGroup;
  provincia: string | null = null;

  isBoostActive: boolean = false;
  cooldown!: number | null;
  timerSubscription: Subscription | null = null;

  toast!: ToastComponent | null;
  unsubscribe = new Subject<void>();

  constructor(
    private userStorage: UserStorageService,
    private router: Router,
    private auth: AuthService,
    private management: UserManagementService,
    private provinceService: ProvinceService,
    private loadBalanceService: LoadBalanceService,
    private modalService: ModalService,
    private toastService:ToastService
  ) {
    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('nameSurname')
    });
  }

  ngOnInit(): void {
    this.provincia = this.provinceService.getProvince().getValue();
    this.toastService.getChildComponent().pipe(takeUntil(this.unsubscribe)).subscribe((component) => this.toast = component)

    this.userStorage.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        this.editForm.patchValue({ name: this.user?.name, surname: this.user?.surname })
      }
    });

    this.loadBalanceService.isTutorCheck(this.user.uid).pipe(
      switchMap((isTutor) => {
        if (isTutor.check === 'false') {
          return of(null);
        }

        return this.loadBalanceService.getReaminingFactorTime(this.user.uid).pipe(
          switchMap((res) => {
            const time = parseInt(res.remainingFactorTime, 10);

            if (isNaN(time) || time <= 0) {
              return of("Nessun tempo rimanente o valore non valido");
            }

            this.isBoostActive = true;
            this.loadBalanceService.setTimer(time);

            // Timer per calcolo remainingTime
            this.timerSubscription = timer(0, 1000).pipe(
              map((seconds: number) => time - seconds),
              takeWhile((remainingTime: number) => remainingTime > 0),
              finalize(() => {
                //INSERIRE ALERT
                this.loadBalanceService.deactivateBoost(this.user.uid).subscribe();
                this.loadBalanceService.setTimer(null);
                this.modalService.showErrorMessage('Periodo di Boost terminato!')
              })
            ).subscribe((remainingTime: number) => {
              this.cooldown = remainingTime; // Aggiorna lo stato del timer
              this.loadBalanceService.setTimer(remainingTime); // Sincronizza col servizio
            });

            return of(this.timerSubscription);
          })
        );
      })
    ).subscribe({
      next: () => this.timerSubscription,
      error: (err) => console.error("Error in boost logic", err),
    });
  }

  signOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['/login']);
      console.log('Signed out');
    })
  }

  editNameSurname() {
    this.showAlert = true;
  }

  closeEditModal() {
    this.showAlert = false;
  }

  updateProvincia(newValue: string) {
    this.provinceService.setProvince(newValue);
  }

  onAlertAction(save: boolean) {
    if (save) {
      const name = this.editForm.value.name
      const surname = this.editForm.value.surname
      this.userStorage.setUser(this.user as User)
      this.management.changeUserName(this.user.uid, name, surname).subscribe((user) => {
        this.closeEditModal()
      });
    }
    else this.closeEditModal()
  }

  activeBoost() {
    const boostDuration = 60 * 60 * 1000; // 1 ora in millisecondi
    if (!this.isBoostActive) {
      this.loadBalanceService.isTutorCheck(this.user.uid).pipe(
        switchMap((isTutor) => {
          if (isTutor) {
            return this.loadBalanceService.activeBoost(this.user.uid, 100).pipe(
              switchMap((res: any) => {
                this.isBoostActive = true;
                this.toastService.setAction("Il boost è da ora attivo per un'ora. In quest'ora, avrai molte più probabilità di ottenere una richiesta da gestire.")
                this.toast!.showSuccess(this.toast!.successTpl)
                return this.loadBalanceService.getReaminingFactorTime(this.user.uid)
              })
            );
          } else {
            return of('Not a family tutor');
          }
        })
      ).subscribe((res) => {
        let time = parseInt(res.remainingFactorTime);
        this.loadBalanceService.setTimer(time);
        this.timerSubscription = timer(0, 1000).pipe(
          map((seconds: number): number => (time ?? 0) - seconds), // Calcola il tempo rimanente
          takeWhile((remainingTime: number) => remainingTime > 0), // Continua finché il tempo è > 0
          finalize(() => {
            this.loadBalanceService.deactivateBoost(this.user.uid).subscribe(() => {
              console.log("test")
              this.toastService.setAction("Boost terminato, non avrai più richieste in eccesso da gestire.")
              this.toast!.showDanger(this.toast!.successTpl)
              this.isBoostActive = false;
              this.loadBalanceService.setTimer(null); // Imposta il timer a null al termine
            });
          })
        ).subscribe((remainingTime: number) => {
          this.cooldown = remainingTime;
          this.loadBalanceService.setTimer(remainingTime);
        })
        return res
      });
    } else {
      // Disattivazione boost
      this.loadBalanceService.deactivateBoost(this.user.uid).subscribe((res) => {
        this.isBoostActive = false;
        this.loadBalanceService.setTimer(null);
        // Annulla il timer se è attivo
        if (this.timerSubscription) {
          this.timerSubscription.unsubscribe();
          this.timerSubscription = null;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}


