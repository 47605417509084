<div *ngIf="caregiver" class="card cardFalse" id="caregiver-card" [ngClass]="{
  '': isMatch == true,
  'cardFalse': isMatch === false,
  'cardEngaged' : isEngaged === true
}">
  <div class="container card-body" (click)="isAddingCaregiver ? select() : undefined"
    [ngClass]="{'selectCard': selected}">
    <div class="row">
      <div class="col-4">
        <div class="col-12 justify-content-center position-relative">
          <app-compatibility-index [compatibility]="caregiver.fittingIndex" size="large" class="position-absolute"
            style="left: 0px;"></app-compatibility-index>
          <div class="rounded-circle overflow-hidden img-container position-absolute">
            <img [src]="
              caregiver.photoURL === '' || caregiver.photoURL === null
                ? '/assets/default.jpeg'
                : caregiver.photoURL
            " alt="Profile Picture" class="img-fluid" />
          </div>
        </div>
        <div class="mt-3 mb-1 d-flex">
          <p class="card-text text-match">
            {{ (caregiver.fittingIndex * 100).toFixed(0) }}% - {{caregiverAge}} anni
          </p>
        </div>
      </div>
      <div class="col-8 mt-2">
        <div class="col-12 mb-2 border-bottom">
          <div class="mt-4 d-flex row">
            <div class="col-8">
              <h5 class="card-title" [ngbTooltip]="caregiver.name + ' ' + caregiver.surname">
                {{ caregiver.name + " " + caregiver.surname }}
              </h5>
            </div>
            <div class="col-4">
              <div>
                <i *ngIf="!isAddingCaregiver" [ngClass]="{
              'bi-heart': isPreferred === false,
              'bi-heart-fill': isPreferred === true
            }" (click)="setPreferred()" class="bi bi-heart likeIcon"></i>
                <a [href]="caregiver.profileURL" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                    class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-3 d-flex">
          <div class="row mb-2">
            <p class="card-text">
              <small class="text-contact phoneNumber" (click)="copyToClipboard()"><i class="bi bi-telephone-fill"></i>
                {{
                caregiver.phone }}</small>
          </div>
        @if(caregiver.discarded && (!!cvSent || !!contactSent)) {
          <i [ngbTooltip]='"Già presentata alla famiglia"' class="bi bi-exclamation-triangle-fill ps-2 color-danger-euro"></i>
        }
        </div>
      </div>
    </div>
    <div class="row mt-3 align-items-center">
      <div class="col-5 iconsContainer align-items-center gap-1 d-flex">
        <a [href]="caregiver.cvURL !== '' ? caregiver.cvURL : 'javascript:void(0)'"
          [ngbTooltip]="caregiver.cvURL === '' ? 'CV non presente' : 'CV presente'" placement="top" target="_blank">
          <i [ngClass]="{'color-success': caregiver.cvURL && caregiver.cvURL !== '', 'color-danger': caregiver.cvURL === ''}"
            class="bi bi-file-earmark-text"></i>
        </a>
        <i [ngClass]="{'color-success-euro': caregiver.subscriptionActive === true, 'color-danger-euro': caregiver.subscriptionActive === false}"
          class="bi bi-currency-euro"
          [ngbTooltip]="caregiver.subscriptionActive === false ? 'Non abbonato' : 'Abbonato'" placement="top"></i>
        <i [ngClass]="{'color-success-car': haveCar === true, 'color-danger-car': haveCar === false }"
          class="bi bi-car-front" [ngbTooltip]="haveCar === false ? 'Non automunita' : 'Automunita'"
          placement="top"></i>
        <span>
          <i [ngClass]="{'color-success-euro': cvSent === true, 'color-danger-euro': cvSent === false }"
            class="bi bi-file-earmark-arrow-up-fill" placement="top"
            [ngbTooltip]="cvSentDateTxt ? 'CV inviato il ' + cvSentDateTxt : 'CV non ancora inviato'"></i>
        </span>
        <span>
          <i [ngClass]="{'color-success-euro': contactSent === true, 'color-danger-euro': contactSent === false}"
            class="bi bi-envelope-check" placement="top"
            [ngbTooltip]="contactSentDateTxt ? 'Contatto inviato il ' + contactSentDateTxt : 'Contatto non ancora inviato'"></i>
        </span>
      </div>
      <div class="col-3 geoContainer">
        <p class="card-text text-center">
          <small class="text-distance text-center"><i class="bi bi-geo-alt-fill"></i>
            {{ caregiver.distance | caregiverCardDistance }}</small>
        </p>
      </div>
      <div class="col-4">
        <p class="card-text text-center">
          <small class="text-distance text-center"><i class="bi bi-calendar-check-fill pe-1"></i><span
              ngbTooltip="Data di candidatura" placement="bottom">
              {{ caregiver.applicationDate | caregiverCardDate }}</span></small>
        </p>
      </div>
    </div>
  </div>
</div>
