import { GoogleMapsService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import html2canvas from 'html2canvas';
import { CertificateService, Colf, ModalService, ProvinceFilterComponent } from 'npx-family-happy-common';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { RequestService } from 'src/app/requests-handling/request-service/request.service';
import { CaregiverEvaluationRecord } from 'src/app/shared/models/caregiver-evaluation-record.model';
import { CaregiverEvaluation, DEFAULT_CAREGIVER_EVALUATION } from 'src/app/shared/models/caregiver-evaluation.model';
import { EvaluationService } from 'src/app/shared/services/evaluation-service/evaluation.service';
import { ColfState, RouterStateManagerService } from 'src/app/shared/services/router-state-manager/router-state-manager.service';
import { environment } from 'src/environments/environment';
import { ColfService } from '../colf-service/colf-service.service';
import { CaregiverEngagementComponent } from 'src/app/shared/components/caregiver-engagement/caregiver-engagement.component';
import { CaregiverEvaluationComponent } from 'src/app/shared/components/caregiver-evaluation/caregiver-evaluation.component';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { CvColfTemplateComponent } from '../cv-colf-template/cv-colf-template.component';
import { DEFAULT_CAREGIVER_ENGAGEMENT } from 'src/app/shared/models/caregiver-engagement.model';

@Component({
  selector: 'app-colf-detail',
  templateUrl: './colf-detail.component.html',
  styleUrls: ['./colf-detail.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ProvinceFilterComponent, CaregiverEvaluationComponent, CaregiverEngagementComponent,
    AlertComponent, ImageCropperComponent, CvColfTemplateComponent
  ]
})
export class ColfDetailComponent implements OnInit {

  isNew = true;
  hasPayed!: boolean;
  addressSelected: boolean = false;
  showAlert = false;
  showModal = false;
  chosenPhoto!: string;
  finalPhoto!: string;
  step = 0;
  form!: UntypedFormGroup;
  colf!: Colf;
  colfID!: string;
  generatePages: boolean = false;
  cvPages: number = 1;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];
  checkNotRated = true;
  private nextTab = -1;
  private colfState!: ColfState;
  private unsubscribe = new Subject<void>();
  private caregiverRating: CaregiverEvaluation | undefined;
  private currentUser: string = '';
  private goBackRequested = false
  private oldAddress!: string | undefined

  //default option for NEW COLF
  defaultRating = DEFAULT_CAREGIVER_EVALUATION
  defaultEngagement = DEFAULT_CAREGIVER_ENGAGEMENT

  constructor(private certificateService: CertificateService, private caregiverEvaluationService: EvaluationService,
    private colfService: ColfService, private stateManager: RouterStateManagerService,
    private sanitizer: DomSanitizer, private modalService: ModalService, private requestService: RequestService, private router: Router,
    private activeRoute: ActivatedRoute, private datePipe: DatePipe, private userStorage: UserStorageService, private googleMapsService: GoogleMapsService) {
    this.finalPhoto = '';
  }

  ngOnInit(): void {
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.currentUser = user?.name ?? '';
    })
    this.form = new UntypedFormGroup({
      info: new UntypedFormGroup({
        name: new UntypedFormControl('', Validators.required),
        surname: new UntypedFormControl('', Validators.required),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        phone: new UntypedFormControl(null, Validators.required),
        address: new UntypedFormControl(''),
        city: new UntypedFormControl(''),
        zone: new UntypedFormControl(''),
        cap: new UntypedFormControl('', [Validators.pattern(new RegExp('^\\d{5}$'))]),
        age: new UntypedFormControl(0),
        birthDate: new UntypedFormControl(''),
        provincia: new UntypedFormControl('TO'),
        fiscalcode: new UntypedFormControl('', [Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
        iban: new UntypedFormControl(''),
        resume: new UntypedFormControl(''),
        resumeHidden: new UntypedFormControl(''),
        video: new UntypedFormControl(''),
        notes: new UntypedFormControl(''),
        description: new UntypedFormControl(''),
        photo: new UntypedFormControl(''),
        subscription: new UntypedFormControl(''),
        manualSubscription: new UntypedFormControl(''),
        expDate: new UntypedFormControl(''),
        expDateManual: new UntypedFormControl(''),
        stopWhatsapp: new UntypedFormControl(false),
        latitude: new UntypedFormControl(null),
        longitude: new UntypedFormControl(null)
      }),
      exp: new UntypedFormGroup({
        employer: new UntypedFormControl(''),
        total: new UntypedFormControl(0),
      }),
      lang: new UntypedFormGroup({
        title: new UntypedFormControl(''),
        graduationType: new UntypedFormControl(''),
        english: new UntypedFormControl(''),
        // french: new FormControl(''),
        italian: new UntypedFormControl(''),
        // german: new FormControl(''),
        spanish: new UntypedFormControl(''),
        other1: new UntypedFormControl(''),
        other2: new UntypedFormControl(''),
      }),
      reference: new UntypedFormGroup({
        f_surname: new UntypedFormControl(''),
        f_second_surname: new UntypedFormControl('')
      }),
      avail: new UntypedFormGroup({
        car: new UntypedFormControl(false),
        contract: new UntypedFormGroup({
          occ: new UntypedFormControl(false),
          part: new UntypedFormControl(false),
          full: new UntypedFormControl(false),
        }),
        cooking: new UntypedFormControl(false),
        days: new UntypedFormGroup({
          sun: new UntypedFormControl(false),
          mon: new UntypedFormControl(false),
          tue: new UntypedFormControl(false),
          wed: new UntypedFormControl(false),
          thu: new UntypedFormControl(false),
          fri: new UntypedFormControl(false),
          sat: new UntypedFormControl(false),
        }),
        gardening: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        groceries: new UntypedFormControl(false),
        helping: new UntypedFormControl(false),
        hours: new UntypedFormGroup({
          mor: new UntypedFormControl(false),
          aft: new UntypedFormControl(false),
          eve: new UntypedFormControl(false),
        }),
        houseCleaning: new UntypedFormControl(false),
        ironing: new UntypedFormControl(false),
        license: new UntypedFormControl(false),
        sewing: new UntypedFormControl(false),
        smoker: new UntypedFormControl(false),
        terraceCleaning: new UntypedFormControl(false),
        washingMachines: new UntypedFormControl(false),
        windowCleaning: new UntypedFormControl(false),
      })
    });
    this.activeRoute.params.subscribe(param => {
      this.colfID = param.id;
    })
    try {
      this.stateManager.getColfState().pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
        this.colfState = state;
        this.colfState.id = this.colfID;
        this.colfState.route = 'colf-detail/' + this.colfID;
        this.oldAddress = state.colf?.info.address //GET OLD ADDRESS FROM BE (TO COMPARE WITH ADDRESS ON FORM)
        if (this.colfState.step >= 0) {
          this.step = this.colfState.step;
        }
        if (this.colfState.colf) {
          this.isNew = false;
          this.setColf(this.colfState.colf);
          this.formInitialize(this.colfState.colf);
        } else if (!this.colfID.match('null')) {
          const id = this.colfID;
          if (id && id !== 'new') {
            this.isNew = false;
            this.colfService.getSingleColf(id).pipe(takeUntil(this.unsubscribe)).subscribe((colfs) => {
              let colf = colfs[0];
              if (colf) {
                this.setColf(colf);
                this.formInitialize(this.colf);
              }
            });
          }
        }
      });
    } catch (err) {
      this.modalService.showErrorMessage('Errore nel recupero delle informazioni legate alla badante.');
    }

    this.form.get('lang.title')?.valueChanges.subscribe(value => {
      if (value === 'Laurea') {
        this.form.get('lang.graduationType')?.enable({ emitEvent: false });
      } else {
        this.form.get('lang.graduationType')?.disable({ emitEvent: false });
      }
    });

    /* Reset exp clusters when value is 0 */
    /* Listen to every cluster's expYears value changes and then compute the total exp according to the formula */
    this.form.get('exp.cluster1.expYears')?.setValue(0);
    this.form.get('exp.cluster2.expYears')?.setValue(0);
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;

    if (inputAddress) {
      inputAddress.addEventListener('change', () => {
        inputAddress.value = '';
        this.form.get('info.address')?.setValue('', { emitEvent: false });
      });
      const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {

        const autocomplete = new google.maps.places.Autocomplete(inputAddress);

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          let formAddress = this.form.get('info.address');

          formAddress?.setValue(place.formatted_address);

          // Compile other address fields according to place object
          place.address_components?.forEach((component: any) => {
            if (component.types.includes('locality')) {
              this.form.get('info.city')?.setValue(component.long_name);
            }
            if (component.types.includes('postal_code')) {
              this.form.get('info.cap')?.setValue(component.long_name);
            }
          });
        })
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stateManager.setColfState(this.colfState, false);
    this.processFormData(this.form.value).then((res) => {
      this.setColf(res);
    })
  }

  uploadFile(_event: any, field: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento CV in corso...');
      const file = <File>_event.target.files[0];
      const path = field + '/' + field + this.form.get('info.email')?.value + '.pdf';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (field === 'info.resume') {
            this.colf.info.resume = downloadURL;
          } else {
            this.colf.info.resumeHidden = downloadURL;
          }
          this.form.get(field)?.setValue(downloadURL);
          if (this.isNew) {
            this.createColf();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updateColf();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel caricamento del CV. Si prega di riprovare');
    }
  }

  uploadImage(image: string, id: string) {
    try {
      this.modalService.showLoadingWithMessage('Caricamento foto in corso...');
      const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
      const path = 'pictures/' + id + '.png';
      let storageRef = ref(getStorage(), path);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on('state_changed', () => { }, () => { }, () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          this.form.get('info.photo')?.setValue(downloadURL);
          let body = this.colf.info;
          body.photo = downloadURL;
          this.colf.info = body;
          if (this.isNew) {
            this.createColf();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          } else {
            this.updateColf();
            this.modalService.hideLoading();
            this.modalService.showSuccessMessage('Caricamento riuscito.');
          }
        });
      })
    } catch (err) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nel corso del caricamento della foto. Si prega di riprovare.');
    }
    this.showModal = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async generateCV(showAlert: boolean) {
    console.log("trigger")
    if (this.form.invalid) {
      console.log("trigger first if")
      return
    }
    if (showAlert) {
      this.modalService.showLoadingWithMessage('Generazione CV Family Happy in corso...');
    }
    try {
      this.generatePages = true;
      setTimeout(() => {
        this.htmlToCanvas(showAlert)
      }, 1000);
    } catch (err: any) {
      this.modalService.hideLoading();
      this.modalService.showErrorMessage('Errore nella generazione del CV. Potrebbe essere legato alla foto caricata. Si prega di scaricarla, caricarla nuovamente e riprovare.');
    }
  }

  goBack() {
    if (this.form.dirty) {
      this.showAlert = true;
      this.goBackRequested = true;
    } else {
      this.colfState.route = 'colf-list';
      this.colfState.colf = undefined;
      this.colfState.step = 0;
      this.colfState.id = 'null';
      this.ngOnDestroy();
      this.stateManager.setColfState(this.colfState);
    }
  }

  formInitialize(colf: Colf) {
    this.resetAllFormArrays();
    if (colf) {
      this.form.setValue({
        info: {
          ...colf.info,
          expDate: colf.info.expDate ? this.datePipe.transform(colf.info.expDate, 'yyyy-MM-dd') : '',
          expDateManual: colf.info.expDateManual ? this.datePipe.transform(colf.info.expDateManual, 'yyyy-MM-dd') : ''
        },
        exp: {
          total: colf.exp.total,
          employer: colf.exp.employer,
        },
        lang: {
          ...colf.lang,
          // certificates: ['']
        },
        reference: {
          f_surname: colf.reference ? colf.reference.f_surname : '',
          f_second_surname: colf.reference ? colf.reference.f_second_surname : ''
        },
        avail: {
          ...colf.avail,
          days: this.colfService.fromStringToAvailObject(colf.avail.days, 'days'),
          hours: this.colfService.fromStringToAvailObject(colf.avail.hours, 'hours'),
          contract: this.colfService.fromStringToAvailObject(colf.avail.contract, 'contract')
        }
      }, { emitEvent: false });

      // this.setCertificatesLenght(Colf.certificates.colfCertificateList);
    }

    if (this.form.get('rating.notRated')?.value === true && this.checkNotRated) {
      this.form.get('rating.overall')?.valueChanges.subscribe((overall) => {
        if (overall > 0) {
          this.form.get('rating.notRated')?.setValue(false);
        } else {
          this.form.get('rating.notRated')?.setValue(true);
        }
      })
      this.checkNotRated = false;
    } else {
      this.checkNotRated = false;
    }
  }

  save(showMessage?: boolean) {
    if (this.form.valid) {
      if (this.isNew) {
        this.createColf();
      } else {
        this.updateColf(showMessage);
      }
    }
  }

  changeTab(tab: number) {
    if (this.form.dirty) {
      this.showAlert = true;
      this.nextTab = tab;
    } else {
      this.step = tab;
      this.colfState.step = this.step;
    }
  }

  onAlertAction(save: boolean) {
    if (save && !this.isNew) {
      this.updateColf();
    } else if (save && this.isNew) {
      this.createColf();
    } else if (!save && this.goBackRequested) {
      if (this.colf === undefined) {
        this.form.markAsPristine();
        this.form.reset();
        this.goBack();
      } else {
        this.form.markAsPristine();
        this.form.reset();
        this.formInitialize(this.colf);
        this.goBack();
      }
    } else {
      this.form.markAsPristine();
      this.form.reset();
      this.formInitialize(this.colf);
      // this.goBack();
    }
    this.showAlert = false;
    if (this.nextTab !== -1) {
      this.step = this.nextTab;
      this.colfState.step = this.step;
      this.nextTab = -1;
    }
  }

  private async createColf() {
    this.colf = await this.processFormData(this.form.value);
    this.colfService.createColf(this.colf).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: any) => {
        this.modalService.showSuccessMessage('Nuova badante creata.');
        const regex = /#(\w{2}-\d{5})/gm;
        const result = regex.exec(res.message);
        if (result) {
          this.colfService.getSingleColf(result[1]).pipe(takeUntil(this.unsubscribe)).subscribe((colfs) => {
            let colf = colfs[0];
            if (colf) {
              this.isNew = !this.isNew;
              this.colf = colf;
              this.form.markAsPristine();
              this.formInitialize(this.colf);
            }
          });
        }
      },
      error: (error) => {
        if ((error.error as string).match('Key .* already exists.')) {
          this.modalService.showErrorMessage('Esiste già un profilo associato all\'indirizzo email fornito.');
        } else {
          this.modalService.showErrorMessage('Si è verificato un errore nella creazione della badante, si prega di riprovare.');
        }
      }
    });
  }

  private async updateColf(showMessage?: boolean) {
    this.colf = await this.processFormData(this.form.value);
    this.colfService.updateColf(this.colf).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: () => {
        if (showMessage) {
          this.modalService.showSuccessMessage('Badante modificata.');
        }
        this.publishNewCaregiverScoreUpdate();
        // this.requestService.updateSummary(this.Colf).subscribe(); ///TO CHECK
        this.form.markAsPristine();
        this.formInitialize(this.colf);
        this.generateCV(false);
      },
      error: (error) => {
        console.log(error);
        this.modalService.showErrorMessage(`Si è verificato un errore nell'aggiornamento della badante.`);
      }
    });
  }

  private async processFormData(data: any) {
    let colf: Colf = {
      docRef: this.colf?.docRef ?? '',
      certificate: this.colf?.certificate ?? '',
      info: {
        ...data.info,
        age: +data.info.age > 0 ? +data.info.age : 1,
        expDate: data.info.expDate !== '' ? new Date(data.info.expDate) : null,
        expDateManual: data.info.expDateManual !== '' ? new Date(data.info.expDateManual) : null,
        stopWhatsapp: data.info.stopWhatsapp ?? false,
        latitude: data.info.latitude,
        longitude: data.info.longitude,
      },
      exp: {
        total: data.exp.total,
        employer: data.exp.employer,
      },
      reference: {
        f_surname: data.reference.f_surname,
        f_second_surname: data.reference.f_second_surname
      },
      lang: {
        ...data.lang,
      },
      avail: {
        ...data.avail,
        days: this.colfService.fromAvailObjectToString(data.avail.days, 'days'),
        hours: this.colfService.fromAvailObjectToString(data.avail.hours, 'hours'),
        contract: this.colfService.fromAvailObjectToString(data.avail.contract, 'contract')
      },
      rating: {
        ...this.caregiverRating ?? this.colf?.rating ??
        {
          overall: 0,
          punctuality: 0,
          empathy: 0,
          behave: 0,
          communication: 0,
          attitude: '',
          dangerous: false,
          notRated: false
        }
      },
      engagement: {
        ...this.colf?.engagement ?? {
          selected: 0,
          chosen: 0,
          discarded: 0,
          ignored: 0,
          substituted: 0,
          applications: 0,
          chosenDate: undefined,
          latestApplication: undefined
        }
      },
      certificates: {
        colfCertificateList: data.lang.certificates
      },
      updateTimestamp: this.colf ? this.colf.updateTimestamp : new Date()
    };

    //calculate coordinates from address
    if (data.info.address === '') {
      let coordinates = {
        latitude: data.info.latitude,
        longitude: data.info.longitude,
      }
      colf = {
        ...colf,
        info: {
          ...colf.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }
    } else {
      if (this.oldAddress !== data.info.address) {
        let coordinates = await this.getCoordiantes(colf)
        colf = {
          ...colf,
          info: {
            ...colf.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }

      } else {
        let coordinates = {
          latitude: data.info.latitude,
          longitude: data.info.longitude,
        }
        colf = {
          ...colf,
          info: {
            ...colf.info,
            latitude: coordinates.latitude,
            longitude: coordinates.longitude
          }
        }
      }
    }
    return colf;
  }

  private setColf(bb: Colf) {
    this.colf = bb;
    this.colfState.colf = this.colf;
    this.colfState.step = this.step;
  }

  openModal(event: any) {
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  dismiss(event: Event) {
    const elem = event.target as HTMLElement;
    if (elem === document.getElementById('modal-container')) {
      this.showModal = false;
    }
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
  }

  /* Experience form arrays methods */

  changeFormArrayLength(more: boolean, cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  resetFormArray(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    array.clear();
  }

  resetAllFormArrays() {
    // this.resetCertificates();
    // this.changeCertificatesLength(true);
  }

  getFormArrayControls(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`exp.cluster${cluster}.${field}`) as UntypedFormArray;

    return array.controls;
  }


  /* Certificates form arrays methods */

  // changeCertificatesLength(more: boolean) {
  //   let array: FormArray = this.form.get('lang.certificates') as FormArray;
  //   if (more) {
  //     array.push(new FormControl(''));
  //   } else {
  //     array.removeAt(array.length - 1);
  //   }
  // }

  // setCertificatesLenght(data?: string[]) {
  //   let array: FormArray = this.form.get('lang.certificates') as FormArray;

  //   if (data && data.length > 0) {
  //     array.clear();
  //     data!.forEach(data => array.push(new FormControl(data)));
  //   }
  // }

  // resetCertificates() {
  //   let array: FormArray = this.form.get('lang.certificates') as FormArray;

  //   array.clear();
  // }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('lang.certificates') as UntypedFormArray;

    return array.controls;
  }

  htmlToCanvas(showAlert: boolean) {
    console.log('Check pages:', this.currentPage, this.cvPages)
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;
      return;
    }
    const element = document.getElementById('cv-page-container') as HTMLElement;
    console.log('Element:', element);

    html2canvas(element, { allowTaint: true, useCORS: true, scale: 2, }).then(canvas => {
      this.canvases.push(canvas);
      console.log('Canvases available:', this.canvases);
      if (this.currentPage === this.cvPages - 1) {
        this.certificateService.createColfPDFCVFromHTML(this.canvases, this.colf, this.isNew, showAlert,
          this.stateManager, environment.colfServiceURL, environment.requestServiceURL);
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas(showAlert) }, 1000);
    })
  }

  cvPreview() {
    if (!this.generatePages) {
      this.router.navigate(['colf-detail', this.colf.docRef, 'cv'], { state: { data: this.colf } });
    }
  }

  openCertificatePage() {
    /* const config: FillCertificateConfig = {
      nurse: this.babySitter,
      stateManager: this.stateManager,
      isProduction: environment.production,
      mailServiceURL: environment.mailServiceURL,
      babysitterApiURL: environment.babysitterServiceURL,
      showModal: true
    }
    this.certificateService.setConfig(config);
    debugger;
    this.router.navigate(['nurse-detail', this.babySitter.docRef, 'fill-certificate']); */
  }

  computeTotalExp() {
    let value = this.form.get('exp')?.value;
    let expSum = value.cluster1.expYears + value.cluster2.expYears;
    let age = this.form.get('info.age')?.value;
    this.form.get('exp.total')?.setValue(Math.max(value.cluster1.expYears, value.cluster2.expYears, Math.min(expSum, (age - 16))), { emitEvent: false });
    this.form.get('exp.total')?.markAsDirty();
    this.form.get('exp.total')?.markAsTouched();
  }

  onCaregiverEvaluationChanged(newCaregiverEvaluation: CaregiverEvaluation) {
    this.caregiverRating = newCaregiverEvaluation;
    this.form.markAsTouched();
    this.form.markAsDirty();
  }

  updateProvincia(newValue: string) {
    this.form.get('info.provincia')?.setValue(newValue);
    this.form.markAllAsTouched();
    this.form.get('info.provincia')?.markAsDirty();
  }

  private publishNewCaregiverScoreUpdate() {
    if (this.caregiverRating) {
      // Insert code to save new caregiver evaluation in the database
      const record: CaregiverEvaluationRecord = {
        ...this.caregiverRating,
        id: null,
        docRef: this.colf.docRef,
        operator: this.currentUser,
        timestamp: new Date()
      }
      this.caregiverEvaluationService.addRecord(record).subscribe((response: CaregiverEvaluationRecord) => console.log(record))
      this.caregiverRating = undefined;
    }
  }

  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Colf) {
    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }

}
