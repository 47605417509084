<div class="fh-form-section" [formGroup]="form">
  <fieldset>
    <legend>Dati richiesta</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="idrichiesta">ID richiesta</label>
        <input type="number" id="id" formControlName="id" readonly>
      </div>
      <div class="fh-form-input-field">
        <div style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
          <label class="fw-semibold" for="nomeoperatore">Nome operatore</label>
          <button *ngIf="showOperatorSwap" type="button" class="link-button"
            style="justify-self: end; padding: 5px 10px;" (click)="operatorSwap()">Prendi in carico</button>
        </div>
        <input type="text" id="nomeoperatore" readonly formControlName="operator">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="requestCategory">Business Line</label>
        <select id="requestCategory" formControlName="requestCategory">
          <option default value="BABYSITTER">Babysitter</option>
          <option value="PETSITTER">Petsitter</option>
          <option value="NURSE">Badanti</option>
          <option value="COLF">Colf</option>
        </select>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="customer">Tipo Cliente</label>
        <select id="customer" formControlName="customer">
          <option default value=""> - </option>
          <option *ngFor="let option of customerTypesAvailable" value="{{option}}">{{option}}</option>
        </select>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="service">Servizio</label>
        <select id="service" formControlName="service" (change)="selectService($event)">
          <option default value=""> - </option>
          <option *ngFor="let option of servicesAvailable" value="{{option.name}}">{{option.name}}</option>
        </select>
      </div>

      <div class="fh-form-input-field">
        <label class="fw-semibold" for="provincia">Provincia di interesse</label>
        <app-province-filter [provincia]="form.get('provincia')?.value"
          (valueChange)="updateProvincia($event)"></app-province-filter>
      </div>

      <div class="fh-form-input-field">
        <label class="fw-semibold" for="contactType">Tipo contatto</label>
        <select id="contactType" formControlName="contactType">
          <option>Preventivo</option>
          <option>Lead</option>
          <option>Telefonata</option>
          <option>WhatsApp</option>
          <option>SMS</option>
          <option>Email</option>
        </select>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="datatelefonata">Data e ora prima telefonata</label>
        <input type="datetime-local" id="datatelefonata" formControlName="phoneDate">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="datacontatto">Data e ora contatto</label>
        <input type="datetime-local" id="data" formControlName="contactDate">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="contactPreference">Preferenza contatto</label>
        <input type="text" id="contactPreference" formControlName="contactPreference">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="contactHours">Fascia oraria per comunicazioni</label>
        <input type="text" id="contactHours" formControlName="contactHours">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="referral">Come ci hai conosciuto</label>
        <input type="text" id="referral" formControlName="referral">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="company">Azienda</label>
        <input type="text" id="company" formControlName="company">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="companySize">Dimensione Azienda</label>
        <select formControlName="companySize" id="companySize">
          <option value="">Scegli un'opzione</option>
          <option value="EXTRA_SMALL">1-10 dipendenti</option>
          <option value="SMALL">11-50 dipendenti</option>
          <option value="MEDIUM">51-200 dipendenti</option>
          <option value="LARGE">200+ dipendenti</option>
        </select>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="welfareCompany">Compagnia Welfare</label>
        <input type="text" id="welfareCompany" formControlName="welfareCompany">
      </div>
    </div>
  </fieldset>

  <fieldset>
    <legend>Dati genitore</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="nomegenitore">Nome genitore</label>
        <input type="text" id="nomegenitore" formControlName="parentName">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="cognomegenitore">Cognome genitore</label>
        <input type="text" id="cognomegenitore" formControlName="parentSurname">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="emailgenitore">Email genitore</label>
        <input type="text" id="emailgenitore" formControlName="parentEmail">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="telefonogenitore">Telefono genitore</label>
        <input type="tel" id="telefonogenitore" formControlName="parentPhone">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="indirizzogenitore">Indirizzo genitore</label>
        <input type="text" id="indirizzogenitore" formControlName="parentAddress" autocomplete="none">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="cittagenitore">Città genitore</label>
        <input type="text" id="cittagenitore" formControlName="parentCity">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="zonagenitore">Zona genitore</label>
        <input type="text" id="zonagenitore" formControlName="parentZone">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="capgenitore">CAP genitore</label>
        <input type="text" id="capgenitore" formControlName="parentCap">
      </div>
      <div class="fh-form-input-field">
        <label for="subscription" class="fw-semibold">Stato Abbonamento</label>
        <input type="text" formControlName="subscription" id="subscription" readonly>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="expDate">Scadenza Abbonamento</label>
        <input type="date" id="expDate" formControlName="expDate" readonly>
      </div>
      <div class="fh-form-input-field">
        <label for="manualSubscription" class="fw-semibold">Stato Abbonamento Manuale</label>
        <select formControlName="manualSubscription" id="manualSubscription">
          <option value="">Scegli un'opzione</option>
          <option value="ABBONATO">ABBONATO</option>
          <option value="EX ABBONATO">EX ABBONATO</option>
        </select>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="manualExpDate">Scadenza Abbonamento Manuale</label>
        <input type="date" id="manualExpDate" formControlName="manualExpDate">
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="parentFiscalCode">Codice Fiscale genitore</label>
        <input type="text" id="parentFiscalCode" formControlName="parentFiscalCode">
      </div>
    </div>
  </fieldset>

  <div class="fh-form-input-field">
    <label class="fw-semibold" for="message">Prima richiesta e appunti telefonata</label>
    <textarea style="margin-top: 20px;width: 100%;border-radius: 10px; padding: 10px;" rows=10 maxlength="2048"
      formControlName="message"></textarea>
  </div>
</div>
