import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { add } from 'cypress/types/lodash';
import {
  Babysitter,
  Colf,
  Nurse,
  Petsitter,
} from 'npx-family-happy-common';
import {
  Subject,
  Subscription,
  debounceTime,
  takeUntil,
} from 'rxjs';
import { BabySitterService } from 'src/app/caregiver/babysitter/baby-sitter-service/baby-sitter-service.service';
import { ColfService } from 'src/app/caregiver/colf/colf-service/colf-service.service';
import { NurseService } from 'src/app/caregiver/nurse/nurse-service/nurse-service.service';
import { PetsitterService } from 'src/app/caregiver/petsitter/petsitter-service/petsitter-service.service';
import { CaregiverCardComponent } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-card/caregiver-card.component';
import { CaregiverSelectionService } from 'src/app/requests-handling/caregiver-scoreboard/caregiver-selection/caregiver-selection-service/caregiver-selection.service';
import { CaregiverSelectionRecord } from 'src/app/shared/models/caregiverSelectionRecord.model';
import { CaregiverSelectionRecordSortable } from 'src/app/shared/models/caregiverSelectionRecordSortable.models';

@Component({
  selector: 'app-select-caregiver-modal',
  templateUrl: './select-caregiver-modal.component.html',
  styleUrls: ['./select-caregiver-modal.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, CaregiverCardComponent]
})
export class SelectCaregiverModalComponent implements OnInit, OnDestroy {
  closeResult = '';
  message = 'Cerca caregiver per nome e cognome';
  title = 'Nuova candidatura';
  isAddingCaregiver = true;
  favorite: boolean = false

  showSuitableSearchBar = false;
  caregiverSearchText = new UntypedFormControl('');
  private unsubscribe = new Subject<void>();
  babysitterSearchResults: Babysitter[] = []
  petsitterSearchResults: Petsitter[] = []
  nurserSearchResults: Nurse[] = []
  colfrSearchResults: Colf[] = []
  records: CaregiverSelectionRecordSortable[] = [];

  babysitterSuitable: Babysitter[] = []
  newBabysitterSuitable: Babysitter[] = []
  babysitterSuitableObs = new Subscription();
  babysitterSuitableSubject = new Subject<Babysitter>();

  petsitterSuitable: Petsitter[] = []
  newPetsitterSuitable: Petsitter[] = []
  petsitterSuitableObs = new Subscription();
  petsitterSuitableSubject = new Subject<Petsitter>();

  nurseSuitable: Nurse[] = []
  newNurseSuitable: Nurse[] = []
  nurseSuitableObs = new Subscription();
  nurseSuitableSubject = new Subject<Nurse>();

  colfSuitable: Colf[] = []
  newColfSuitable: Colf[] = []
  colfSuitableObs = new Subscription();
  colfSuitableSubject = new Subject<Colf>();


  selectedCaregiver: CaregiverSelectionRecordSortable[] = [];


  @Input() modal!: any;
  @Input() requestCategory!: string;
  @Input() provincia!: string;
  @Input() requestID!: number;
  @Output() cardsUpdated = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private babysitterService: BabySitterService,
    private petsitterService: PetsitterService,
    private nurseService: NurseService,
    private colfService: ColfService,
    private caregiverSelectionService: CaregiverSelectionService
  ) {
    this.babysitterSuitableObs = this.babysitterSuitableSubject
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((caregiver) => {
        this.babysitterSuitable.push(caregiver)
      });
    this.petsitterSuitableObs = this.petsitterSuitableSubject
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((caregiver) => {
        this.petsitterSuitable.push(caregiver)
      });
    this.nurseSuitableObs = this.nurseSuitableSubject
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((caregiver) => {
        this.nurseSuitable.push(caregiver)
      });
    this.colfSuitableObs = this.colfSuitableSubject
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((caregiver) => {
        this.colfSuitable.push(caregiver)
      });
  }
  ngOnInit(): void {
    this.caregiverSearchText.valueChanges
      .pipe(debounceTime(300), takeUntil(this.unsubscribe))
      .subscribe((searchText) => {
        if (this.requestCategory === 'BABYSITTER') {
          this.babysitterService
            .getFiveSuggestions(searchText, this.provincia)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((searchResults) =>
              this.handleSuggestionsBB(searchResults)
            );
        } else if (this.requestCategory === 'PETSITTER') {
          this.petsitterService
            .getFiveSuggestions(searchText, this.provincia)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((searchResults) =>
              this.handleSuggestionsPET(searchResults) //OK
            );
        } else if (this.requestCategory === 'NURSE') {
          this.nurseService
            .getFiveSuggestions(searchText, this.provincia)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((searchResults) =>
              this.handleSuggestionsNURSE(searchResults)
            );
        } else if (this.requestCategory === 'COLF') {
          this.colfService
            .getFiveSuggestions(searchText, this.provincia)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((searchResults) =>
              this.handleSuggestionsCOLF(searchResults)
            );
        }
      });
  }

  private handleSuggestionsPET(searchResults: Petsitter[]) {
    let caregiverLinked = [...this.petsitterSuitable];
    let caregivers = searchResults
    this.records = []
    this.petsitterSearchResults = searchResults
      .filter(
        (caregiver) =>
          !caregiverLinked.some((bb) => bb.docRef === caregiver.docRef)
      );
    caregivers.forEach((element) => {
      this.addPetsitterSuitable(element)
    });
  }

  private handleSuggestionsBB(searchResults: Babysitter[]) {
    let caregiverLinked = [...this.babysitterSuitable];
    let caregivers = searchResults
    this.records = []
    this.babysitterSearchResults = searchResults
      .filter(
        (caregiver) =>
          !caregiverLinked.some((bb) => bb.docRef === caregiver.docRef)
      );
    caregivers.forEach((element) => {
      this.addBabysitterSuitable(element)
    });
  }

  private handleSuggestionsNURSE(
    searchResults: Nurse[]
  ) {
    let caregiverLinked = [...this.nurseSuitable];
    let caregivers = searchResults
    this.records = []
    this.nurserSearchResults = searchResults
      .filter(
        (caregiver) =>
          !caregiverLinked.some((bb) => bb.docRef === caregiver.docRef)
      );
    caregivers.forEach((element) => {
      this.addNurseSuitable(element)
    });
  }

  private handleSuggestionsCOLF(
    searchResults: Colf[]
  ) {
    let caregiverLinked = [...this.nurseSuitable];
    let caregivers = searchResults
    this.records = []
    this.colfrSearchResults = searchResults
      .filter(
        (caregiver) =>
          !caregiverLinked.some((bb) => bb.docRef === caregiver.docRef)
      );
    caregivers.forEach((element) => {
      this.addColfSuitable(element)
    });
  }


  addBabysitterSuitable(caregiver: Babysitter) {
    if (!this.babysitterSuitable) {
      this.babysitterSuitable = [];
    }
    this.babysitterSuitable = [];
    if (
      this.babysitterSuitable.findIndex(
        (item) => item.docRef === caregiver.docRef
      ) === -1
    ) {
      this.babysitterSuitableSubject.next(caregiver)
    }
    if (
      !this.newBabysitterSuitable.some((bb) => bb.docRef === caregiver.docRef)
    ) {
      this.babysitterSuitableSubject.next(caregiver)
    }
    this.initCaregiverRecord(caregiver)
  }


  addPetsitterSuitable(caregiver: Petsitter) {
    if (!this.petsitterSuitable) {
      this.petsitterSuitable = [];
    }
    this.petsitterSuitable = [];
    if (this.petsitterSuitable.findIndex((item) => item.docRef === caregiver.docRef) === -1) {
      this.petsitterSuitableSubject.next(caregiver)
      if (
        !this.newPetsitterSuitable.some((bb) => bb.docRef === caregiver.docRef)
      ) {
        this.petsitterSuitableSubject.next(caregiver)
      }
      this.initPetsitterRecord(caregiver)
    }
  }

  addNurseSuitable(caregiver: Nurse) {
    if (!this.nurseSuitable) {
      this.nurseSuitable = [];
    }
    this.nurseSuitable = [];
    if (
      this.nurseSuitable.findIndex(
        (item) => item.docRef === caregiver.docRef
      ) === -1
    ) {
      this.nurseSuitableSubject.next(caregiver)
      if (
        !this.newNurseSuitable.some((bb) => bb.docRef === caregiver.docRef)
      ) {

        this.nurseSuitableSubject.next(caregiver)
      }
      this.initNurseRecord(caregiver)
    }
  }

  addColfSuitable(caregiver: Colf) {
    if (!this.colfSuitable) {
      this.colfSuitable = [];
    }
    this.colfSuitable = [];
    if (
      this.colfSuitable.findIndex(
        (item) => item.docRef === caregiver.docRef
      ) === -1
    ) {
      this.colfSuitableSubject.next(caregiver)
      if (
        !this.newColfSuitable.some((bb) => bb.docRef === caregiver.docRef)
      ) {

        this.colfSuitableSubject.next(caregiver)
      }
      this.initColfRecord(caregiver)
    }
  }

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }
  closeSearchBar() {
    this.caregiverSearchText.reset();
    this.showSuitableSearchBar = false;
  }
  initCaregiverRecord(caregiver: Babysitter) {
    let newRecordSortable: CaregiverSelectionRecordSortable = {
      record: {
        id: -1,
        requestID: this.requestID,
        babysitter: caregiver,
        petsitter: null,
        nurse: null,
        colf:null,
        suitable: true,
        suitableDate: new Date(),
        selected: false,
        selectedDate: null,
        chosen: false,
        chosenDate: null,
        discarded: false,
        discardedDate: null,
        discardedReason: '',
        favorite: this.favorite,
        cvSent: false,
        cvSentDate: null,
        contactSent: false,
        contactSentDate: null,
        distance: -1
      },
      // fittingIndex:Math.random() //to fix
      fittingIndex: 0
    }
    this.records.push(newRecordSortable)
  }

  initPetsitterRecord(caregiver: Petsitter) {
    let newRecordSortable: CaregiverSelectionRecordSortable = {
      record: {
        id: -1,
        requestID: this.requestID,
        babysitter: null,
        petsitter: caregiver,
        nurse: null,
        colf:null,
        suitable: true,
        suitableDate: new Date(),
        selected: false,
        selectedDate: null,
        chosen: false,
        chosenDate: null,
        discarded: false,
        discardedDate: null,
        discardedReason: '',
        favorite: this.favorite,
        cvSent: false,
        cvSentDate: null,
        contactSent: false,
        contactSentDate: null,
        distance: -1
      },
      // fittingIndex:Math.random() //to fix
      fittingIndex: 0
    }
    this.records.push(newRecordSortable)
  }

  initNurseRecord(caregiver: Nurse) {

    let newRecordSortable: CaregiverSelectionRecordSortable = {
      record: {
        id: -1,
        requestID: this.requestID,
        babysitter: null,
        petsitter: null,
        nurse: caregiver,
        colf:null,
        suitable: true,
        suitableDate: new Date(),
        selected: false,
        selectedDate: null,
        chosen: false,
        chosenDate: null,
        discarded: false,
        discardedDate: null,
        discardedReason: '',
        favorite: this.favorite,
        cvSent: false,
        cvSentDate: null,
        contactSent: false,
        contactSentDate: null,
        distance: -1
      },
      // fittingIndex:Math.random() //to fix
      fittingIndex: 0
    }
    this.records.push(newRecordSortable)
  }

  initColfRecord(caregiver: Colf) {

    let newRecordSortable: CaregiverSelectionRecordSortable = {
      record: {
        id: -1,
        requestID: this.requestID,
        babysitter: null,
        petsitter: null,
        nurse: null,
        colf: caregiver,
        suitable: true,
        suitableDate: new Date(),
        selected: false,
        selectedDate: null,
        chosen: false,
        chosenDate: null,
        discarded: false,
        discardedDate: null,
        discardedReason: '',
        favorite: this.favorite,
        cvSent: false,
        cvSentDate: null,
        contactSent: false,
        contactSentDate: null,
        distance: -1
      },
      // fittingIndex:Math.random() //to fix
      fittingIndex: 0
    }
    this.records.push(newRecordSortable)
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  pushSelectedRecord(recordToSave: CaregiverSelectionRecordSortable) {
    this.selectedCaregiver.push(recordToSave)
    let selectedCargiverFilter = this.selectedCaregiver.filter((elemento, indice, array) => {
      return array.indexOf(elemento) === indice
    })
    this.selectedCaregiver = selectedCargiverFilter
  }
  saveSelectedRecord() {
    this.selectedCaregiver.forEach((record) => {
      if (record.record.babysitter) {
        this.caregiverSelectionService
          .addBabysitterSelectionSuitable(record.record)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((record) => {
            this.cardsUpdated.emit()
            this.modal.close('Save click')
            return record
          });
      } else if (record.record.petsitter) {
        this.caregiverSelectionService
          .addPetsitterSelectionSuitable(record.record)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((record) => {
            this.cardsUpdated.emit()
            this.modal.close('Save click')
            return record
          });
      } else if (record.record.nurse) {
        this.caregiverSelectionService
          .addNurseSelectionSuitable(record.record)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((record) => {
            this.cardsUpdated.emit()
            this.modal.close('Save click')
            return record
          });
      } else if (record.record.colf) {
        this.caregiverSelectionService
          .addColfSelectionSuitable(record.record)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((record) => {
            this.cardsUpdated.emit()
            this.modal.close('Save click')
            return record
          });
      }
    })
  }
  sortOriginalRecord() {
    this.records.sort((a, b) => b.fittingIndex - a.fittingIndex);
  }
}

