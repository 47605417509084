import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-discarded-alert',
  templateUrl: './discarded-alert.component.html',
  styleUrls: ['./discarded-alert.component.scss'],
  standalone:true,
  imports:[CommonModule, NgbTooltipModule]
})
export class DiscardedAlertComponent implements OnInit {
  @Output() save = new EventEmitter<string[]>();
  @Output() close = new EventEmitter<boolean>(false);
  @Input() data: any;
  @Input() title!: string;
  @Input() message!: string;
  alert = false

  reasonsForm = new UntypedFormGroup({
    province: new UntypedFormControl(null)
  })

  discardedReasons = [
    { label: 'Logistica', reason: 'Non rispecchia le esigenze dei genitori' },
    { label: 'Età', reason: 'Età' },
    { label: 'Competenze', reason: 'Competenze' },
    { label: 'Comportamento', reason: 'Comportamento' },
    { label: 'Non si presenta ai colloqui, non risponde, ecc.', reason: 'Disciplina verso il FamilyTutor' },
    { label: 'Richiesta chiusa dal genitore', reason: 'Richiesta chiusa dal genitore' },
    { label: 'Si dichiara non disponibile', reason: 'Si dichiara non disponibile' },
  ];
  selectedValues:string[] = []

  constructor() { }

  ngOnInit(): void {
  }

  onChange(reason: string, event: Event): void {
    const target = event.target as HTMLInputElement | null;
    if (target !== null) {
      let isChecked = target.checked;
      if (isChecked) {
        this.alert = false
          this.selectedValues.push(reason);
      } else {
        const index = this.selectedValues.indexOf(reason);
        if (index > -1) {
          this.selectedValues.splice(index, 1);
        }
      }
    }
  }
  sendReasons(){
    if(this.selectedValues.length !== 0){
      this.save.emit(this.selectedValues)
    } else {
      this.alert = true
    }
  }
}
