import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

export enum familyMode {
  SERVIZIO = 'servizio',
  PACCHETTO = 'pacchetto ore',
  COSTO_PACCHETTO_FORFETTARIO = 'Pacchetto ore B2B'

}

@Component({
  selector: 'app-pricing-family-switch',
  standalone: true,
  imports: [CommonModule],
  template: `
<div class="mode-switch d-flex align-items-center">
  <button
    class="btn me-2"
    [ngClass]="{
      'bg-primary text-white': currentMode === Mode.SERVIZIO,
      'bg-disabled': currentMode !== Mode.SERVIZIO
    }"
    (click)="switchMode(Mode.SERVIZIO)">
    Costo servizio
  </button>
  <button
    class="btn me-2"
    [ngClass]="{
      'bg-primary text-white': currentMode === Mode.PACCHETTO,
      'bg-disabled': currentMode !== Mode.PACCHETTO
    }"
    (click)="switchMode(Mode.PACCHETTO)">
    Pacchetto ore
  </button>
  <button
    class="btn"
    [ngClass]="{
      'bg-primary text-white': currentMode === Mode.COSTO_PACCHETTO_FORFETTARIO,
      'bg-disabled': currentMode !== Mode.COSTO_PACCHETTO_FORFETTARIO
    }"
    (click)="switchMode(Mode.COSTO_PACCHETTO_FORFETTARIO)">
    Pacchetto ore B2B
  </button>
</div>
  `,
  styles: [`
    .mode-switch .btn {
      min-width: 120px;
    }
    .bg-disabled {
      background-color:none;
      color:#3886c3;
      border-color: #3886c3
    }
  `]
})
export class PricingFamilySwitchComponent implements OnInit {

  @Input() currentMode!: familyMode;
  Mode = familyMode; // expose enum to the template
  @Output() modeChanged = new EventEmitter<familyMode>();

  ngOnInit(): void {
  }

  switchMode(mode: familyMode) {
      this.modeChanged.emit(mode);
  }
}
