<div class="grid" [formGroup]="form">
  <div>
    <label class="label" for="overall">Valutazione generale</label>
    <input type="number" id="overall" formControlName="overall" min="0" max="10">
  </div>
  <div>
    <label class="label" for="punctuality">Puntualità</label>
    <input type="number" id="punctuality" formControlName="punctuality" min="0" max="10">
  </div>
  <div>
    <label class="label" for="empathy">Empatia</label>
    <input type="number" id="empathy" formControlName="empathy" min="0" max="10">
  </div>
  <div>
    <label class="label" for="behave">Educazione</label>
    <input type="number" id="behave" formControlName="behave" min="0" max="10">
  </div>
  <div>
    <label class="label" for="communication">Capacità comunicativa</label>
    <input type="number" id="communication" formControlName="communication" min="0" max="10">
  </div>
  <div></div>
  <div>
    <label class="label" for="attitude">Profilo Caregiver</label>
    <input *ngIf="rating?.attitude" type="text" id="attitude" formControlName="attitude" readonly>
    <button *ngIf="!rating?.attitude" type="button" class="secondary-button" (click)="generateFormLink()">Genera
      link</button>
  </div>
  <div>
    <label class="label" for="dangerous">Pericolosa</label>
    <input type="checkbox" id="dangerous" formControlName="dangerous" (click)="false">
  </div>
  <div class="d-block">
    <label class="label text-danger w-100" *ngIf="form.get('rating.notRated')?.value"><i
        class="bi bi-exclamation-triangle-fill me-3"></i>Questa babysitter non è mai stata valutata</label>
  </div>
</div>
<app-evaluation-history style="grid-column: 1/3; place-self: start;width: 100%"
  [docRef]="docRef"></app-evaluation-history>
<div class="d-flex gap-2 align-items-center">
  <label class="label" for="scheduledAppointment">Colloquio Caregiver:</label>
  <input type="datetime-local" id="scheduledAppointment" [(ngModel)]="appointmentDate">
  <button class="btn btn-secondary text-light" (click)="saveAppointment()" *ngIf="!scheduledAppointment">Imposta</button>
  <button class="btn btn-primary text-light" (click)="deleteAppointment()" *ngIf="scheduledAppointment">Elimina</button>
  <span *ngIf="scheduledAppointment && scheduledAppointment.createdBy">Inserito da: {{scheduledAppointment.createdBy}}</span>
</div>
<app-caregiver-score class="w-100" style="grid-column: 1/3;" [docRef]="docRef"></app-caregiver-score>
