import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Page, Price, Product } from 'npx-family-happy-common';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export const DEFAULT_PRICE_FILTERS: PriceFilters = {
  province: null,
  amount: null,
  stripeID: '',
  productID: null,
};

@Injectable({
  providedIn: 'root',
})
export class PriceService {

  private baseURL = environment.priceAPI;
  filters = new BehaviorSubject<PriceFilters>(DEFAULT_PRICE_FILTERS)

  constructor(private http: HttpClient) { }

  createPrice(body: PriceCreationBody) {
    return this.http.post<Price>(`${this.baseURL}/`, body);
  }

  updatePrice(price: Price) {
    return this.http.put<Price>(`${this.baseURL}/`, price);
  }

  getPriceByID(id: number) {
    return this.http.get<Price>(`${this.baseURL}/${id}`);
  }

  getAllPrices(page: number, pageSize = 20, filters = DEFAULT_PRICE_FILTERS) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `${this.convertFilterObjectToQueryParam(filters)}`,
    ].join('&');
    return this.http.get<Page<Price>>(`${this.baseURL}/list?${params}`);
  }

  deletePriceById(id: number) {
    return this.http.delete<Price>(`${this.baseURL}/${id}`);
  }

  setFilters(filters: any) {
    this.filters.next(filters)
  }

  getFilters() {
    return this.filters.asObservable()
  }


  private convertFilterObjectToQueryParam(filters: PriceFilters): string {
    const queryParams = Object.entries(filters)
      .filter(([key, value]) => {
        if (value === null) return false;
        if (typeof value === 'string' && value.trim() === '') return false;
        if (typeof value === 'number' && value < 0) return false;
        return true;
      })
      .map(([key, value]) => {
        if (key === 'amount') {
          const amountInCents = Math.round(+value * 100)
          return `${key}=${amountInCents}`;
        }
        if (key === 'province') {
          return `${key}=${encodeURIComponent(value)}`;
        }
        if (key === 'productID') {
          return `${key}=${parseInt(value)}`
        };
        return `${key}=${value}`;
      })
      .join('&');

    return queryParams;
  }
}


export interface PriceFilters {
  province: string | null;
  amount: number | null;
  productID: number | null;
  stripeID: string | null;
}

export interface PriceCreationBody {
  stripeID: string;
  provinces: string[];
  amount: number;
  product: Product;
}
