<select class="form-select" id="operator" [formControl]="formControl">
  <option value="" default hidden>Seleziona operatore</option>
  <option value="default" *ngIf="includeAll">Mostra tutti</option>
  <option value="Andrèe Dametti">Andrèe Dametti</option>
  <option value="Valentina">Valentina Riva</option>
  <option value="Emanuele Meraglia">Emanuele Meraglia</option>
  <option value="Giulia Drago">Giulia Drago</option>
  <option value="Alice">Alice Mantoan</option>
  <option value="Gianluca Lacerenza">Gianluca Lacerenza</option>
  <option value="Sofia Lazzati Corizzo">Sofia Lazzati Corizzo</option>
  <option value="Adele Missere">Adele Missere</option>
  <option value="Giulia Campanile">Giulia Campanile</option>
  <option value="Patrick Suppo" *ngIf="includeRecruiter">Patrick Suppo</option>
  <option value="Barbara Buccino" *ngIf="includeRecruiter">Barbara Buccino</option>
  <option value="Jessica Mazzola" *ngIf="includeRecruiter">Jessica Mazzola</option>
  <option value="Sabrina Pacifico" *ngIf="includeRecruiter">Sabrina Pacifico</option>
  <option value="Giulia Sciacca" *ngIf="includeRecruiter">Giulia Sciacca</option>
  <!-- <option *ngFor="let ope of operators" [value]="ope">
    {{ope}}
  </option> -->
  <option value="Nessuno" *ngIf="includeNone">Nessuno</option>
</select>
